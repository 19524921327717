import { List } from 'immutable';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { documentsGridGetHeadersAction } from 'domain/documents/documentsActions';
import Api from 'domain/api';
import * as adapters from 'domain/documents/adapters';

const useFetchGridColumnDefs = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const [columnDefs, setColumnDefs] = useState([]);
  const [isBusy, setIsBusy] = useState(true);

  const fetchGridColumnHeaders = useCallback(
    async () =>
      Api.getGridHeaders({
        data: {
          companyId,
        },
      }),
    [companyId],
  );

  useEffect(() => {
    setIsBusy(true);
    dispatch(documentsGridGetHeadersAction());

    fetchGridColumnHeaders()
      .then(({ data }) => {
        const columns = adapters.gridHeadersListAdapter(data);

        setColumnDefs(columns.toJS());

        dispatch({
          type: documentsGridGetHeadersAction.success,
          payload: columns,
        });
      })
      .catch(() => {
        dispatch({
          type: documentsGridGetHeadersAction.failure,
          payload: new List(),
        });

        // TODO: ag-grid doLogout Action
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [fetchGridColumnHeaders, dispatch]);

  return {
    rawColumnDefs: columnDefs,
    rawColumnDefsIsBusy: isBusy,
    setRawColumnDefs: setColumnDefs,
  };
};

export default useFetchGridColumnDefs;
