// @flow
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';

// components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// helpers
import { filter } from 'lodash/fp';
import { ID_FOR_DETECT_CELL_OVERFLOW } from 'pages/company/grid/helpers';
import { styled } from '@mui/material/styles';

const Separator = styled('span')(({ theme }) => ({ marginInlineEnd: theme.spacing(0.5) }));

type Props = {| texts: Array<string> |};

const FlexibleList = ({ texts }: Props) => {
  const containerRef = useRef();
  const elements = useRef({});

  const [hiddenItems, setHiddenItems] = useState([]);

  const isHiddenIdx = useCallback(
    (idx: string, array: Array<string> = hiddenItems) => array.includes(idx.toString()),
    [hiddenItems],
  );

  const showItem = useCallback(
    (idx: string) => {
      setHiddenItems((items) => (isHiddenIdx(idx, items) ? filter((i) => i !== idx)(items) : items));
    },
    [isHiddenIdx],
  );

  const hideItem = useCallback(
    (idx: string) => {
      setHiddenItems((items) => (!isHiddenIdx(idx, items) ? [...items, idx] : items));
    },
    [isHiddenIdx],
  );

  const handleObserver = useCallback(
    (idx: string) =>
      ([intersectionObject]) => {
        const action = intersectionObject.intersectionRatio === 1 ? showItem : hideItem;
        action(idx);
      },
    [showItem, hideItem],
  );

  const initObservers = useCallback(() => {
    const observerOptions = { root: containerRef.current, rootMargin: '1px', threshold: 1 };
    const observerMap = {};
    Object.entries(elements.current).forEach(([idx, el]) => {
      const observer = new IntersectionObserver(handleObserver(idx), observerOptions);
      observer.observe(el);
      observerMap[idx] = observer;
    });
    return observerMap;
  }, [handleObserver]);

  const visibleKeys = useMemo(() => filter((i) => !isHiddenIdx(i))([...texts.keys()]), [texts, isHiddenIdx]);

  const stats = useMemo(() => {
    const count = texts.length;
    const countVisible = visibleKeys.length;
    const restCount = count - countVisible;
    const lastIdx = count ? (count - 1).toString() : null;
    const lastVisibleIdx = countVisible && countVisible !== count ? (countVisible - 1).toString() : null;

    return { count, countVisible, lastVisibleIdx, restCount, lastIdx };
  }, [texts, visibleKeys]);

  useEffect(() => {
    const observers = initObservers();

    return () => {
      Object.values(observers).forEach((observer) => {
        observer.disconnect();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" maxWidth="100%" ref={containerRef} id={ID_FOR_DETECT_CELL_OVERFLOW}>
      {texts.map((t, i) => {
        const idx = i.toString();
        return (
          <Stack
            direction="row"
            width="100%"
            key={t}
            ref={(r) => {
              if (i !== 0) {
                elements.current[i] = r;
              }
            }}
            sx={{ opacity: isHiddenIdx(idx) ? 0 : 1 }}
          >
            <Box overflow="hidden">
              {t}
              {i !== texts.length - 1 && <Separator>;</Separator>}
            </Box>
            {stats.lastVisibleIdx === idx && (
              <Box sx={{ color: (theme) => theme.palette.text.primary }}>...[{stats.restCount}]</Box>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default FlexibleList;
