// @flow
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import { companyFeatureSetSelector } from 'domain/companies/companiesSelector';
import { sagaDataLoadingSelector } from 'domain/env';
import { importVendorPreferencesAction, updateVendorDefaultsAction } from 'domain/settings/settingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { type TGridApi } from 'pages/company/grid/types.js.flow';
import useInitialLoad from './hooks/initialLoad';
import { columnDefsAdapter } from 'pages/configurations/company/pages/supplier_defaults/helpers/columnDefinitions';
import useAgGridDefaultConfig from 'hooks/agGrid/useAgGridDefaultConfig';
import useAgGridManualConfig from 'hooks/agGrid/useAgGridManualConfig';

import ButtonExport from 'pages/configurations/company/pages/supplier_defaults/components/ButtonExport';
import ButtonImport from 'pages/configurations/company/pages/supplier_defaults/components/ButtonImport';
import AlertImportError from 'pages/configurations/company/pages/supplier_defaults/components/AlertImportError';
import DropdownCellEditor from 'pages/company/grid/components/DropdownCellEditor';
import MultipleDropdownCellEditor from 'pages/company/grid/components/MultipleDropdownCellEditor';
import MultipleDropdownCellRenderer from 'pages/company/grid/components/MultipleDropdownCellRenderer';
import DropdownCellRenderer from 'pages/company/grid/components/DropdownCellRenderer';
import InputCellRenderer from 'pages/company/grid/components/InputCellRenderer';
import InputNumberCellEditor from 'pages/company/grid/components/InputNumberCellEditor';
import BooleanCellRenderer from 'pages/company/grid/components/BooleanCellRenderer';
import FeatureDisabledMessage from 'pages/configurations/components/FeatureDisabledMessage';
import AmountSumStatusPanelRenderer from 'pages/company/grid/components/AmountSumStatusPanelRenderer';
import AgGridCustomTooltip from 'pages/company/grid/components/CustomTooltip';
import FilterSetRender from 'pages/company/grid/components/FilterSetRender';
import toast from 'components/Toast';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import useAgGridVendorStyles from 'pages/configurations/company/pages/supplier_defaults/hooks/useAgGridVendorStyles';

const SupplierDefaults = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const features = useSelector(companyFeatureSetSelector);
  const isSagaLoading = useSelector(sagaDataLoadingSelector);
  const [importErrorKey, setImportErrorKey] = useState(null);
  const [isBusyImport, setIsBusyImport] = useState(false);
  const gridApiRef = useRef();
  const agGridDefaultProps = useAgGridDefaultConfig(useAgGridVendorStyles);
  const { enableRtl, getLocaleText } = useAgGridManualConfig();

  const {
    grid: { headers, rows },
    updateGridData,
  } = useInitialLoad();

  const columnDefs = useMemo(
    () => ({
      columnDefs: columnDefsAdapter(headers, enableRtl, formatMessage),
    }),
    [headers, enableRtl, formatMessage],
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      menuTabs: ['filterMenuTab'],
      suppressMovable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    [],
  );

  const onGridReady = useCallback((params: {| api: TGridApi |}) => {
    gridApiRef.current = params.api;
  }, []);

  const autoSizeColumns = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.autoSizeAllColumns();
    }
  }, []);

  const onFirstDataRendered = useCallback(() => {
    autoSizeColumns();
  }, [autoSizeColumns]);

  const save = useCallback(() => {
    if (gridApiRef.current) {
      const rowsData = [];

      gridApiRef.current.forEachNode((node) => rowsData.push(node.data));

      new Promise((resolve, reject) => {
        dispatch(updateVendorDefaultsAction({ rows: rowsData, resolve, reject }));
      }).then((data) => {
        updateGridData(data);
      });
    }
  }, [dispatch, updateGridData]);

  const getErrorMessageByKey = useCallback(
    (errorKey: string) => {
      switch (errorKey) {
        case 'import_file_has_errors':
          return formatMessage({
            id: 'toast.vendorPreferences.import.failure.import_file_has_errors',
            defaultMessage: 'Import failed. Please download the file to view the errors',
          });
        case 'imported_file_has_an_incorrect_structure': {
          return formatMessage({
            id: 'toast.vendorPreferences.import.failure.imported_file_has_an_incorrect_structure',
            defaultMessage:
              'Import failed. The imported file appears to have an incorrect structure, or it may not be the intended file.',
          });
        }
        default: {
          return null;
        }
      }
    },
    [formatMessage],
  );

  const onImportFile = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];

      if (file) {
        setIsBusyImport(true);

        new Promise((resolve, reject) => {
          dispatch(importVendorPreferencesAction({ file, resolve, reject }));
        })
          .then((data) => {
            updateGridData(data);
            setImportErrorKey(null);

            toast.success(
              formatMessage({
                id: 'toast.vendorPreferences.import.success',
                defaultMessage: 'Import successful',
              }),
            );
          })
          .catch((data) => {
            const errorKey = data?.response?.data?.error;

            if (errorKey) {
              const errorMessage = getErrorMessageByKey(errorKey);
              setImportErrorKey(errorKey);

              if (errorMessage) {
                toast.error(errorMessage);
              }
            }
          })
          .finally(() => {
            setIsBusyImport(false);
          });
      }
    },
    [dispatch, updateGridData, formatMessage, getErrorMessageByKey],
  );

  const onCloseAlert = useCallback(() => setImportErrorKey(null), []);

  const frameworkComponents = {
    dropdownCellEditor: DropdownCellEditor,
    dropdownCellRenderer: DropdownCellRenderer,
    multipleDropdownCellEditor: MultipleDropdownCellEditor,
    multipleDropdownCellRenderer: MultipleDropdownCellRenderer,
    inputCellRenderer: ({ value }) => <InputCellRenderer value={value} />,
    inputNumberCellEditor: InputNumberCellEditor,
    booleanCellRenderer: BooleanCellRenderer,
    amountSumStatusPanel: AmountSumStatusPanelRenderer,
    customTooltip: AgGridCustomTooltip,
    filterSetRender: FilterSetRender,
  };

  // if columns were changed - invoke autosize
  useEffect(() => {
    autoSizeColumns();
  }, [columnDefs, autoSizeColumns]);

  if (isSagaLoading) return <LinearProgress />;

  return features.get('supplier_form', false) && features.get('fin', false) ? (
    <Box className="ag-theme-material" flexGrow={1} display="flex" flexDirection="column">
      {features.get('import_export_vendor_prefs_file', false) && (
        <>
          <Stack direction="row" spacing={1.5} ml="auto" mb={2}>
            <ButtonImport onImportFile={onImportFile} isBusy={isBusyImport} />
            <ButtonExport />
          </Stack>
          {importErrorKey && (
            <AlertImportError
              onClose={onCloseAlert}
              errorKey={importErrorKey}
              message={getErrorMessageByKey(importErrorKey)}
            />
          )}
        </>
      )}
      <AgGridReact
        {...agGridDefaultProps}
        suppressClickEdit={false}
        enableRangeSelection={false}
        rowHeight={58}
        {...columnDefs}
        // props from useAgGridManualConfig hook
        getLocaleText={getLocaleText}
        enableRtl={enableRtl}
        //
        rowData={rows}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        components={frameworkComponents}
        getRowId={({ data }) => data.id}
        singleClickEdit
        stopEditingWhenCellsLoseFocus
        suppressColumnVirtualisation
        suppressContextMenu
        suppressScrollWhenPopupsAreOpen
      />
      <Box display="flex" justifyContent="end" pt={2}>
        <Button onClick={save} sx={{ minWidth: '20ch' }}>
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>
      </Box>
    </Box>
  ) : (
    <FeatureDisabledMessage />
  );
};

export default SupplierDefaults;
