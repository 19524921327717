// @flow
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  documentGetLinkedAction,
  documentLinkedOpenStatusSelector,
  documentLinkInListAction,
  documentSortedLinkedSelector,
  isLinkedDocsLockedSelector,
  linkedSelector,
  documentHideLinkedDocs,
  setLinkingTagAction,
} from 'domain/documents';
import { Set } from 'immutable';
import { documentWorkSpaceTypeSelector } from 'domain/companies';
import * as ACL from 'domain/restriction';
import useToggle from 'hooks/useToggle';
import { promisify } from 'lib/helpers';
import type { PreviewStateT } from 'pages/company/type.js.flow';
import type { TDocument, TDocumentRecord } from 'domain/documents/types.js.flow';
import { documentChangeLinkedWidthAction, linkedSidebarWidthSelector, rtlEnable } from 'domain/env';

const mapStateToProps = (state) => ({
  linkedData: linkedSelector(state),
  linkedDocumentList: documentSortedLinkedSelector(state),
  panelWidth: linkedSidebarWidthSelector(state),
  workspaceType: documentWorkSpaceTypeSelector(state),
  isOpen: documentLinkedOpenStatusSelector(state),
  isLockedRedux: isLinkedDocsLockedSelector(state),
  isRtl: rtlEnable(state),
  isGranted: ACL.isGranted(state),
});

export type TLinkedPanel = {
  onContextMenu: (event: SyntheticMouseEvent<HTMLDivElement>, document: TDocumentRecord) => void,
  onPreview: (p: PreviewStateT) => void,
  onClick: (e: SyntheticMouseEvent<HTMLElement>, d: TDocumentRecord, l: boolean) => void,
  openNotes: (e: SyntheticMouseEvent<HTMLElement>, d: TDocumentRecord) => void,
  clearSelection: () => void,
  onOpenSendByEmailDialog: (linkId: string) => void,
  selectedDocuments: Set<string>,
  preview: PreviewStateT,
  isContextMenuOpen: boolean,
};

const PREVIEW_CONTEXT_NAME = 'LINKED_PANEL_PREVIEW';

const useLinkedPanel = ({
  clearSelection,
  selectedDocuments,
  onContextMenu,
  onPreview,
  onClick,
  openNotes,
  preview,
}: TLinkedPanel) => {
  const dispatch = useDispatch();
  const [isOver, toggleIsOver] = useToggle();
  const { linkedDocumentList, isOpen, linkedData, isLockedRedux, isGranted, isRtl, panelWidth } =
    useSelector(mapStateToProps);
  const { tag, pageToken, count } = linkedData;
  const isLocked = isLockedRedux || isGranted(ACL.IS_SUPPLIER_USER);
  const linkID = linkedDocumentList.getIn([0, 'linkid'], '');
  const note = linkedDocumentList.getIn([0, 'ltext'], '');
  const isPreviewOpen = preview.documentId && preview.contextName === PREVIEW_CONTEXT_NAME;

  const fetchNext = useCallback(() => {
    if (typeof pageToken === 'string') {
      const fn = (params) => dispatch(documentGetLinkedAction(params));

      promisify(fn, { pageToken, tag }).then();
    }
  }, [pageToken, tag, dispatch]);

  const handleContextMenu = useCallback(
    (...args) => {
      onContextMenu(...args, 'linked');
    },
    [onContextMenu],
  );

  const handleNoteClick = useCallback(
    (...args) => {
      openNotes(...args, 'linked');
    },
    [openNotes],
  );

  const handlePreviewClick = useCallback(
    (d: TDocument | null) => {
      const [documentId, contextName] = d ? [d.documentID, PREVIEW_CONTEXT_NAME] : [null, ''];

      onPreview({ documentId, contextName });
    },
    [onPreview],
  );

  const handleDocumentClick = useCallback(
    (event, item) => {
      onClick(event, item, true);
    },
    [onClick],
  );

  const handleResize = useCallback(
    (width: number) => {
      window.localStorage.setItem('dokkaLinkedSidebarWidth', width);
      dispatch(documentChangeLinkedWidthAction(width));
    },
    [dispatch],
  );

  const onDndOver = (e) => {
    e.preventDefault();

    if (!isLocked && !isOver) {
      toggleIsOver();
    }
  };

  const onDndLeave = () => {
    if (isOver) {
      toggleIsOver();
    }
  };

  const onDndDrop = ({ linkid, id }: { linkid: string, id: number }) => {
    if (!linkedData.list.has(id) && !isLocked && !linkid) {
      const promise = new Promise((resolve, reject) => {
        dispatch(
          documentLinkInListAction({
            documentID: selectedDocuments.size ? selectedDocuments.toJS() : id,
            linkID,
            text: note,
            resolve,
            reject,
          }),
        );
      });

      promise.then(() => {
        if (tag) {
          clearSelection();
        }
      });
    }
    onDndLeave();
  };

  const onClose = useCallback(() => {
    dispatch(documentHideLinkedDocs());
    dispatch(setLinkingTagAction(''));
  }, [dispatch]);

  return {
    list: linkedDocumentList,
    total: count,
    linkID,
    note,
    panelWidth,
    isOpen,
    isOver,
    isPreviewOpen,
    isLocked,
    isRtl,
    onDndDrop,
    onDndOver,
    onDndLeave,
    onClose,
    handleNoteClick,
    handlePreviewClick,
    handleContextMenu,
    handleDocumentClick,
    handleResize,
    fetchNext,
  };
};

export default useLinkedPanel;
