// @flow
import { isMobile } from 'lib/systemHelpers/browserHelpers';

export const PROPS = {
  token: 'dokkaToken',
  role: 'role',
  chatToken: 'chatToken',
  chatUser: 'chatUser',
  userId: 'userId',
  reactotronEnabled: 'reactotronEnabled',
  companyConfigurationVisited: 'companyConfigurationVisited',
  userGUID: 'userGUID',
  isDokkaSupport: 'isDokkaSupport',
  notesCreate: 'notesCreate',
  notesUpdate: 'notesUpdate',
  notesDelete: 'notesUpdate',
  splitOptions: 'splitOptions',
  workSpaceType: 'workSpaceType',
  tagsWidth: 'dokkaTagsWidth',
  backUrl: 'BACKURL',
  insightsIsActive: 'DOKKA_INSIGHTS_IS_ACTIVE',
  insightsWidgetWidth: 'DOKKA_INSIGHTS_WIDGET_WIDTH',
  insightsAgGridConfig: 'DOKKA_INSIGHTS_GRID_CONFIG_V2',
  emailSuggestion: 'emailSuggestion',
  workspaceAgGridConfig: 'DOKKA_WORKSPACE_GRID_CONFIG',
};

const st = isMobile ? window.sessionStorage : window.localStorage;

function getItem<T: string>(key: string): ?T {
  return st.getItem(key);
}

function setItem<T: string>(key: T, value: string | number): boolean {
  st.setItem(key, value);
  return getItem(key) === value.toString();
}

function removeItem<T: string>(key: T): boolean {
  st.removeItem(key);
  return getItem(key) === null;
}

type StorageType<K: string, V: string> = {
  [key: K]: () => {
    get: () => ?V,
    set: (n: K) => boolean,
    remove: () => boolean,
  },
};

// eslint-disable-next-line max-len
type PropsType<T: string> = {
  +[key: T]: string,
};

export function storage<T: string, V: string>(p: PropsType<T> = PROPS): StorageType<T, V> {
  const fields: Array<T> = Object.keys(p);
  const methods = (name) => ({
    get: () => getItem(name),
    set: (v) => setItem(name, v),
    remove: () => removeItem(name),
  });
  return fields.reduce((a, v) => Object.assign(a, { [v]: () => methods(PROPS[v]) }), {
    [fields[0]]: () => methods(PROPS[fields[0]]),
  });
}
