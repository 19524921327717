// @flow
import React, { useState, useRef, memo, useCallback, useEffect } from 'react';
import { areEqual } from 'react-window';

import Tooltip from 'components/mui/Tooltip';
import { isEllipsisActive } from 'pages/company/grid/helpers';

export type TFilterRowOption = {
  label: string,
  id: string,
};

export type TFilterRowOptions = Array<TFilterRowOption>;

export type TItemData = {
  options: TFilterRowOptions,
  values: TFilterRowOptions,
  onClickItem: (value: TFilterRowOption) => void,
};

type TVirtualizedRow = {
  index: number,
  style: CSSStyleDeclaration,
  data: TItemData,
};

const VirtualizedRow = (props: TVirtualizedRow) => {
  const { index, style, data } = props;
  const { options, values, onClickItem } = data;
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  const option = options[index];
  const isExist = Boolean(values.find((o) => o.id === option.id));
  const [checked, setChecked] = useState(isExist);

  useEffect(() => {
    setChecked(isExist);
  }, [isExist]);

  const handleEnter = useCallback(() => {
    setIsOverflow(isEllipsisActive(textElementRef.current));
  }, []);

  const handleClick = useCallback(
    (state) => {
      setChecked(state);
      onClickItem(option);
    },
    [onClickItem, option],
  );

  return (
    <div className="ag-virtual-list-item ag-filter-virtual-list-item" style={style} key={index}>
      <div role="presentation" className="ag-set-filter-item" onClick={() => handleClick(!checked)}>
        <Tooltip t={option.label} disableHoverListener={!isOverflowed} placement="right">
          <div className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
            <div
              ref={textElementRef}
              onMouseEnter={handleEnter}
              className="ag-input-field-label ag-label ag-checkbox-label ag-label-ellipsis"
            >
              {option.label}
            </div>
            <div
              className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper${checked ? ' ag-checked' : ''}`}
              role="presentation"
            >
              <input className="ag-input-field-input ag-checkbox-input" type="checkbox" tabIndex="-1" />
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(VirtualizedRow, areEqual);
