// @flow
import { useCallback, useImperativeHandle, useMemo } from 'react';
import { isServiceColumn } from './helpers';
import type { GridRef, TGridApi } from './types.js.flow';

const usePropagateGridApiRef = (ref: GridRef, gridApi: {| current: null | TGridApi |}, forceUpdate: () => void) => {
  const allColumns = gridApi.current?.getAllDisplayedColumns() || [];
  const columnKeys = allColumns.filter(({ colId }) => !isServiceColumn(colId)).map(({ colId }) => colId);
  const { selectAll, toggledNodes } = gridApi.current?.getServerSideSelectionState() || {
    selectAll: false,
    toggledNodes: [],
  };
  const isSelected = !selectAll || toggledNodes.length > 0;

  const params = useMemo(
    () => ({
      columnKeys,
      autoConvertFormulas: true,
      processCellCallback: (x) => {
        const { field, type } = x.column.getColDef();
        const value = type === 'amount' ? x.value : x.column.colDef.valueFormatter(x);
        return field === 'hyperlink' ? `=HYPERLINK("${value}")` : value;
      },
      sheetName: 'report',
      skipPinnedTop: true,
    }),
    [columnKeys],
  );

  const exportAsExel = useCallback(
    (onlySelected = false) => {
      console.log('===== exportAsExel FN');
      try {
        gridApi.current.exportDataAsExcel({
          onlySelected,
          ...params,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [gridApi, params],
  );

  const exportAsCsv = useCallback(() => {
    try {
      gridApi.current.exportDataAsCsv({
        ...params,
      });
    } catch (e) {
      console.error(e);
    }
  }, [gridApi, params]);

  useImperativeHandle(ref, () => ({
    forceUpdate,
    exportAllRowsDataAsExcel: () => exportAsExel(),
    exportSelectedRowsDataAsExcel: () => exportAsExel(isSelected),
    exportAllRowsDataAsCsv: exportAsCsv,
  }));
};

export default usePropagateGridApiRef;
