import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, generatePath, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Api from 'domain/api';
import { setDocumentsTotalAction } from 'domain/documents';
import { getNestedCategory } from 'domain/categories/helpers';
import CompanyContext from 'pages/company/CompanyContext';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import useQuery from 'hooks/useQuery';

const useFetchTotalByCategory = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const category = getNestedCategory(params);
  const { category1 } = params;
  const { companyType } = useContext(CompanyContext);
  const isConfidential = companyType === 'confidential';

  const [total, setTotal] = useState(null);
  const [isBusy, setIsBusy] = useState(true);

  const fetchGridTotal = useCallback(async () => {
    setIsBusy(true);

    await Api.getGridTotalByCategory({
      params: {
        isConfidential,
        companyId,
        category,
        ...query,
      },
    })
      .then(({ data }) => {
        setTotal(data);
        dispatch(setDocumentsTotalAction(data));
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [companyId, isConfidential, category, query, dispatch]);

  useEffect(() => {
    fetchGridTotal().then();
  }, [fetchGridTotal]);

  useEffect(() => {
    if (total === 0 && category !== category1) {
      const route =
        companyType === 'confidential'
          ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
          : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

      const path = generatePath(route, { companyId, category1 });

      navigate(path);
    }
  }, [total, companyType, navigate, category, category1, companyId]);

  return {
    documentsTotal: total,
    documentsTotalIsBusy: isBusy,
    fetchGridTotal,
  };
};

export default useFetchTotalByCategory;
