// @flow
import React, { forwardRef } from 'react';
import memoizeOne from 'memoize-one';
import { FixedSizeList } from 'react-window';
import VirtualizedRow, {
  type TItemData,
} from 'pages/company/grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedRow';

type TVirtualizedInfiniteLoadList = {
  ...TItemData,
  onItemsRendered: () => void,
};

const createItemData = memoizeOne((options, onClickItem, values) => ({
  options,
  onClickItem,
  values,
}));

const VirtualizedList = forwardRef(
  ({ options, values, onClickItem, onItemsRendered }: TVirtualizedInfiniteLoadList, ref) => {
    const itemData = createItemData(options, onClickItem, values);

    return (
      <FixedSizeList
        ref={ref}
        selected={values}
        itemData={itemData}
        height={224}
        itemSize={32}
        itemCount={itemData.options.length}
        overscanCount={5}
        onItemsRendered={onItemsRendered}
      >
        {VirtualizedRow}
      </FixedSizeList>
    );
  },
);

export default VirtualizedList;
