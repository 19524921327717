// @flow
import * as React from 'react';
import { List, type Map, Set, OrderedSet, type RecordOf } from 'immutable';
import { type ConfirmOptions } from 'material-ui-confirm';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import keeper, { withApiToken } from 'lib/apiTokenKeeper';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import withConfirm from 'hoc/withConfirm';
import {
  documentsByIdSelector,
  documentGetLinkedAction,
  documentUpdateTagsInList,
  documentLinkInListAction,
  documentUnlinkInList,
  documentRemove as documentRemoveAction,
  scanningRequest,
  documentLinkedSelector,
  documentSortedLinkedSelector,
  documentHideLinkedDocs,
  documentsUnlink,
  documentSignDocument,
  duplicateDocumentAction,
  addEditableDoc,
  editableDocSelector,
  clearEditableDoc,
  moveDocumentToCategoryAction,
  markAsPaidAction,
  documentLinkedTagSelector,
  documentGetLinkedCountAction,
  uploadQueueSelector,
  queueRemoveAction,
  setLinkingTagAction,
  linkingTagSelector,
  documentSetAsCoverAction,
  documentIgnoreWarningsAction,
  getIndicateWarningByDocSelector,
  documentsGridLoaderSelector,
  documentsDownloadDocumentsAction,
  documentGetLinkedIDsAction,
  mergeDocumentsForPrintingAction,
  documentGetLinkedForPreviewAction,
} from 'domain/documents';
import { navigate } from 'domain/router/redux/reduxActions';
import { rootCategoriesListSelector, type CategoriesList } from 'domain/categories';
import {
  isCategoryAll,
  isToApproveCategory,
  findCatById,
  isAllowMoveToCategories,
  getMovableCategories,
} from 'domain/categories/helpers';
import { createMemoFn, withPropsCollector } from 'lib/propHelpers';
import { checkAcceptSignType, isDocPaid } from 'domain/documents/helpers';
import Api from 'domain/api';
import * as ACL from 'domain/restriction';
import print, { onPrint, removePrintFramesFromDOM } from 'lib/print';
import { promisify } from 'lib/helpers';
import { getDocumentUrl, generateLinkID } from './helpers';
import CONST from 'domain/documents/constants';
import { querySelector } from 'domain/router';
import {
  loadingSelector,
  localeSelector,
  saveBackSearchUrlAction,
  setNavigationAction,
  userFeaturesSelector,
} from 'domain/env';
import { documentSetAsGeneralAction, documentSetAsFinancialAction } from 'domain/documents/documentsActions';
import { companiesByIdSelector, documentWorkSpaceTypeSelector } from 'domain/companies';
import download from 'lib/download';
import type { TQueueItem, TDocument, TDocumentRecord, TDocumentsMap } from 'domain/documents/types.js.flow';
import type { DocDropParams, Query } from 'pages/company/type.js.flow';
import { type Company } from 'domain/companies/helpers';
import type { TUserFeatures, TWorkspaceType } from 'domain/env/types.js.flow';
import type { DokkaStore } from 'domain/types.js.flow';
import { type GridRef } from 'pages/company/grid/types.js.flow';

import toast from 'components/Toast';
import DocumentEdit from 'pages/common/Dialog/DialogDocumentEdit';
import DialogNotes from 'pages/common/Dialog/DialogNotes';
import DialogTagsManage from 'pages/common/Dialog/DialogTagsManage';
import DialogSignDocument from 'pages/common/Dialog/DialogSignDocument';
import DialogMoveDocumentsToCompany from 'pages/common/Dialog/DialogMoveDocumentsToCompany';
import DialogSendByEmail from 'pages/common/Dialog/DialogSendByEmail';
import { filtredItem, filtredLinkedItem } from './DocumentContextMenu/helpers';
import DialogUpload from './DialogUpload';
import WebTwain from './webtwain';
import { DnDItemTarget, LINKED } from 'components/DnDItem';
import { NativeTypes } from 'react-dnd-html5-backend';
import WorkSpace from 'pages/company/workSpace';
import EventsHistoryPanel from 'pages/company/EventsHistoryPanel';
import CompanyContext from 'pages/company/CompanyContext';

import { PreviewStateT } from 'pages/company/type.js.flow';
import { PREVIEW_CONTEXT_NAME as GRID_PREVIEW_CONTEXT_NAME } from 'pages/company/grid';

import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import LayoutCompany from 'components/mui/Layouts/Company';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import CompanyBarPanel from 'pages/company/CompanyBarPanel';
import CompanyNavigation from 'pages/company/CompanyNavigation';
import CompanyTitlePanel from 'pages/company/CompanyTitlePanel';
import Container from '@mui/material/Container';
import DialogKeyboardHotkeys from './DialogKeyboardHotkeys';
import DocumentContextMenu from 'pages/company/DocumentContextMenu';
import { CONFIGURABLE_API_ENDPOINT } from 'lib/apiHelpers';

type Props = {
  type: 'standard' | 'confidential',
  match: {
    params: {
      companyId: string,
      documentType: string,
      category1: string,
    },
  },
  query: Query,
  classes: {
    [key: string]: string,
  },
  company: Company,
  linkedDocs: Map<string, TDocumentRecord>,
  sortedLinkedDocs: List<TDocumentRecord>,
  documentsById: Map<string, TDocumentRecord>,
  // eslint-disable-next-line react/no-unused-prop-types
  apiToken: string,
  editableDoc: TDocumentRecord,
  documentUpdateTags: Dispatch<documentUpdateTagsInList>,
  documentLink: Dispatch<documentLinkInListAction>,
  documentUnlink: Dispatch<documentUnlinkInList>,
  documentGetLinked: Dispatch<documentGetLinkedAction>,
  documentGetLinkedForPreview: Dispatch<documentGetLinkedForPreviewAction>,
  documentGetLinkedIDs: Dispatch<documentGetLinkedIDsAction>,
  getDocumentLinkedCount: Dispatch<typeof documentGetLinkedCountAction>,
  documentSetAsGeneral: Dispatch<documentSetAsGeneralAction>,
  documentSetAsFinancial: Dispatch<documentSetAsFinancialAction>,
  loading: boolean,
  gridLoading: boolean,
  hideLinkedDocs: Dispatch<documentHideLinkedDocs>,
  documentRemove: Dispatch<documentRemoveAction>,
  enableLoader: Dispatch<scanningRequest>,
  disableLoader: Dispatch<scanningRequest.success>,
  intl: IntlShape,
  locale: 'en' | 'he',
  restriction: number,
  isGranted: (number | number[]) => boolean,
  unlinkDocuments: Dispatch<documentsUnlink>,
  signDocument: Dispatch<documentSignDocument>,
  saveBackUrl: Dispatch<saveBackSearchUrlAction>,
  setNavigation: Dispatch<setNavigationAction>,
  duplicateDocument: Dispatch<duplicateDocumentAction>,
  addEditableDocument: Dispatch<addEditableDoc>,
  clearEditableDocument: Dispatch<clearEditableDoc>,
  moveDocumentToCategory: Dispatch<typeof moveDocumentToCategoryAction>,
  rootCategoriesList: CategoriesList,
  markAsPaid: Dispatch<markAsPaidAction>,
  documentLinkedTag: ?string,
  queue: Map<string, RecordOf<TQueueItem>>,
  removeQueue: Dispatch<typeof queueRemoveAction>,
  setLinkingTag: Dispatch<typeof setLinkingTagAction>,
  setDocumentAsCover: Dispatch<typeof documentSetAsCoverAction>,
  linkingTag: string,
  workSpaceType: TWorkspaceType,
  userFeatures: RecordOf<TUserFeatures>,
  ignoreDocumentWarnings: Dispatch<typeof documentIgnoreWarningsAction>,
  getIndicateWarningByDoc: (doc: TDocument) => boolean,
  navigatePush: Dispatch<typeof navigate.push>,
  downloadLinkedDocs: Dispatch<typeof documentsDownloadDocumentsAction>,
  printPreparationCompleted: Dispatch<documentGetLinkedIDsAction.success>,
  confirm: (options?: ConfirmOptions) => Promise<void>,
  mergeDocumentsForPrinting: Dispatch<typeof mergeDocumentsForPrintingAction>,
};

type PopupsName = 'notes' | 'confirmMoveLinked' | 'tags' | 'upload' | 'sign' | 'download' | 'sendByEmail';

type Menu = {
  status: boolean | 'documentMenu' | 'linkedPanelDocumentMenu' | 'linkedHeaderMenu',
  anchor: any,
  isFolder: boolean,
};

type MoveConfirmation = {|
  targetName: string,
  targetLangID: string,
  count: string,
  placeholder: string,
  payload: { [key: string]: any },
|};

type State = {
  notes: boolean,
  tags: boolean,
  confirmMove: ?MoveConfirmation,
  upload: boolean,
  linkingTag?: ?string,
  linkedDocs: Array<TDocumentRecord>,
  menu: Menu,
  ready: boolean,
  show: boolean,
  deleting: boolean,
  sign: boolean,
  moveToCompany: boolean,
  sendByEmail: boolean,
  items: ?List<*>,
  ref: ?React.ElementRef<'div'>,
  selected: OrderedSet<string>,
  preview: PreviewStateT,
  linkIdForMoving?: string,
  linkIdForSending?: string,
};

const DropTarget = DnDItemTarget([NativeTypes.FILE, LINKED]);

class CompanyPage extends React.PureComponent<Props, State> {
  dropTargetEl: ?HTMLDivElement;

  root: ?HTMLDivElement;

  lockedDocumentId: ?string;

  gridRef: GridRef = React.createRef();

  constructor() {
    super();

    this.state = {
      menu: {
        id: null,
        anchor: null,
        status: false,
        isFolder: false,
      },
      notes: false,
      deleting: false,
      tags: false,
      confirmMove: null,
      confirmMoveLinkedCount: null,
      upload: false,
      linkingTag: null,
      ready: true,
      show: false,
      ref: undefined,
      items: null,
      sign: false,
      moveToCompany: false,
      sendByEmail: false,
      linkIdForMoving: null,
      linkIdForSending: null,
      selected: new OrderedSet(),
      preview: {
        documentId: null,
        contextName: '',
      },
    };
  }

  componentDidMount() {
    this.root = document.getElementById('root');
    if (this.root) {
      this.root.addEventListener('dragover', this.onDragOver, false);
      this.root.addEventListener('drop', this.onFilesDrop, false);
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: $ReadOnly<Props>): void {
    const { documentsById: prevDocumentsById } = prevProps;
    this.resetLockedDocument(prevDocumentsById);
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.removeEventListener('dragover', this.onDragOver);
      this.root.removeEventListener('drop', this.onFilesDrop);
    }
    removePrintFramesFromDOM();
  }

  onPreview = (preview: PreviewStateT) => {
    this.setState({ preview });
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDndDropFromLinked = ({ id }) => {
    const {
      documentLinkedTag,
      match: {
        params: { category1 },
      },
      rootCategoriesList,
      documentUnlink,
      documentGetLinked,
      isGranted,
    } = this.props;

    const { selected } = this.state;
    const documentID = selected.size ? selected.toJS() : [id];
    const currentCategory = findCatById(rootCategoriesList, category1);
    const isCatAll = isCategoryAll(currentCategory);
    const isCatToApproval = isToApproveCategory(currentCategory);

    if (isCatAll || isCatToApproval || !isGranted(ACL.IS_ACCOUNT)) {
      const promise = new Promise((resolve, reject) => {
        documentUnlink({ documentID, resolve, reject });
      });
      promise.then(() => {
        if (documentLinkedTag) {
          documentGetLinked({ tag: documentLinkedTag });
        }
      });
    } else {
      this.moveTo({
        categoryId: parseInt(category1, 10),
        documentID,
        itemType: 'LINKED',
        linkid: documentLinkedTag,
      });
    }
    this.clearSelected();
  };

  onLinkingSidebarClose = () => {
    const { setLinkingTag, hideLinkedDocs } = this.props;
    hideLinkedDocs();
    setLinkingTag('');
  };

  onLoadLinked = async (tag, isOpenPanel = true, searchedDocumentId = null) => {
    const { hideLinkedDocs, documentGetLinked, documentGetLinkedForPreview, linkingTag, setLinkingTag } = this.props;
    if (!linkingTag) {
      hideLinkedDocs();
    }
    const action = searchedDocumentId ? documentGetLinkedForPreview : documentGetLinked;
    await promisify(action, { tag, isOpen: isOpenPanel, searchedDocumentId });
    setLinkingTag(tag);
  };

  // only available for docs that have no linkID and ltext
  onShowLinkingSidebar = (documentID) => {
    const { documentLink } = this.props;
    const newLinkingTag = generateLinkID();
    new Promise((resolve, reject) => {
      documentLink({
        documentID,
        linkID: newLinkingTag,
        text: '',
        resolve,
        reject,
      });
    })
      .then(() => {
        this.onLoadLinked(newLinkingTag);
      })
      .catch(() => null);
  };

  onReady = () => {
    this.setState({ ready: true });
  };

  onFilesDrop = (event: SyntheticDragEvent<HTMLElement>) => {
    const { files, items } = event.dataTransfer;
    const { company } = this.props;
    if (company && files.length > 0 && files.length === items.length) {
      event.preventDefault();
      this.setState({
        items: List(event.dataTransfer.items),
      });
    }
  };

  onUploadClose = () => {
    this.setState({
      upload: false,
      items: null,
    });
    const { queue, removeQueue } = this.props;

    if (queue.size) {
      const filteredQueue = queue.filter((i) => i.status !== 'uploading');
      removeQueue(filteredQueue.keySeq().toArray());
    }
  };

  onSign = (signatureID) => {
    const { signDocument } = this.props;
    const { menu } = this.state;
    signDocument({ signatureID, documentID: menu.id });
  };

  onGridPreview = (documentId: string) => {
    this.onPreview({ documentId, contextName: GRID_PREVIEW_CONTEXT_NAME });
  };

  onConfirmMove = () => {
    const { confirmMove } = this.state;
    this.movedDocuments(confirmMove.payload);
    this.onCloseConfirmMove();
  };

  onCloseConfirmMove = () => {
    this.setState({ confirmMove: null });
  };

  onHeaderChange = () => {
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  };

  // eslint-disable-next-line react/sort-comp
  getContextMenuItems = () => {
    const {
      documentsById,
      restriction,
      linkedDocs,
      sortedLinkedDocs,
      apiToken,
      isGranted,
      workSpaceType,
      company: { signParams, id },
      rootCategoriesList,
      match,
      getIndicateWarningByDoc,
    } = this.props;

    const { menu, preview } = this.state;

    const docsMaps = menu.source === 'linked' ? linkedDocs : documentsById;
    const doc = docsMaps.get(menu.id);

    if (typeof doc === 'undefined') return [];
    const { notes, doctype, tags, linkid, documentID, status, viewinfo, canBeMoved } = doc;
    const url = Api.getDocumentUrl({ dokkaToken: apiToken, documentID, companyId: id });
    const isLinkedLocked = linkid ? linkid.endsWith('locked') : false;
    const isGridWorkspace = workSpaceType === 'grid';
    const isLinkedContextOnGridView = isGridWorkspace && menu.source === 'linked';

    const isFirstOrderLinkedDocument = sortedLinkedDocs.findIndex((d) => d.documentID === documentID) === 0;
    const isLocked = linkid.includes('_locked');

    const items = {
      open: {
        action: () => this.toDocument(documentID),
        hidden: !isGridWorkspace || isLinkedContextOnGridView,
      },
      preview: {
        action: () => this.onGridPreview(documentID),
        hidden: !isGridWorkspace || preview.documentId || isLinkedContextOnGridView,
      },
      setAsCover: {
        disabled: isFirstOrderLinkedDocument || isLinkedLocked,
        action: this.setAsCover,
        hidden: !linkid,
      },
      linkDocuments: {
        disabled: linkid.length > 0 || isGridWorkspace,
        action: () => this.onShowLinkingSidebar(documentID),
      },
      editStickyNote: {
        action: () => this.toggleShow('notes'),
      },
      addStickyNote: {
        action: () => this.toggleShow('notes'),
      },
      tagsAs: {
        action: () => this.toggleShow('tags'),
      },
      markAsPaid: {
        action: this.markAsPaid(true),
        hidden: isDocPaid(doc),
      },
      markAsUnpaid: {
        action: this.markAsPaid(false),
        hidden: !isDocPaid(doc),
      },
      markAsGeneral: {
        action: this.setAsGeneral,
      },
      markAsFinancial: {
        action: this.setAsFinancial,
      },
      ignoreDocumentWarnings: {
        action: this.ignoreDocumentWarnings,
        hidden: !getIndicateWarningByDoc(doc),
      },
      sendByEmail: {
        action: this.onOpenSendByEmailDialog,
      },
      downloadDocument: {
        action: this.download,
      },
      editDocument: {
        action: this.documentEditShow,
        disabled:
          documentID.slice(-4) !== '.pdf' ||
          viewinfo.pages < 2 ||
          status.indexOf('accepted') + 1 > 0 ||
          tags.has(CONST.tags.isScheduledAcceptance),
      },
      deleteDocument: {
        action: () => this.documentRemove(),
        disabled: (~status.indexOf('accepted') && !isGranted(ACL.IS_ADMIN)) || isLinkedLocked,
        sx: {
          color: 'error.main',
        },
      },
      duplicateDocument: {
        action: () => this.duplicateDocument(),
      },
      printDocument: {
        action: async () => print(url, documentID, doc.protected),
      },
      signDocument: {
        action: () => this.toggleShow('sign'),
        disabled:
          !signParams ||
          !signParams.guid ||
          doc.status.indexOf('signed') + 1 > 0 ||
          !checkAcceptSignType(documentID) ||
          doc.protected,
      },
      moveTo: {
        submenu: this.getMoveToSubmenu({ documentID: [documentID], linkid, doc }),
        // linked doc can not be moved to category
        hidden: !isAllowMoveToCategories({
          rootCategories: rootCategoriesList,
          currentRootCategoryId: match.params.category1,
          isGranted,
        }),
        disabled: isLocked,
      },
      moveLinkedToCompany: {
        action: () => this.onMoveToCompany(linkid),
        disabled: isLocked,
        hidden: !linkid,
      },
      printLinked: {
        action: this.printLinked,
      },
      sendByEmailLinked: {
        action: () => this.onOpenSendByEmailDialog(linkid),
      },
      downloadLinked: {
        action: this.downloadLinked,
      },
      linkedBundle: {
        hidden: !linkid,
      },
      moveToCompany: {
        action: () => this.toggleShow('moveToCompany'),
        disabled: !canBeMoved,
        hidden: linkid,
      },
    };

    return filtredItem({
      doctype,
      notes,
      restriction,
      status,
      tags,
    }).map((e) => ({
      ...e,
      ...items[e.id],
      ...(e.submenu && { submenu: e.submenu.map((m) => ({ ...m, ...items[m.id] })) }),
    }));
  };

  getContextLinkedMenuItems() {
    const { documentsById, restriction, isGranted, rootCategoriesList, match } = this.props;
    const { menu } = this.state;

    const doc = documentsById.get(menu.id);
    if (doc) {
      const { doctype, linkid, notes, status, tags } = doc;
      const isLocked = linkid.includes('_locked');

      const items = {
        moveTo: {
          submenu: this.getMoveToSubmenu({ linkedID: [linkid], doc }),
          hidden: !isAllowMoveToCategories({
            isGranted,
            currentRootCategoryId: match.params.category1,
            rootCategories: rootCategoriesList,
          }),
          disabled: isLocked,
        },
        moveLinkedToCompany: {
          action: () => this.onMoveToCompany(linkid),
          disabled: isLocked,
        },
        sendByEmailLinked: {
          action: () => this.onOpenSendByEmailDialog(linkid),
        },
        downloadLinked: {
          action: this.downloadLinked,
        },
        printLinked: {
          action: this.printLinked,
        },
        editLinkDocuments: {
          action: () => this.onLoadLinked(linkid),
        },
        unlink: {
          action: () => this.unlinkAll(linkid),
          disabled: linkid.endsWith('locked'),
        },
      };

      return filtredLinkedItem({
        doctype,
        status,
        notes,
        restriction,
        tags,
      }).map((e) => ({ ...e, ...items[e.id] }));
    }
    return [];
  }

  getMoveToSubmenu = (params) => {
    const { rootCategoriesList, match } = this.props;
    const { doc, ...rest } = params;

    return (
      getMovableCategories(match.params.category1, rootCategoriesList)
        // extra filtering in 'all' category by document root category, not url
        .filter((cat) => String(cat.id) !== String(doc.rootCategory))
        .map((cat) => ({
          id: `category.name.${cat.nameLangId}`,
          title: <FormattedMessage id={`category.name.${cat.nameLangId}`} defaultMessage={cat.name} />,
          disabled: false,
          action: () => {
            this.moveTo({ categoryId: cat.id, ...rest });
          },
        }))
        .toJS()
    );
  };

  getDocumentLinkedCount = (linkedID: string) => {
    const { getDocumentLinkedCount } = this.props;
    this.setState({ confirmMoveLinkedCount: null });
    const promise = new Promise((resolve, reject) => getDocumentLinkedCount({ linkedID, resolve, reject }));
    promise.then((response) => {
      this.setState({ confirmMoveLinkedCount: response.count });
    });
  };

  getDocumentLinkedCountByContextMenu = () => {
    const { menu } = this.state;
    const { documentsById } = this.props;
    const doc = !!menu.id && documentsById.get(menu.id);
    const linkid = !!doc && doc.linkid;

    if (linkid) {
      this.getDocumentLinkedCount(linkid);
    }
  };

  getLinkedConfirmationLangValues = createMemoFn(
    () => {
      const { confirmMove } = this.state;
      return { confirmMove };
    },
    ({ confirmMove }) => ({
      catName: (
        <span style={{ fontWeight: 600 }}>
          <FormattedMessage id={`category.name.${confirmMove.targetLangID}`} defaultMessage={confirmMove.targetName} />
        </span>
      ),
      count: <span style={{ fontWeight: 600 }}>{confirmMove.count}</span>,
    }),
  );

  onChangeSelected = (newSelected) => {
    this.setState({ selected: newSelected });
  };

  get contextMenuItems() {
    const { workSpaceType } = this.props;
    const { menu } = this.state;
    return menu.isFolder && workSpaceType !== 'grid' ? this.getContextLinkedMenuItems() : this.getContextMenuItems();
  }

  setDropTargetEl = (ref: ?HTMLDivElement) => {
    if (!this.dropTargetEl) {
      this.dropTargetEl = ref;
      this.forceUpdate();
    }
  };

  setNavigation = (id: string, fromLinked: boolean = false, fromHistory: boolean = false) => {
    const { query, match, type, setNavigation, saveBackUrl } = this.props;
    const params = {
      ...match.params,
      companyType: type,
    };
    setNavigation({
      search: { query, params },
      fromLinked,
      fromHistory,
      isSetRequest: true,
    });
    saveBackUrl({ query, params });
  };

  setAsCover = () => {
    const {
      menu: { id },
    } = this.state;
    const { setDocumentAsCover, intl } = this.props;

    new Promise((resolve, reject) => setDocumentAsCover({ documentId: id, resolve, reject }))
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.setAsCover.success',
            defaultMessage: 'Cover for bundle of Linked documents set successfully',
          }),
        );
        this.contextMenuHide();
      })
      .catch(() => {
        this.contextMenuHide();
      });
  };

  setAsGeneral = () => {
    const {
      menu: { id },
    } = this.state;
    const { documentSetAsGeneral, intl } = this.props;

    new Promise((resolve, reject) => documentSetAsGeneral({ documentId: id, resolve, reject }))
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.general.success',
            defaultMessage: 'Document marked as general',
          }),
        );
        this.contextMenuHide();
      })
      .catch(() => {
        this.contextMenuHide();
      });

    this.setLockedDocument(id);
  };

  setAsFinancial = () => {
    const {
      menu: { id },
    } = this.state;
    const { documentSetAsFinancial, intl } = this.props;

    new Promise((resolve, reject) => documentSetAsFinancial({ documentId: id, resolve, reject }))
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.financial.success',
            defaultMessage: 'Document marked as financial',
          }),
        );
        this.contextMenuHide();
      })
      .catch(() => {
        this.contextMenuHide();
      });

    this.setLockedDocument(id);
  };

  setLockedDocument(id: string) {
    const {
      match: {
        params: { documentType },
      },
    } = this.props;
    if (!documentType) return;
    this.lockedDocumentId = id;
  }

  ignoreDocumentWarnings = () => {
    const {
      menu: { id },
    } = this.state;
    const { ignoreDocumentWarnings } = this.props;
    ignoreDocumentWarnings({ documentId: id });
  };

  toDocument = (documentID: string) => {
    const { match, navigatePush } = this.props;
    this.setNavigation(documentID);
    const documentURL = getDocumentUrl(match.params.companyId, documentID);

    navigatePush(documentURL);
  };

  clearSelected = (cb?: any) => {
    this.setState({ selected: new Set() }, cb);
  };

  movedDocuments = (payload) => {
    const { moveDocumentToCategory, documentGetLinked } = this.props;
    const { linkid = '' } = payload;
    const promise = new Promise((resolve, reject) => {
      moveDocumentToCategory({
        ...payload,
        resolve,
        reject,
      });
    });

    this.clearSelected();

    promise.then(() => {
      // update categories
      if (linkid) {
        setTimeout(() => {
          documentGetLinked({ tag: linkid });
        }, 500);
      }
    });
  };

  moveTo = (payload: DocDropParams & { name: string, nameLangId: string }) => {
    const { type } = this.props;
    const { selected, confirmMoveLinkedCount } = this.state;
    const { linkid, linkedID } = payload;
    const documentID = selected.size && !linkedID ? selected.toJS() : payload.documentID;

    const sendPayload = {
      ...payload,
      documentID,
      workspaceType: type,
    };

    if ((linkedID && linkedID.length > 0) || linkid) {
      const { rootCategoriesList } = this.props;
      const category = findCatById(rootCategoriesList, payload.categoryId);

      if (category.name) {
        const moveAndUnlink = documentID && documentID.length > 1 ? 'moveManyAndUnlink' : 'moveOneAndUnlink';

        const confirmMove = {
          targetName: category.name,
          targetLangID: category.nameLangId,
          count: linkid ? documentID.length : confirmMoveLinkedCount,
          placeholder: linkid ? moveAndUnlink : 'onlyMove',
          payload: sendPayload,
        };
        this.setState({ confirmMove }, () => {
          if (this.isAllowAutoConfirm()) {
            this.onConfirmMove();
          } else {
            this.onOpenConfirmModal();
          }
        });
      }
    } else {
      this.movedDocuments(sendPayload);
    }
  };

  onOpenConfirmModal = () => {
    const {
      confirm,
      intl: { formatMessage },
    } = this.props;
    const { confirmMove } = this.state;

    const confirmOptions = {
      title: (
        <FormattedMessage
          id="document.show.modals.moveLinked.title"
          defaultMessage="Move linked document to {catName}"
          values={this.getLinkedConfirmationLangValues()}
        />
      ),
      description: (
        <FormattedMessage
          id={`document.show.modals.moveLinked.placeholder.${confirmMove.placeholder}`}
          defaultMessage="{count} linked document will be moved to {catName}.\n Are you sure?"
          values={this.getLinkedConfirmationLangValues()}
        />
      ),
      confirmationText: formatMessage({
        id: 'button.yes',
        defaultMessage: 'Yes',
      }),
    };
    confirm({ ...confirmOptions })
      .then(() => {
        this.onConfirmMove();
      })
      .catch(() => this.onCloseConfirmMove());
  };

  markAsPaid = (isPaid: boolean) => () => {
    const {
      menu: { id },
    } = this.state;
    const { markAsPaid, workSpaceType } = this.props;
    markAsPaid({ documentId: id, isPaid, needJESync: workSpaceType === 'grid' });
  };

  unlinkAll = (linkid) => {
    const { unlinkDocuments } = this.props;
    return new Promise((resolve, reject) => unlinkDocuments({ linkid, resolve, reject })).then(() =>
      this.onLinkingSidebarClose(),
    );
  };

  toggleShow = (name: PopupsName) => {
    this.setState((state) => ({ ...state, [name]: !state[name] }));
  };

  contextMenu = (event: SyntheticMouseEvent<HTMLElement>, document: TDocumentRecord, source: string = 'list') => {
    event.preventDefault();

    this.setState({
      menu: {
        status: true,
        source,
        id: document.documentID,
        isFolder: !!document.linkid && source === 'list',
        position: {
          x: event.clientX,
          y: event.clientY,
        },
      },
    });
  };

  handleContextMenu = (event, document: TDocumentRecord, source: string = 'list') => {
    event.preventDefault();
    let anchor = null;

    // define an open context menu near the mouse or element
    // if its right click - mouse, left - element
    if (event.type === 'click') {
      // anchorEl - Menu mui component props
      anchor = { anchorEl: event.currentTarget };
    } else if (event.type === 'contextmenu') {
      // anchorReference, anchorPosition - Menu mui component props
      anchor = {
        anchorReference: 'anchorPosition',
        anchorPosition: {
          left: event.clientX + 2,
          top: event.clientY - 6,
        },
      };
    }

    this.setState({
      menu: {
        status: true,
        anchor,
        source,
        id: document.documentID,
        isFolder: !!document.linkid && source === 'list',
      },
    });
  };

  documentEditShow = () => {
    const { addEditableDocument } = this.props;
    const { menu } = this.state;
    addEditableDocument(menu.id);
  };

  documentEditHide = () => {
    const { clearEditableDocument } = this.props;
    clearEditableDocument();
  };

  contextMenuHide = () => {
    const { menu } = this.state;
    removePrintFramesFromDOM();
    this.setState({
      menu: {
        ...menu,
        anchor: null,
        status: false,
      },
    });
  };

  documentRemove = () => {
    const {
      menu: { id },
      deleting,
    } = this.state;

    const {
      documentRemove,
      intl: { formatMessage },
      confirm,
    } = this.props;

    const confirmOptions = {
      title: formatMessage({
        id: 'document.show.modals.delete.title',
        defaultMessage: 'Delete Document?',
      }),
      description: formatMessage({
        id: 'document.show.modals.delete.placeholder',
        defaultMessage: 'Are you sure you want to delete the document?',
      }),
      confirmationText: formatMessage({
        id: 'document.show.modals.delete.btn',
        defaultMessage: 'Delete',
      }),
      confirmationButtonProps: {
        disabled: deleting,
      },
    };

    this.setState({
      deleting: true,
    });

    confirm({ ...confirmOptions })
      .then(() => {
        new Promise((resolve, reject) => {
          documentRemove({
            documentID: id,
            resolve,
            reject,
          });
        })
          .then(() => {
            toast.info(
              formatMessage({
                id: 'document.actions.remove.success',
                defaultMessage: 'Document was removed',
              }),
            );
            this.setState({
              deleting: false,
            });
          })
          .catch(() => {
            this.setState({
              deleting: false,
            });
          });
      })
      .catch(() => {
        this.setState({
          deleting: false,
        });
      });
  };

  resetLockedDocument = (prevDocumentsById: TDocumentsMap) => {
    if (!this.lockedDocumentId) return;
    const { documentsById } = this.props;

    const prevDoc = prevDocumentsById.get(this.lockedDocumentId);
    const currentDoc = documentsById.get(this.lockedDocumentId);

    const isMissing = [prevDoc, currentDoc].some((doc) => !doc);
    if (isMissing) this.lockedDocumentId = null;
  };

  updateTags = ({ documentId, tagsToAdd, tagsToRemove }) => {
    const { documentUpdateTags, intl } = this.props;
    new Promise((resolve, reject) => {
      documentUpdateTags({
        documentID: documentId,
        tagsToAdd,
        tagsToRemove,
        resolve,
        reject,
      });
    })
      .then(() => {
        toast.info(
          intl.formatMessage({
            id: 'document.actions.tags.success',
            defaultMessage: 'Documents tags were updated',
          }),
        );
        this.toggleShow('tags');
      })
      .catch(() => {
        this.toggleShow('tags');
      });
  };

  openNotes = (id: string, source: string = 'list') => {
    if (this.lockedDocumentId === id) return;

    this.setState({
      menu: {
        status: false,
        source,
        id,
        position: {
          x: 0,
          y: 0,
        },
      },
      notes: true,
    });
  };

  onClickScan = (value?: boolean) => {
    const { ready, show } = this.state;

    if (ready) {
      this.setState({
        show: value || !show,
      });
    }
  };

  download = async () => {
    const {
      menu: { id },
    } = this.state;
    if (CONFIGURABLE_API_ENDPOINT && id) {
      const { company } = this.props;
      const token = await keeper.getToken();
      const url = `${CONFIGURABLE_API_ENDPOINT}/v2/3/getDocument?dokkaToken=${token}&companyId=${company.id}&documentID=${id}&original=true`;
      download(url, id);
      this.contextMenuHide();
    }
  };

  downloadLinked = async () => {
    const {
      menu: { id },
    } = this.state;
    const { documentsById, downloadLinkedDocs } = this.props;
    const doc = !!id && documentsById.get(id);
    const linkid = !!doc && doc.linkid;

    downloadLinkedDocs({ linkid });

    toast.success(
      <FormattedMessage id="selectedPanel.download.toast" defaultMessage="The download will start in a few seconds" />,
      { autoClose: 7000 },
    );
  };

  printLinked = async () => {
    const {
      menu: { id: menuId },
    } = this.state;
    const {
      documentsById,
      documentGetLinkedIDs,
      apiToken,
      company: { id },
      printPreparationCompleted,
      mergeDocumentsForPrinting,
    } = this.props;
    const doc = !!menuId && documentsById.get(menuId);
    const linkid = !!doc && doc.linkid;

    const ids = await promisify(documentGetLinkedIDs, { linkid });

    if (ids.length > 1) {
      const pdfFile = await promisify(mergeDocumentsForPrinting, { documentID: ids });
      await onPrint(pdfFile, ids[0], false);
    } else {
      const url = Api.getDocumentUrl({ dokkaToken: apiToken, documentID: ids[0], companyId: id });

      await print(url, ids[0], false);
    }
    printPreparationCompleted();
  };

  duplicateDocument = () => {
    const {
      duplicateDocument,
      intl: { formatMessage },
      confirm,
    } = this.props;

    const {
      menu: { id },
    } = this.state;

    const confirmOptions = {
      title: formatMessage({
        id: 'document.show.modals.duplicate.title',
        defaultMessage: 'Duplicate document?',
      }),
      description: formatMessage({
        id: 'document.show.modals.duplicate.placeholder',
        defaultMessage: 'New version of a document will be created',
      }),
    };

    if (this.isAllowAutoConfirm()) {
      duplicateDocument({ id });
    } else {
      confirm({ ...confirmOptions }).then(() => {
        duplicateDocument({ id });
      });
    }
  };

  isAllowAutoConfirm = () => {
    const { userFeatures } = this.props;
    return !userFeatures.get('modal', true);
  };

  onMoveToCompany = (linkIdForMoving) => {
    this.setState({ linkIdForMoving });
    this.toggleShow('moveToCompany');
  };

  onCloseMoveToCompanyDialog = () => {
    this.setState({ linkIdForMoving: null });
    this.toggleShow('moveToCompany');
  };

  onOpenSendByEmailDialog = (linkIdForSending = null) => {
    this.setState({ linkIdForSending });
    this.toggleShow('sendByEmail');
  };

  onCloseSendByEmailDialog = () => {
    this.setState({ linkIdForSending: null });
    this.toggleShow('sendByEmail');
  };

  render() {
    const {
      apiToken,
      match,
      documentsById,
      linkedDocs,
      locale,
      enableLoader,
      disableLoader,
      type,
      editableDoc,
      company,
      rootCategoriesList,
      isGranted,
      loading,
      gridLoading,
      workSpaceType,
      queue,
      removeQueue,
    } = this.props;

    const {
      menu,
      show,
      items,
      selected,
      sign,
      upload,
      tags,
      notes,
      preview,
      moveToCompany,
      linkIdForMoving,
      sendByEmail,
      linkIdForSending,
    } = this.state;

    const isConfidential = type === 'confidential';
    const docsMaps = menu.source === 'linked' ? linkedDocs : documentsById;
    const contextDoc = menu.id ? docsMaps.get(menu.id) : null;

    const backgroundStyles = { ...(isConfidential && { backgroundColor: '#FFF8F8' }) };

    return !company ? null : (
      <CompanyContext.Provider value={{ companyType: type, toggleUpload: () => this.toggleShow('upload') }}>
        <LayoutCompany
          DrawerContent={
            <CompanyNavigation onLinkingSidebarClose={this.onLinkingSidebarClose} onDocMove={this.moveTo} />
          }
        >
          <StickySubheader AppBarProps={{ sx: backgroundStyles }}>
            <>
              <CompanyTitlePanel />
              <CompanyBarPanel
                onUpload={() => this.toggleShow('upload')}
                onDownload={() => this.toggleShow('download')}
                onScan={this.onClickScan}
                gridRef={this.gridRef}
              />
            </>
          </StickySubheader>
          <Container
            maxWidth={false}
            sx={{
              flexGrow: 1, // flexGrow: 1 - for ag-grid table
              px: workSpaceType === 'grid' ? '0px !important' : 'inherit',
              ...backgroundStyles,
            }}
            component={DropTarget}
            refProxy={this.setDropTargetEl}
            onDndDrop={this.onDndDropFromLinked}
            onDragOver={this.onDragOver}
          >
            {show ? (
              <WebTwain
                ready={this.onReady}
                twainRef={this.dropTargetEl}
                hide={this.onClickScan}
                dokkaToken={apiToken}
                onContextMenu={this.contextMenu}
                enableLoader={enableLoader}
                disableLoader={disableLoader}
                isConfidential={type === 'confidential'}
              />
            ) : null}

            <WorkSpace
              match={match}
              dokkaToken={apiToken}
              onShowLinked={this.onLoadLinked}
              onPreview={this.onPreview}
              preview={preview}
              setNavigation={this.setNavigation}
              openNotes={this.openNotes}
              onContextMenu={this.handleContextMenu}
              isContextMenuOpen={menu.status}
              // eslint-disable-next-line max-len
              locale={locale}
              documents={documentsById}
              linkedDocs={linkedDocs}
              moveDocumentToCategory={this.moveTo}
              getDocumentLinkedCount={this.getDocumentLinkedCount}
              onChangeSelected={this.onChangeSelected}
              workSpaceType={workSpaceType}
              selected={selected}
              gridRef={this.gridRef}
              onMoveToCompany={this.onMoveToCompany}
              onOpenSendByEmailDialog={this.onOpenSendByEmailDialog}
            />

            <DocumentContextMenu
              open={menu.status}
              anchor={menu.anchor}
              onClose={this.contextMenuHide}
              options={this.contextMenuItems}
              getDocumentLinkedCountByContextMenu={menu.isFolder ? this.getDocumentLinkedCountByContextMenu : undefined}
            />
            <CircularProgressWithBackdrop type="light" isOpen={loading || gridLoading} />
          </Container>
          {sendByEmail && (
            <DialogSendByEmail
              documentIds={linkIdForSending ? [] : [menu.id]}
              onClose={this.onCloseSendByEmailDialog}
              linkID={linkIdForSending || contextDoc?.linkid}
            />
          )}
          {notes && contextDoc && (
            <DialogNotes
              open={!!(notes && contextDoc)}
              onClose={() => this.toggleShow('notes')}
              value={contextDoc.get('notes')}
              noteColorKey={contextDoc.get('notesColor')}
              documentID={menu.id}
            />
          )}

          {tags && contextDoc && (
            <DialogTagsManage
              open={!!(tags && contextDoc)}
              initialTags={contextDoc.get('tags').toJS()}
              onSubmit={(data) => this.updateTags({ documentId: menu.id, ...data })}
              onCancel={() => this.toggleShow('tags')}
            />
          )}
          {editableDoc.documentID ? <DocumentEdit onClose={this.documentEditHide} /> : null}
          <DialogUpload
            open={Boolean(upload || items)}
            items={items}
            format={company.get('dateFormat')}
            queue={queue}
            remove={removeQueue}
            isGranted={isGranted}
            confidential={isConfidential}
            closeModal={this.onUploadClose}
            rootCategoriesList={rootCategoriesList}
          />
          {contextDoc && sign && (
            <DialogSignDocument
              onSign={this.onSign}
              documents={Set.of(contextDoc)}
              signParams={company.get('signParams')}
              onClose={() => this.toggleShow('sign')}
            />
          )}
          {moveToCompany && (
            <DialogMoveDocumentsToCompany
              documentId={menu.id}
              onClose={this.onCloseMoveToCompanyDialog}
              linkID={linkIdForMoving || contextDoc?.linkid}
            />
          )}
          <EventsHistoryPanel
            match={match}
            dokkaToken={apiToken}
            documentsById={documentsById}
            onShowLinked={this.onLoadLinked}
            setNavigation={this.setNavigation}
            isConfidential={isConfidential}
            company={company}
          />
          <DialogKeyboardHotkeys />
        </LayoutCompany>
      </CompanyContext.Provider>
    );
  }
}

const mapStateToProps = (state: DokkaStore, props: Props) => ({
  query: querySelector(state),
  loading: loadingSelector(state),
  gridLoading: documentsGridLoaderSelector(state),
  documentsById: documentsByIdSelector(state),
  locale: localeSelector(state),
  linkedDocs: documentLinkedSelector(state),
  sortedLinkedDocs: documentSortedLinkedSelector(state),
  restriction: ACL.myRestriction(state),
  isGranted: ACL.isGranted(state),
  company: companiesByIdSelector(state).get(props.match.params.companyId),
  editableDoc: editableDocSelector(state),
  rootCategoriesList: rootCategoriesListSelector(state),
  documentLinkedTag: documentLinkedTagSelector(state),
  queue: uploadQueueSelector(state),
  linkingTag: linkingTagSelector(state),
  workSpaceType: documentWorkSpaceTypeSelector(state),
  userFeatures: userFeaturesSelector(state),
  getIndicateWarningByDoc: getIndicateWarningByDocSelector(state),
});

const mapDispatchToProps = {
  documentGetLinked: documentGetLinkedAction,
  documentGetLinkedForPreview: documentGetLinkedForPreviewAction,
  documentGetLinkedIDs: documentGetLinkedIDsAction,
  printPreparationCompleted: () => ({ type: documentGetLinkedIDsAction.success }),
  hideLinkedDocs: documentHideLinkedDocs,
  documentUpdateTags: documentUpdateTagsInList,
  documentLink: documentLinkInListAction,
  documentUnlink: documentUnlinkInList,
  documentRemove: documentRemoveAction,
  documentSetAsGeneral: documentSetAsGeneralAction,
  documentSetAsFinancial: documentSetAsFinancialAction,
  enableLoader: scanningRequest,
  disableLoader: () => ({ type: scanningRequest.success }),
  unlinkDocuments: documentsUnlink,
  signDocument: documentSignDocument,
  saveBackUrl: saveBackSearchUrlAction,
  setNavigation: setNavigationAction,
  duplicateDocument: duplicateDocumentAction,
  markAsPaid: markAsPaidAction,
  addEditableDocument: addEditableDoc,
  clearEditableDocument: clearEditableDoc,
  moveDocumentToCategory: moveDocumentToCategoryAction,
  getDocumentLinkedCount: documentGetLinkedCountAction,
  removeQueue: queueRemoveAction,
  setLinkingTag: setLinkingTagAction,
  setDocumentAsCover: documentSetAsCoverAction,
  navigatePush: navigate.push,
  ignoreDocumentWarnings: documentIgnoreWarningsAction,
  downloadLinkedDocs: documentsDownloadDocumentsAction,
  mergeDocumentsForPrinting: mergeDocumentsForPrintingAction,
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withPropsCollector,
  withApiToken,
  withConfirm,
)(CompanyPage);
