/* eslint-disable array-callback-return */
/* @flow */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import update from 'lodash/fp/update';
import flow from 'lodash/fp/flow';
import merge from 'lodash/fp/merge';
import partialRight from 'lodash/fp/partialRight';
import { invertPinnedValue } from 'pages/company/grid/helpers';

import { type TColumnDefs } from 'pages/company/grid/types.js.flow';
import { type TGridItemValueType } from 'domain/documents/types.js.flow';

import toast from 'components/Toast';

const DEFAULT_ROW_NAME = ['Default', 'ברירת מחדל'];
const READONLY_COLUMN_NAMES = ['default_values', 'approval_flow', 'two_way_match'];

export const isDefaultRow = (colName: string) => DEFAULT_ROW_NAME.includes(colName);

export const isReadOnlyCell = (row: Object) => {
  const {
    column: { colId },
    data: {
      supplier: { label },
    },
  } = row;

  return isDefaultRow(label) && READONLY_COLUMN_NAMES.some((colName) => colId.includes(colName));
};

const extendedListCellEditorParams = {
  // this tells grid how to render list option represented as {} as flat value
  formatValue: (value) => value.label || 'No value',
};

const stringColDef = {
  filter: 'agTextColumnFilter',
  filterParams: { buttons: ['reset'] },
  cellClass: ['__cell-is-readonly'],
  valueGetter: (params) => {
    const [group] = params.colDef.field.split('.');

    return params.data[group].label;
  },
  valueFormatter: ({ value }) => value.label,
  tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
  tooltipComponent: 'customTooltip',
};

const supplierNameColDef = {
  ...stringColDef,
  lockPinned: true,
  lockPosition: true,
};

const listColDef = {
  cellRenderer: 'dropdownCellRenderer',
  cellRendererParams: {
    needContainerForDetectOverflow: true,
  },
  cellEditor: 'dropdownCellEditor',
  cellEditorPopup: true,
  editable: (params) => !isReadOnlyCell(params),
  cellClassRules: {
    '__cell-is-readonly': (params) => isReadOnlyCell(params),
  },
  filter: 'agSetColumnFilter',
  filterParams: {
    buttons: ['reset'],
    showTooltips: true,
    cellRenderer: 'filterSetRender',
    valueGetter: (params) => {
      const { data, colDef } = params;
      const [group, field] = colDef.field.split('.');

      return data[group][field].label;
    },
  },
  valueGetter: ({ colDef, data }) => {
    const [group, field] = colDef.field.split('.');

    return data[group][field];
  },
  valueSetter: (params) => {
    const { data, newValue } = params;
    const [group, field] = params.colDef.field.split('.');
    data[group][field] = newValue;
    // this forces cell update.
    // we can prevent update if the same value has been selected
    return true;
  },
  valueFormatter: ({ value }) => value.label,
  tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
  tooltipComponent: 'customTooltip',
  suppressKeyboardEvent: (params) => {
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const ENTER = 13;
    const BACKSPACE = 8;
    const DELETE = 46;
    const {
      data,
      editing,
      event: { keyCode },
      node,
    } = params;

    if (!editing) {
      // allows to remove cell data on backspace/delete key pressed event
      const isBackspaceKey = keyCode === BACKSPACE;
      const isDeleteKey = keyCode === DELETE;

      if (isBackspaceKey || isDeleteKey) {
        const [group, field] = params.colDef.field.split('.');
        data[group][field] = {};
        node.setData(data);
        return true;
      }
      return false;
    }

    // grid handling of this keys must suppressed and handled by editor itself
    const gridHouldnotHandleEvent = editing && (keyCode === KEY_UP || keyCode === KEY_DOWN || keyCode === ENTER);

    return gridHouldnotHandleEvent;
  },
};

const multipleListColDef = {
  ...listColDef,
  cellEditor: 'multipleDropdownCellEditor',
  cellRenderer: 'multipleDropdownCellRenderer',
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  filterParams: {
    valueGetter: (params) => {
      const { data, colDef } = params;
      const [group, field] = colDef.field.split('.');

      return data[group][field].map(({ label }) => label);
    },
  },
  valueFormatter: ({ value }) => value.map(({ label }) => label).join(', '),
};

const getBooleanColDef = (formatMessage) => ({
  cellRenderer: 'booleanCellRenderer',
  editable: false,
  cellClassRules: {
    '__cell-is-readonly': (params) => isReadOnlyCell(params),
  },
  cellStyle: {
    textAlign: 'center',
  },
  valueGetter: (params) => {
    const [group, field] = params.colDef.field.split('.');
    // value can be null - ag-grid have third mode for checkbox - indeterminate
    // convert null to boolean
    return Boolean(params.data[group][field]);
  },
  valueSetter: (params) => {
    const { data, newValue } = params;
    const [group, field] = params.colDef.field.split('.');
    data[group][field] = newValue;
    // this forces cell update.
    // we can prevent update if the same value has been selected
    return true;
  },
  filterParams: {
    valueFormatter(params) {
      const value = !!params.value;
      return formatMessage({
        id: `configurations.vendorPreferences.grid.filter.value.${value}`,
        defaultMessage: value,
      });
    },
  },
});

const numberColDef = {
  cellRenderer: 'inputCellRenderer',
  cellEditor: 'inputNumberCellEditor',
  cellClassRules: {
    'cell-negative-number': (x: { value: TGridItemValueType }) => typeof x.value === 'number' && x.value < 0,
  },
  filter: 'agNumberColumnFilter',
  filterParams: { buttons: ['reset'] },
};

const thresholdExtendedColDef = {
  ...numberColDef,
  valueSetter: (params) => {
    const MAX_VALUE = 100000000;

    const cellNotEmpty = (value: string | null) => value !== '' && value !== null;
    const valueEmpty = (value: ?string) => value === '' || typeof value === 'undefined';
    const validateNumber = (value: string) => {
      // empty string allows for deleting cell value
      if (parseInt(value, 10) < MAX_VALUE || valueEmpty(value)) {
        return true;
      }
      toast.error(
        <FormattedMessage
          id="toast.validation.entered.notValidNumber"
          defaultMessage="The value entered is not a valid number"
        />,
      );

      return false;
    };

    const validateRange = (value: string, column: 'min_level' | 'max_level', thresholds: Object) => {
      switch (column) {
        case 'min_level':
          if (valueEmpty(value)) return true;
          if (cellNotEmpty(thresholds.max_level)) {
            if (parseFloat(value) < parseFloat(thresholds.max_level)) {
              return true;
            }

            toast.error(
              <FormattedMessage
                id="toast.validation.entered.lessThan"
                values={{ value: thresholds.max_level }}
                defaultMessage={`The value entered should be less than ${thresholds.max_level}`}
              />,
            );
            return false;
          }
          return true;
        case 'max_level': {
          if (valueEmpty(value)) return true;
          if (cellNotEmpty(thresholds.min_level)) {
            if (parseFloat(value) > parseFloat(thresholds.min_level)) {
              return true;
            }
            toast.error(
              <FormattedMessage
                id="toast.validation.entered.moreThan"
                values={{ value: thresholds.min_level }}
                defaultMessage={`The value entered should be more than ${thresholds.min_level}`}
              />,
            );
            return false;
          }
          return true;
        }
        default:
          return false;
      }
    };

    const [field, level] = params.colDef.field.split('.');
    const { data, newValue } = params;

    if (validateNumber(newValue) && validateRange(newValue, level, data[field])) {
      const result = parseFloat(newValue);
      data[field][level] = !Number.isNaN(result) ? result : null;
      // this allows cell update with new value
      return true;
    }

    return false;
  },
};

// extends BE-driven json grid config with grid functions to ensure proper dropdowns behaviour
export const columnDefsAdapter = (
  headers: TColumnDefs[],
  isRTL: boolean,
  formatMessage: ({ id: string, defaultMessage: string }) => string,
) =>
  headers
    ? headers.map((header) => {
        if (Array.isArray(header.children)) {
          return update(['children'], (children) => {
            const subheaders = isRTL ? invertPinnedValue(children) : children;
            return subheaders.map((subheader) => {
              switch (subheader.type) {
                case 'list': {
                  return flow(
                    // this extends 'header' with 'debitAccountExtendedColDef' fields in data-last style
                    // merge is not data-last optimized, so must apply partial to sort of curry it
                    partialRight(merge)([listColDef]),
                    update(['cellEditorParams'], (params) => merge(extendedListCellEditorParams, params)),
                  )(subheader);
                }
                case 'multi_list': {
                  return flow(
                    // this extends 'header' with 'debitAccountExtendedColDef' fields in data-last style
                    // merge is not data-last optimized, so must apply partial to sort of curry it
                    partialRight(merge)([multipleListColDef]),
                    update(['cellEditorParams'], (params) => merge(extendedListCellEditorParams, params)),
                  )(subheader);
                }
                case 'number':
                  if (subheader.field.includes('amount_threshold')) {
                    return merge(thresholdExtendedColDef, subheader);
                  }
                  return merge(numberColDef, subheader);

                case 'string': {
                  if (subheader.field.includes('supplier')) {
                    return merge(supplierNameColDef, subheader);
                  }
                  return merge(stringColDef, subheader);
                }

                case 'boolean': {
                  return merge(getBooleanColDef(formatMessage), subheader);
                }

                default:
                  return subheader;
              }
            });
          })(header);
        }
      })
    : null;
