import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentCompanyDateFormatSelector, shouldHighlightAmountSelector } from 'domain/companies/companiesSelector';
import moment from 'moment';

import { compose } from 'redux';
import { convertToFloatNumber, splitNumberByCommas } from 'lib/helpers';

export const SYMBOL_FOR_EMPTY_CELL = '–';

const useAgGridColumnTypes = () => {
  const companyDateFormat = useSelector(currentCompanyDateFormatSelector);
  const shouldHighlightAmount = useSelector(shouldHighlightAmountSelector);
  const dateFormat = companyDateFormat || 'DD/MM/YYYY';
  // apply filter params in addFilters method - here only if you need some data from other places (redux etc)
  return useMemo(
    () => ({
      text: {},
      date: {
        filterParams: {
          format: dateFormat,
        },
        valueFormatter: ({ value }) => (value ? moment(value).format(dateFormat) : SYMBOL_FOR_EMPTY_CELL),
      },
      number: {
        cellStyle: { textAlign: 'right' },
        valueFormatter: ({ value }) =>
          typeof value === 'number' ? compose(splitNumberByCommas, convertToFloatNumber)(value) : SYMBOL_FOR_EMPTY_CELL,
      },
      string: {},
      actions: {
        width: 0,
      },
      integer: {
        valueFormatter: ({ value }) => (typeof value === 'number' ? parseInt(value, 10) : SYMBOL_FOR_EMPTY_CELL),
      },
      select: {},
      extraSelect: {
        cellRenderer: 'statusCellRenderer',
        cellRendererParams: {
          needContainerForDetectOverflow: true,
        },
        valueFormatter: ({ value }: { value: string[] }) =>
          Array.isArray(value) && !!value.length ? value.join(';') : SYMBOL_FOR_EMPTY_CELL,
      },
      static_select: {},
      amount: {
        cellRenderer: 'amount',
        cellClass: 'amount',
        cellStyle: { textAlign: 'right' },
        valueFormatter: ({ value }) => (shouldHighlightAmount ? Math.abs(value) : value),
      },
      tag: {
        cellRenderer: 'tag',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          padding: '0px',
          maxWidth: '100%',
        },
        maxWidth: 1200,
        minWidth: 100,
      },
      approval: {
        cellRenderer: 'approval',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          padding: '0px',
          maxWidth: '100%',
        },
        maxWidth: 1000,
        minWidth: 100,
      },
    }),
    [shouldHighlightAmount, dateFormat],
  );
};

export default useAgGridColumnTypes;
