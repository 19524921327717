// @flow
import React, { useCallback, memo } from 'react';

import {
  MultipleAutocompleteVirtualized,
  TextField,
} from 'pages/company/grid/components/MultipleDropdownCellEditor/StyledComponents';
import Chip from '@mui/material/Chip';

import type { TOption, TOptions } from 'components/mui/Form/Autocomplete/AutocompleteBase';

type TProps = {
  column: any,
  onValueChange: (TOption | {}) => void,
  value: TOption | {},
  values: TOptions,
};

const MultipleDropdownCellEditor: React$StatelessFunctionalComponent<TProps> = ({
  values,
  column: { actualWidth },
  onValueChange,
  value,
}) => {
  const handleChange = useCallback(
    (e, selected) => {
      onValueChange(selected);
    },
    [onValueChange],
  );

  const renderTags = useCallback(
    (chips, getTagProps) =>
      chips.map((option, index) => (
        <Chip key={option.value} label={option.label} size="small" {...getTagProps({ index })} />
      )),
    [],
  );

  return (
    <MultipleAutocompleteVirtualized
      open
      value={value}
      options={values}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} inputProps={{ ...params.inputProps }} variant="standard" autoFocus />
      )}
      ListboxProps={{ optionStyle: { unicodeBidi: 'normal' }, suppressToolTip: false }}
      renderTags={renderTags}
      sx={{ width: actualWidth }}
      fullWidth
      autoComplete
      disablePortal
      disableCloseOnSelect
      multiple
      filterSelectedOptions
    />
  );
};

export default memo(MultipleDropdownCellEditor);
