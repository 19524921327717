// @flow
import React from 'react';
import { isEllipsisActive, ID_FOR_DETECT_CELL_OVERFLOW, SET_FILTER_HOVER_CLASSNAME } from 'pages/company/grid/helpers';

type Props = {|
  value: string,
  valueFormatted: string,
  colDef: {| cellRendererParams: { needContainerForDetectOverflow?: boolean } |},
|};

export default ({ valueFormatted, value, colDef }: Props) => {
  const rowCell = document.querySelector(
    `.ag-row-hover .ag-column-hover ${
      colDef?.cellRendererParams?.needContainerForDetectOverflow ? `#${ID_FOR_DETECT_CELL_OVERFLOW}` : ''
    }`,
  );

  const filterCell = document.querySelector(`.${SET_FILTER_HOVER_CLASSNAME}`);
  const cell = rowCell || filterCell;

  const isVisible = Boolean(cell) && isEllipsisActive(cell);

  return (
    <div className="ag-tooltip-custom--content" style={{ display: isVisible ? 'flex' : 'none' }}>
      {valueFormatted || value}
    </div>
  );
};
