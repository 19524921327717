// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { exportFormatsSelector } from 'domain/companies';
import { getExportFormatOptions } from 'pages/company/helpers';
import { exportDataAction } from 'domain/documents';
import * as ACL from 'domain/restriction';
import type { TStoreExportFormats } from 'domain/documents/types.js.flow';
import { type GridRef } from 'pages/company/grid/types.js.flow';

import Tooltip from 'components/mui/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

type TIconButtonExport = {
  gridRef: GridRef,
};

const mapStateToProps = (state) => ({
  exportFormats: exportFormatsSelector(state),
  isGranted: ACL.isGranted(state),
});

const IconButtonExport: React$StatelessFunctionalComponent<TIconButtonExport> = ({ gridRef }) => {
  const dispatch = useDispatch();
  const popupState = usePopupState({ variant: 'popover', popupId: 'LinkedExportMenu' });
  const { exportFormats, isGranted } = useSelector(mapStateToProps);
  const isVisible = isGranted(ACL.IS_BOOKKEEPER_IN_FINANCIAL_COMPANY);

  const options = getExportFormatOptions(exportFormats);

  const onClick = (format: TStoreExportFormats) => {
    const actionForGrid = gridRef.current
      ? {
          xlsx: gridRef.current.exportAllRowsDataAsExcel,
          csv: gridRef.current.exportAllRowsDataAsCsv,
        }
      : {};
    dispatch(exportDataAction({ format, actionForGrid }));
    popupState.close();
  };

  useEffect(() => {
    if (popupState.isOpen && gridRef.current) {
      // TODO: ag-grid check grid force update
      gridRef.current.forceUpdate();
    }
  }, [popupState, gridRef]);

  return isVisible ? (
    <>
      <Tooltip
        t={{
          id: 'tooltip.export',
          defaultMessage: 'Export',
        }}
      >
        <IconButton color="primary" {...bindTrigger(popupState)}>
          <SimCardDownloadOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} onClick={() => onClick(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

export default IconButtonExport;
