// @flow
import { List, OrderedMap, Set, type RecordOf, Map } from 'immutable';
import { compose } from 'redux';
import moment from 'moment';
import {
  DocumentFactory,
  DocumentsFactory,
  ViewinfoFactory,
  ValidatedDocumentFactory,
} from 'domain/documents/factories';
import { ApprovalsStoreAdapter } from 'domain/approvals';
import CONST from './constants';
import { storage } from 'lib/storage';
import { type TPublishTypes } from 'pages/document/components/DocumentPublishButton/DropdownButton/types.js.flow';
import type {
  TDocument,
  TDocumentRecord,
  TDocumentsMap,
  TDocumentsStore,
  TViewinfo,
  TRawValidatedDocument,
  TValidatedDocuments,
} from 'domain/documents/types.js.flow';

export type Data = {|
  count: number,
  processing: number,
  company_total: number,
  count_without_link_panels: number,
  document?: TDocument,
  items: Array<TDocument>,
  pageToken: string,
|};

type DocumentGroup = OrderedMap<string, List<TDocument | void>>;

const TypeTags = {
  _T_FIN: 'financial',
  _T_GEN: 'general',
};

const OptionalTags = {
  _S_PROTECTED: 'protected',
};

const PairsTagsStatuses = [
  {
    status: 'signed',
    tags: Set.of('_S_SIG_DOKKA1_SIGNED', '_S_SIG_DOKKA2_SIGNED'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_PENDING', '_S_SIG_DOKKA2_PENDING'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_SIGNED', '_S_SIG_DOKKA2_PENDING'),
  },
  {
    status: 'pending',
    tags: Set.of('_S_SIG_DOKKA1_PENDING', '_S_SIG_DOKKA2_SIGNED'),
  },
];

const StatusTags = {
  _S_NEW: 'new',
  _S_READ: 'read',
  _S_ACCEPTED: 'accepted',
  _S_REJECTED: 'rejected',
  _S_SIG_ORIGINAL: 'signed',
  _S_PREVIEW_STATUS: 'isBeingProcessed',
  _S_SCHEDULED_ACCEPTANCE: 'isScheduledAcceptance',
};

const pairsTags = PairsTagsStatuses.map((pair) => pair.tags).reduce((res, tags) => res.concat(tags), new Set());

const StatusTagsSet = Set.fromKeys(StatusTags).concat(pairsTags);

export type TypeTag = $Keys<typeof TypeTags>;
type OptionalTag = $Keys<typeof OptionalTags>;
export type StatusTag = $Keys<typeof StatusTags>;
export type ProcessedTypeTag = $Values<typeof TypeTags>;
export type ProcessedStatusTag = $Values<typeof StatusTags>;

function checkStatus(tags) {
  const statusTags = StatusTagsSet.intersect(tags);
  let statuses = statusTags.map((tag) => StatusTags[tag]);
  PairsTagsStatuses.forEach((pair) => {
    if (pair.tags.intersect(tags).size === pair.tags.size) {
      statuses = statuses.add(pair.status);
    }
  });
  return statuses.toArray();
}

// eslint-disable-next-line max-len
export function parseTags(document: TDocument | TRawValidatedDocument): TDocument | TRawValidatedDocument {
  // we know tags is Array, not Set
  let tags = ((document.tags || []: any): Array<string>);
  tags = tags.map((item) => item.trim());
  // @to-do quick fallback. return empty values for type and status if no tags present
  const TypeTagsSet: Set<TypeTag> = Set.fromKeys(TypeTags);
  const typeKey = TypeTagsSet.intersect(tags).toArray();
  const OptionalTagsSet: Set<OptionalTag> = Set.fromKeys(OptionalTags);
  const protectedKey = OptionalTagsSet.intersect(tags).toArray();
  return Object.assign(document, {
    doctype: typeKey.length ? TypeTags[typeKey[0]] : undefined,
    status: checkStatus(tags),
    protected: protectedKey.length ? Boolean(OptionalTags[protectedKey[0]]) : false,
  });
}

function castTagsToSet(document: TDocument | TRawValidatedDocument): TDocument | TRawValidatedDocument {
  const castedDocument = document;
  if (document && Array.isArray(document.tags)) {
    castedDocument.tags = Set(castedDocument.tags);
  }
  castedDocument.approvals = document.approvals || new Map();
  return castedDocument;
}

function castSnakeCaseProps(document: TDocument | TRawValidatedDocument): TDocument | TRawValidatedDocument {
  const { notes_color: notesColor, ...tail } = document;
  return { ...tail, notesColor };
}

export function documentCompositionFunctionsAdapter(d: TDocument) {
  return compose(DocumentFactory, castTagsToSet, parseTags, castSnakeCaseProps)(d);
}

export function documentsListAdapter(l: Array<TDocument>): TDocumentsMap {
  // eslint-disable-next-line max-len
  return l.reduce((a, v: TDocument) => a.set(v.documentID, documentCompositionFunctionsAdapter(v)), new OrderedMap());
}

export function documentsAdapter({
  items,
  pageToken,
  count,
  processing,
  // eslint-disable-next-line camelcase
  company_total,
  // eslint-disable-next-line camelcase
  count_without_link_panels,
}: Data): TDocumentsStore {
  return DocumentsFactory({
    list: documentsListAdapter(items),
    pageToken,
    count,
    processing,
    company_total,
    count_without_link_panels,
  });
}

export function viewInfoAdapter({ pages, rotations }: TViewinfo): RecordOf<TViewinfo> {
  const positiveRotations = (rotations || []).map((angle) => (angle < 0 ? angle + 360 : angle));
  return ViewinfoFactory({
    pages,
    rotations: List(positiveRotations),
  });
}

export function documentAdapter(document: TDocument): { document: TDocumentRecord } {
  return {
    document: DocumentFactory({
      ...compose(castTagsToSet, parseTags, castSnakeCaseProps)(document),
      viewinfo: viewInfoAdapter(document.viewinfo),
    }),
  };
}

// eslint-disable-next-line max-len
export function documentAdapterValidated(document: TRawValidatedDocument): { document: TValidatedDocuments } {
  return {
    document: ValidatedDocumentFactory({
      ...compose(castTagsToSet, parseTags, castSnakeCaseProps)(document),
      viewinfo: viewInfoAdapter(document.viewinfo),
      approvals: ApprovalsStoreAdapter(document.approvals),
      // eslint-disable-next-line max-len
      acceptValidationError: document.details && document.details.error ? document.details.error : null,
    }),
  };
}

export function sorterByCreated<T: { created: string, documentID: string }>(a: T, b: T) {
  const aCreated = new Date(a.created).getTime();
  const bCreated = new Date(b.created).getTime();

  return bCreated === aCreated ? a.documentID.localeCompare(b.documentID) : bCreated - aCreated;
}

export const sorterByFavoriteOrCreated = <T: { +created: string, favorite: boolean }>(a: T, b: T) =>
  // $FlowFixMe
  b.favorite - a.favorite || sorterByCreated(a, b);

export function documentGroup(list: List<TDocument>): DocumentGroup {
  return OrderedMap(list.groupBy((e) => moment(e.created).format('MM-YYYY')));
}

// @to-do use function composition for next 2 functions
export function documentsWithUniqLinkedUngrouped(d: List<TDocument>): List<TDocument | void> {
  // filter linked document and group by linkID
  const groupedLinkedDocs = d.filter((doc) => doc.linkid).groupBy((doc) => doc.linkid);

  // sort linked documents by favorites and created fields
  const uniqLinkedDocs = groupedLinkedDocs.reduce((a, v, key) => {
    // favorite => fresh doc => old doc - always
    const sortedLinkedList = v.sort(sorterByFavoriteOrCreated);

    return a.set(key, sortedLinkedList);
  }, new OrderedMap());

  // list of linked ids which were set
  let alreadySetCoverForLinkedIDs = new List();

  return d.reduce((list, document) => {
    if (alreadySetCoverForLinkedIDs.includes(document.linkid)) return list;

    if (document.linkid) {
      alreadySetCoverForLinkedIDs = alreadySetCoverForLinkedIDs.push(document.linkid);

      if (!document.favorite) {
        const linkedDocuments = uniqLinkedDocs.get(document.linkid);
        // first document will be always favorite or more fresh by date
        return list.push(linkedDocuments.first());
      }
    }

    return list.push(document);
  }, new List());
}

export function documentGroupSupplier(list: List<TDocument>) {
  return list
    .groupBy((e) => e.stateColumn)
    .map((grouppedList) => {
      // group linked inside each supplier dashboard column as opposed to standard workspace
      // where we group by linked first.
      // this allows 2 linked docs to appear on 2 separate columns
      const grouppedListUnique = documentsWithUniqLinkedUngrouped(grouppedList);
      return OrderedMap(
        grouppedListUnique.sort(sorterByFavoriteOrCreated).groupBy((e) => moment(e.created).format('MM-YYYY')),
      );
    });
}

export const documentsWithUniqLinkedSupplier = documentGroupSupplier;

export const documentsWithUniqLinked = (d: List<TDocument>): DocumentGroup => {
  const list = documentsWithUniqLinkedUngrouped(d);

  return documentGroup(list);
};

export function documentsWithoutLinkedAndAccepted(d: List<TDocument>): DocumentGroup {
  return d.filter((doc) => !doc.linkid);
}

export const acceptedSignTypes = Set(['pdf', 'jpg', 'jpeg', 'png', 'docx', 'xlsx', 'xlsm', 'pptx', 'tiff', 'tif']);

export function checkAcceptSignType(id: string): boolean {
  const extension = id.split('.').pop();
  return acceptedSignTypes.has(extension);
}

export const isDocPaid = (doc: TDocument): boolean => doc.tags.includes(CONST.tags.paid);

export const allowedExportFormatsForGrid = ['csv', 'xlsx'];

export const getCurrentStoredConfig = () => {
  const record = storage().workspaceAgGridConfig().get();
  return record ? JSON.parse(record) : {};
};

export const updateGridConfigChanges = (params) => {
  const defaultState = {
    gridColumns: null,
    gridSorting: null,
    gridFilters: null,
  };

  // companyId, currentPresetId
  const currentStoredConfig = getCurrentStoredConfig();
  const storeCurrentConfig =
    currentStoredConfig.companyId === params.companyId && currentStoredConfig.currentPresetId === params.currentPresetId
      ? currentStoredConfig
      : {};

  const update = { ...defaultState, ...storeCurrentConfig, ...params };
  storage().workspaceAgGridConfig().set(JSON.stringify(update));
};

export const deleteGridConfigChanges = () => {
  storage().workspaceAgGridConfig().remove();
};

const getCurrentPublishTypeID = (userId: string) => `publishType-${userId}`;

export const getCurrentPublishType = (userId: string) => window.localStorage.getItem(getCurrentPublishTypeID(userId));

export const updateCurrentPublishType = (userId: string, publishType: TPublishTypes) =>
  window.localStorage.setItem(getCurrentPublishTypeID(userId), publishType);

export const decodeArrayBufferText = (value) => {
  try {
    const dataView = new DataView(value);
    const decoder = new TextDecoder('utf8');
    return JSON.parse(decoder.decode(dataView));
  } catch (e) {
    return e;
  }
};

export const mergeDocumentsMapWithPreviousGridMeta = (originList, documentsWereChangedList) =>
  originList.mergeWith((oldVal, newVal) => newVal.set('gridMeta', oldVal.gridMeta), documentsWereChangedList);
