/* @flow */
import React, { memo } from 'react';
import { ID_FOR_DETECT_CELL_OVERFLOW } from 'pages/company/grid/helpers';

import {
  FakeDropdownContainer,
  FakeDropdown,
  FakeDropdownLabel,
  FakeDropdownLabelText,
} from 'pages/company/grid/components/DropdownCellRenderer/StyledComponents';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IProps {
  api: any;
  rowIndex: number;
  column: any;
  value: { label: string };
}

const DropdownCellRenderer = (props: IProps) => {
  const { value, colDef } = props;
  const isEditable = typeof colDef.editable === 'function' ? colDef.editable(props) : colDef.editable;

  return (
    <FakeDropdownContainer>
      <FakeDropdown
        clickable={isEditable}
        disabled={!isEditable}
        label={
          <FakeDropdownLabel>
            <FakeDropdownLabelText id={ID_FOR_DETECT_CELL_OVERFLOW}>{value.label}</FakeDropdownLabelText>
            <ArrowDropDownIcon />
          </FakeDropdownLabel>
        }
      />
    </FakeDropdownContainer>
  );
};

export default memo(DropdownCellRenderer);
