import moment from 'moment';
import type { GridHeaderItemType, GridRawHeaderItemType, TOperators } from 'domain/documents/types.js.flow';

const OPERATORS = {
  eq: 'equals',
  neq: 'notEqual',
  contains: 'contains',
  notContains: 'notContains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  exist: 'exist',
  notExist: 'notExist',
  gt: 'greaterThan',
  gte: 'greaterThanOrEqual',
  lt: 'lessThan',
  lte: 'lessThanOrEqual',
  inrange: 'inRange',
  after: 'after',
  afterOrOn: 'afterOrOn',
  before: 'before',
  beforeOrOn: 'beforeOrOn',
};

const withCustomOperators = {
  ...OPERATORS,
  exist: {
    displayKey: 'exist',
    displayName: 'Exist',
    predicate: (_, cellValue) => !!cellValue,
    hideFilterInput: true,
  },
  notExist: {
    displayKey: 'notExist',
    displayName: 'Not exist',
    predicate: (_, cellValue) => !cellValue,
    hideFilterInput: true,
  },
  after: {
    displayKey: 'after',
    displayName: 'After',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isAfter(moment(filterValue).startOf('day')),
  },
  afterOrOn: {
    displayKey: 'afterOrOn',
    displayName: 'After or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrAfter(moment(filterValue).startOf('day')),
  },
  before: {
    displayKey: 'before',
    displayName: 'Before',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isBefore(moment(filterValue).startOf('day')),
  },
  beforeOrOn: {
    displayKey: 'beforeOrOn',
    displayName: 'Before or on',
    predicate: ([filterValue], cellValue) =>
      moment(cellValue).startOf('day').isSameOrBefore(moment(filterValue).startOf('day')),
  },
};

const operatorsByType = {
  tag: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  approval: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  string: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
  number: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'inrange'],
  date: ['beforeOrOn', 'afterOrOn', 'inrange'],
  select: [],
  extraSelect: [],
};

const DEFAULT_OPTION_LIST = {
  string: OPERATORS.startsWith,
  number: OPERATORS.eq,
  integer: OPERATORS.eq,
  date: OPERATORS.beforeOrOn,
};

const filterMappingByColumnType = [
  {
    string: { filter: 'agTextColumnFilter' },
  },
  {
    number: { filter: 'agNumberColumnFilter' },
  },
  {
    integer: { filter: 'agNumberColumnFilter' },
  },
  {
    date: {
      filter: 'agDateColumnFilter',
    },
  },
  {
    boolean: { filter: 'booleanColumnFilter' },
  },
  {
    tag: {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
      },
    },
  },
  {
    approval: {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith', 'exist', 'notExist'],
        trimInput: true,
      },
    },
  },
  {
    amount: { filter: 'agNumberColumnFilter' },
  },
  {
    select: { filter: 'customSetColumnFilter' },
  },
  {
    static_select: {
      filter: 'customSetColumnFilter',
    },
  },
];

const DEFAULT_FILTER_TYPE = 'string';

const FILTERS_LIST = filterMappingByColumnType.reduce((acc, v) => {
  const [type, filter] = Object.entries(v)[0];
  const operators = operatorsByType[type];

  acc[type] = {
    ...filter,
    filterParams: {
      ...(filter.filterParams || {}),
      defaultOption: DEFAULT_OPTION_LIST[type],
      filterOptions: operators && operators.map((operator) => withCustomOperators[operator]),
      showTooltips: true,
    },
  };

  return acc;
}, {});

const createFiltersWithDefaultOption = (column: GridRawHeaderItemType, operator: ?TOperators) => {
  const filterByTypeOrDefault = FILTERS_LIST[column.type] || FILTERS_LIST[DEFAULT_FILTER_TYPE];
  const { filter, filterParams } = column;
  const headerComponentParams = {
    // enableFilterButton managed in new column menu by suppressHeaderFilterButton props
    // ag-grid docs - Set to true to not display the filter button in the column header. Doesn't apply when columnMenu = 'legacy'.
    // so for the legacy menu, simulate behavior of new column menu and control with the b-end to show or hide the filter icon
    headerComponentParams: { enableFilterButton: column.filter },
  };

  return {
    filter: filter === true ? filterByTypeOrDefault.filter : false, // if b-end filter === false - turn off filter for column, otherwise apply mapping by column type
    filterParams: {
      ...(filterByTypeOrDefault.filterParams || {}), // f-end filter params
      ...(filterParams || {}), // b-end filter params - has a greater weight than f-end
      ...(operator && { defaultOption: OPERATORS[operator] }),
      closeOnApply: true,
    },
    ...headerComponentParams,
  };
};

export const addFilters = (columns: GridHeaderItemType[]): Array<*> =>
  columns.map(({ operator: columnOperator, ...column }) => {
    const operators = operatorsByType[column.type];
    const operator: ?TOperators = (operators && operators.includes(columnOperator) && columnOperator) || undefined;
    const filter = createFiltersWithDefaultOption(column, operator);

    return {
      ...column,
      ...filter,
    };
  });
