// @flow
import React, { useState, useCallback, memo, useRef, useEffect, useMemo } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { useIntl } from 'react-intl';
import Api from 'domain/api';
import isEqual from 'lodash/isEqual';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VirtualizedList from 'pages/company/grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedList';
import { type TFilterRowOptions } from 'pages/company/grid/components/CustomHeaderFilter/CustomSetColumnFilter/VirtualizedRow';
import { debounce } from '@mui/material/utils';

type TModel =
  | {
      type: string,
      values: TFilterRowOptions,
    }
  | undefined;

type TCustomSetColumnFilter = {
  model: TModel | null | undefined,
  onModelChange: (model: TModel | null) => void,
};

// TODO: ag-grid rename - its common component for filter
const CustomSetColumnFilter: React$StatelessFunctionalComponent<TCustomSetColumnFilter> = (props) => {
  const {
    model,
    onModelChange,
    colDef: { field, type }, // type static_select | select
    context: {
      fetchParams: { companyId },
    },
    options, // if no options, it means async select else b-end send predefined array of option - [{id: 'someID', label: 'General Document'}]
  } = props;
  const [currentOptions, setCurrentOptions] = useState(options || []);
  const [isBusy, setIsBusy] = useState(false);
  const [values, setValues] = useState([]);
  const valuesRef = useRef([]);
  const unappliedModelRef = useRef(model?.values || []);
  const searchValueRef = useRef('');
  const listRef = useRef();
  const visibleStartIndexRef = useRef(null);
  const isAsyncFilter = type === 'select';

  useEffect(() => {
    const previousModel = model?.values.concat() || [];

    valuesRef.current = previousModel;
    // unappliedModelRef - initial model, used for reset to previous state on detach
    // concat for create new array - break reference/link on variable
    unappliedModelRef.current = previousModel.concat();
    setValues(previousModel);
  }, [model]);

  const [closeFilter, setCloseFilter] = useState();
  const inputRef = useRef(null);
  const intl = useIntl();

  const afterGuiAttached = useCallback((params) => {
    if (!params || !params.suppressFocus) {
      // Focus the input element for keyboard navigation.
      // Can't do this in an effect,
      // as the component is not recreated when hidden and then shown again
      inputRef.current?.focus();
    }

    if (params) {
      const { hidePopup } = params;

      setCloseFilter(() => hidePopup);
    }

    if (visibleStartIndexRef.current && listRef.current) {
      listRef.current.scrollToItem(visibleStartIndexRef.current);
    }
  }, []);

  const afterGuiDetached = useCallback(() => {
    setValues(unappliedModelRef.current);
    valuesRef.current = unappliedModelRef.current.concat();
  }, []);

  // register filter handlers with the grid
  useGridFilter({
    afterGuiAttached,
    afterGuiDetached,
  });

  const fetch = useCallback(
    async (search) => {
      try {
        setIsBusy(true);

        const {
          data: { options: list },
        } = await Api.getGridFilterListByField({
          params: { companyId, field, size: 100, search },
        });

        setCurrentOptions(list);
        setIsBusy(false);

        return list;
      } catch (error) {
        console.log('ERROR: fetching column filter data', error);
        setIsBusy(false);
        return [];
      }
    },
    [companyId, field],
  );

  const search = useCallback(() => {
    const list = options.filter(({ label }) => label.toLowerCase().indexOf(searchValueRef.current.toLowerCase()) > -1);

    setCurrentOptions(list);
  }, [options]);

  const onChangeInput = useCallback(
    (event) => {
      const { value } = event.target;
      searchValueRef.current = value;

      if (isAsyncFilter) {
        fetch(value).then();
      } else {
        search();
      }
    },
    [fetch, isAsyncFilter, search],
  );

  const debouncedOnChangeInout = useMemo(() => debounce(onChangeInput, 250), [onChangeInput]);

  const onClickItem = useCallback((option) => {
    const existIndex = valuesRef.current.findIndex((value) => value.id === option.id);

    if (existIndex > -1) {
      valuesRef.current.splice(existIndex, 1);
    } else {
      valuesRef.current.push(option);
    }

    setValues(valuesRef.current);
  }, []);

  const onApply = useCallback(() => {
    // apply model when changes is detected
    if (!isEqual(valuesRef.current, unappliedModelRef.current)) {
      if (valuesRef.current.length > 0) {
        // if anything selected
        onModelChange({
          filterType: 'set',
          values: valuesRef.current,
        });
      } else {
        // if all deselected
        onModelChange(null);
      }
    }
    if (closeFilter) {
      closeFilter();
    }
  }, [onModelChange, closeFilter]);

  const onItemsRendered = useCallback(({ visibleStartIndex }) => {
    visibleStartIndexRef.current = visibleStartIndex;
  }, []);

  useEffect(() => {
    if (isAsyncFilter) {
      fetch().then();
    }
  }, [fetch, isAsyncFilter]);

  return (
    <Box flex="1">
      <form className="ag-filter-wrapper ag-focus-managed">
        <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper">
          <div className="ag-set-filter">
            <div
              role="presentation"
              className="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field"
            >
              <div
                className="ag-input-field-label ag-label ag-hidden ag-text-field-label"
                aria-hidden="true"
                role="presentation"
              />
              <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
                <input
                  ref={inputRef}
                  className="ag-input-field-input ag-text-field-input"
                  type="text"
                  tabIndex="0"
                  placeholder="Search"
                  onChange={debouncedOnChangeInout}
                />
              </div>
            </div>
            {isBusy && <LinearProgress />}

            {searchValueRef.current && currentOptions.length === 0 && <Box textAlign="center">No matches.</Box>}

            <VirtualizedList
              ref={listRef}
              options={currentOptions}
              values={values}
              onClickItem={onClickItem}
              onItemsRendered={onItemsRendered}
            />
          </div>
        </div>
        <div className="ag-filter-apply-panel">
          <button type="submit" className="ag-button ag-standard-button ag-filter-apply-panel-button" onClick={onApply}>
            {intl.formatMessage({ id: 'button.ok' })}
          </button>
          <button
            type="button"
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            onClick={closeFilter}
          >
            {intl.formatMessage({ id: 'button.cancel' })}
          </button>
        </div>
      </form>
    </Box>
  );
};

export default memo(CustomSetColumnFilter);
