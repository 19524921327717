// @flow
import React, { useState, useCallback, useMemo } from 'react';
import find from 'lodash/find';
import { useSelector, useDispatch } from 'react-redux';
import { companiesByIdSelector } from 'domain/companies/companiesSelector';
import { WS_GRID_PRESET_TYPES } from 'domain/documents/constants';

import {
  documentsGridSavePresetAction,
  documentsGridDeletePresetAction,
  gridCurrentPresetSelector,
  gridPresetsSelector,
  documentsGridSetCurrentPresetIdAction,
  documentsGridClearConfigurationChangesAction,
  isFilterChangeSelector,
  isSortChangeSelector,
  isColumnsChangeSelector,
  gridRawPresetsLoadedSelector,
} from 'domain/documents';
import { currentConnectedERPselector } from 'domain/settings';
import { FormattedMessage } from 'react-intl';

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import Menu from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  GridPresetsMenuItem,
  GridPresetsMenuToggle,
} from 'pages/company/CompanyBarPanel/components/GridPresetsMenu/StyledComponents';
import DialogCreateGridPreset, {
  type TGrisPresetSaveParams,
} from 'pages/company/CompanyBarPanel/components/GridPresetsMenu/DialogCreateGridPreset';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LinkIcon from '@mui/icons-material/Link';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const LIST_PADDING_TOP = 8;
const MAX_VISIBLE_ITEM_COUNT = 5;

const ItemWrapper = ({ children, getRef }) => {
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    getRef(ref.current);
  }, []);

  return <>{React.cloneElement(children, { ref })}</>;
};

const createMailTo = (id: string) => {
  const { location } = window;
  const link = `${location.origin}${location.pathname}?sharedPreset=${id}`;

  const subject = '';
  return `mailto:?subject=${subject}&body=${link}`;
};

const mapStateToProps = (state) => ({
  gridPresets: gridPresetsSelector(state),
  erpName: currentConnectedERPselector(state),
  gridCurrentPreset: gridCurrentPresetSelector(state),
  companies: companiesByIdSelector(state),
  isFilterChange: isFilterChangeSelector(state),
  isSortChange: isSortChangeSelector(state),
  isColumnsChange: isColumnsChangeSelector(state),
  isGridPresetLoaded: gridRawPresetsLoadedSelector(state),
});

const GridPresets = () => {
  const dispatch = useDispatch();
  const {
    isFilterChange,
    isSortChange,
    isColumnsChange,
    isGridPresetLoaded,
    companies,
    gridPresets,
    gridCurrentPreset,
    erpName,
  } = useSelector(mapStateToProps);

  const [isNew, setNew] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [heights, setHeights] = useState([]);
  const [stickyHeight, setStickyHeight] = useState('auto');

  const selectedPreset = useMemo(() => gridCurrentPreset, [gridCurrentPreset]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'GridPresetsMenu' });
  const { close: closeMenu, isOpen: isOpenMenu } = popupState;

  const onOpenModalAdd = useCallback(() => {
    setNew(true);
    setOpenModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleMenuItemClick = useCallback(
    (id) => () => {
      const preset = find(gridPresets, (p) => p.id === id) || gridPresets[0];
      dispatch(documentsGridSetCurrentPresetIdAction(preset.id));
      dispatch(documentsGridClearConfigurationChangesAction());
      closeMenu();
    },
    [gridPresets, dispatch, closeMenu],
  );

  const handleAddMenuItemClick = () => {
    closeMenu();
    onOpenModalAdd();
  };

  const deletePreset = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      dispatch(documentsGridDeletePresetAction(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridPresets, dispatch],
  );

  const isPresetChanges = useMemo(
    () => isFilterChange || isSortChange || isColumnsChange,
    [isFilterChange, isSortChange, isColumnsChange],
  );

  const isActiveSave = useMemo(
    () => [WS_GRID_PRESET_TYPES.custom, WS_GRID_PRESET_TYPES.recent].includes(selectedPreset.type) && isPresetChanges,
    [selectedPreset, isPresetChanges],
  );

  const onClearFilterChanges = useCallback(() => {
    dispatch(documentsGridClearConfigurationChangesAction());
  }, [dispatch]);

  const onSave = useCallback(
    (params: TGrisPresetSaveParams) => {
      dispatch(documentsGridSavePresetAction(params));
      setOpenModal(false);
    },
    [dispatch],
  );

  const onSaveCurrentPreset = useCallback(() => {
    const { name, isAll, id } = selectedPreset;
    onSave({
      id,
      isNew: false,
      name,
      isAll,
    });
  }, [selectedPreset, onSave]);

  const getHeightUpdater = (name: string, id: string) => (ref) =>
    setHeights((h) => [...h, { id, height: ref?.offsetHeight || 'auto' }]);

  const maxHeight =
    typeof heights[0]?.height === 'string'
      ? 'auto'
      : heights.slice(0, MAX_VISIBLE_ITEM_COUNT).reduce((a, v) => a + v.height, 0) + stickyHeight + LIST_PADDING_TOP;

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <GridPresetsMenuToggle
          variant="outlined"
          size="small"
          presetType={selectedPreset.type}
          {...bindTrigger(popupState)}
        >
          <Typography variant="body2" noWrap>
            <FormattedMessage id={`document.bar.preset.${selectedPreset.type}`} defaultMessage={selectedPreset.name} />
            {selectedPreset.isAll && <FormattedMessage id="document.bar.preset.all" defaultMessage="(all)" />}
          </Typography>
          {isOpenMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </GridPresetsMenuToggle>
        <Menu
          {...bindMenu(popupState)}
          MenuListProps={{ sx: { pb: 0 } }}
          slotProps={{
            paper: {
              sx: {
                minWidth: '25ch',
                maxHeight,
              },
            },
          }}
        >
          {gridPresets.map((item) => (
            <ItemWrapper
              key={`${item.id}${item.type}`}
              getRef={getHeightUpdater(WS_GRID_PRESET_TYPES.default ? 'defaultItem' : 'item')}
            >
              <GridPresetsMenuItem
                selected={item.id === selectedPreset.id}
                onClick={handleMenuItemClick(item.id)}
                presetType={item.type}
              >
                <Box display="flex" alignItems="center" pr={2}>
                  <Typography variant="body2">
                    <FormattedMessage id={`document.bar.preset.${item.type}`} defaultMessage={item.name} />
                    {item.isAll && <FormattedMessage id="document.bar.preset.all" defaultMessage="(all)" />}
                  </Typography>
                </Box>

                {[WS_GRID_PRESET_TYPES.custom, WS_GRID_PRESET_TYPES.recent].includes(item.type) && (
                  <Stack direction="row" spacing={1} ml="auto">
                    <IconButton
                      size="small"
                      color="primary"
                      component={Link}
                      href={createMailTo(item.id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={deletePreset(item.id)}>
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                )}
              </GridPresetsMenuItem>
            </ItemWrapper>
          ))}
          <ItemWrapper getRef={(ref) => setStickyHeight(ref.offsetHeight || 'auto')}>
            <ListItem sx={{ backgroundColor: 'common.white', position: 'sticky', bottom: 0 }}>
              <Button onClick={handleAddMenuItemClick} color="primary" size="small" fullWidth>
                <FormattedMessage id="button.add" defaultMessage="Add" />
              </Button>
            </ListItem>
          </ItemWrapper>
        </Menu>

        {[WS_GRID_PRESET_TYPES.custom, WS_GRID_PRESET_TYPES.recent].includes(gridCurrentPreset.type) ? (
          isActiveSave && (
            <Button size="small" onClick={onSaveCurrentPreset}>
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          )
        ) : (
          <Button size="small" onClick={onOpenModalAdd}>
            <FormattedMessage id="button.add" defaultMessage="Add" />
          </Button>
        )}

        {isPresetChanges && isGridPresetLoaded && (
          <Button variant="outlined" size="small" onClick={onClearFilterChanges} disabled={!isPresetChanges}>
            <FormattedMessage id="button.reset" defaultMessage="Reset" />
          </Button>
        )}
      </Stack>

      {isOpenModal && (
        <DialogCreateGridPreset
          isNew={isNew}
          preset={selectedPreset}
          onClose={onCloseModal}
          onSave={onSave}
          gridPresets={gridPresets}
          erpName={erpName}
          isMultiCompany={companies.size > 1}
        />
      )}
    </>
  );
};

export default GridPresets;
