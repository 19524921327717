/* @flow */
import React, { Suspense, Component, lazy, Fragment } from 'react';
import { Set, Map, OrderedSet } from 'immutable';
import { getDocumentUrl, getPreviewSrc } from './helpers';
import CompanyContext from 'pages/company/CompanyContext';
import ScrollManager from 'lib/scrollManager';
import { PreviewStateT } from 'pages/company/type.js.flow';
import type { TDocumentRecord, TDocument } from 'domain/documents/types.js.flow';
import type { DocDropParams } from 'pages/company/type.js.flow';
import { type TWorkspaceType } from 'domain/env/types.js.flow';
import { type GridRef } from 'pages/company/grid/types.js.flow';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Box from '@mui/material/Box';
import TileGrid from 'pages/company/TileGrid';
import CompanyBulkPanel from 'pages/company/CompanyBulkPanel';
import LinkedDocumentsPanel from 'pages/company/LinkedDocumentsPanel/LinkedPanel';
import StubWhenEmpty from 'pages/company/stub/StubWhenEmpty';
import elements from 'components/elements';

const Grid = lazy(() => import('pages/company/grid'));

type Props = {
  match: {
    params: {
      companyId: string,
      category1: string,
    },
  },
  dokkaToken: string,
  onShowLinked: () => void,
  setNavigation: (id: string, l?: ?boolean) => void,
  openNotes: (id: string, l?: string) => void,
  onContextMenu: (e: SyntheticMouseEvent<HTMLElement>, d: TDocumentRecord, l?: string) => void,
  isContextMenuOpen?: boolean,
  documents: Map<string, TDocumentRecord>,
  linkedDocs: Map<string, TDocumentRecord>,
  moveDocumentToCategory: (data: DocDropParams) => void,
  selected: OrderedSet<string>,
  workSpaceType: TWorkspaceType,
  onChangeSelected: (s: Set<string>) => void,
  getDocumentLinkedCount: (id: string) => void,
  gridRef: GridRef,
  onPreview: (p: PreviewStateT) => void,
  preview: PreviewStateT,
  onMoveToCompany: (linkId: string) => void,
  onOpenSendByEmailDialog: (linkId: string) => void,
};

type State = {
  isLinkedPanelSelected: ?boolean,
  linked: ?string,
};

interface ActionExac {
  (s: string, l?: boolean): void;
  (s: string, l?: string): void;
}

class WorkSpace extends Component<Props, State> {
  documentItem: ?TDocument;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLinkedPanelSelected: false,
      linked: null,
    };
  }

  onPreview = (preview: PreviewStateT) => {
    const { onPreview } = this.props;
    onPreview(preview);
  };

  get selectedDoc(): Set<string> {
    const { selected } = this.props;
    const { linked } = this.state;
    return linked ? new Set() : selected;
  }

  get isGrid() {
    const { workSpaceType } = this.props;

    return workSpaceType === 'grid';
  }

  // eslint-disable-next-line max-len
  getDocumentUrl = (documentID: string) => {
    const {
      match: {
        params: { companyId },
      },
    } = this.props;
    return getDocumentUrl(companyId, documentID);
  };

  // eslint-disable-next-line max-len
  getPreviewSrc = (documentID: string, dokkaToken: string, preview?: boolean) => {
    const {
      match: {
        params: { companyId },
      },
    } = this.props;
    return getPreviewSrc(companyId, documentID, dokkaToken, preview);
  };

  handleClearSelection = () => {
    const { onChangeSelected } = this.props;
    this.setState({
      linked: undefined,
    });
    onChangeSelected(new Set());
  };

  handleOutsideClick = ({ target }: { target: HTMLElement }) => {
    // dont close bulk panel if its Confirm Dialog or another Dialog
    const targetIsDialog = target.closest('[role="presentation"]');

    if (!targetIsDialog) {
      this.handleClearSelection();
    }
  };

  filterSelectedDocs = (docs, isLinkedPanelSelected) =>
    docs.filter((doc) => {
      const linkid = doc.get('linkid');
      return !((linkid && linkid.endsWith('locked')) || (!isLinkedPanelSelected && linkid));
    });

  toggleSelection = (docs: TDocumentRecord, isLinkedPanelSelected?: string | boolean, isSelectAll?: boolean) => {
    const { selected, documents, onChangeSelected } = this.props;
    const { isLinkedPanelSelected: isLinkedPanelSelectedState } = this.state;
    const docsMap = docs.size ? docs : new Map({ [docs.documentID]: docs });
    const filteredDocs = this.filterSelectedDocs(docsMap, isLinkedPanelSelected);
    const isSameItems = !!isLinkedPanelSelectedState === !!isLinkedPanelSelected;
    const getAllDocumentsList = () => {
      if (this.isGrid && !isLinkedPanelSelected) {
        return documents.keySeq().toArray();
      }
      return filteredDocs.keys();
    };
    const uSelected =
      !isSameItems || isSelectAll
        ? new Set(getAllDocumentsList())
        : filteredDocs.reduce((res, doc) => {
            const docId = doc.documentID;
            return res.has(docId) ? res.delete(docId) : res.add(docId);
          }, selected);

    if (!isSameItems) {
      this.setState({ isLinkedPanelSelected });
    }

    onChangeSelected(uSelected);
  };

  resetIsLinkedPanelSelected = () => {
    this.setState({ isLinkedPanelSelected: false });
  };

  // eslint-disable-next-line max-len
  actionWrapper = (cb: ActionExac) => (e: MouseEvent, doc: TDocumentRecord, isLinkedPanelItem?: boolean | string) => {
    if (e.ctrlKey || e.metaKey) {
      e.stopPropagation();
      e.preventDefault();
      this.toggleSelection(doc, isLinkedPanelItem);
    } else {
      cb(doc.documentID, isLinkedPanelItem);
    }
  };

  handleClick = (...args) => {
    const { setNavigation } = this.props;
    return this.actionWrapper(setNavigation)(...args);
  };

  handleOpenNotes = (...args) => {
    const { openNotes } = this.props;
    return this.actionWrapper(openNotes)(...args);
  };

  handleOpenDocumentView = (documentID: string, isLinked?: ?boolean) => {
    const { setNavigation } = this.props;
    setNavigation(documentID, isLinked);
  };

  handleSelectAll = () => {
    const { documents, linkedDocs } = this.props;
    const [docs, isLinkedPanelItem] = linkedDocs.size ? [linkedDocs, true] : [documents, false];
    this.toggleSelection(docs, isLinkedPanelItem, true);
  };

  render() {
    const {
      documents,
      match,
      moveDocumentToCategory,
      getDocumentLinkedCount,
      dokkaToken,
      isContextMenuOpen,
      onContextMenu,
      onChangeSelected,
      selected,
      gridRef,
      onShowLinked,
      preview,
      onMoveToCompany,
      onOpenSendByEmailDialog,
    } = this.props;
    const { isLinkedPanelSelected, linked } = this.state;

    return (
      dokkaToken && (
        <ClickAwayListener onClickAway={this.handleOutsideClick}>
          {/* Box element need for ClickAwayListener - one child element, height 100% - for ag-grid */}
          <Box display="flex" height="100%" data-element={elements.content.workspace.documentsList}>
            <Box flexGrow={1}>
              {this.isGrid ? (
                <Suspense fallback={<div />}>
                  <Grid
                    getDocumentUrl={this.getDocumentUrl}
                    onPreview={this.onPreview}
                    preview={preview}
                    onContextMenu={onContextMenu}
                    isContextMenuOpen={isContextMenuOpen}
                    onChangeSelected={onChangeSelected}
                    onDocumentOpen={this.handleOpenDocumentView}
                    selected={selected}
                    forwardedRef={gridRef}
                    onShowLinked={onShowLinked}
                    resetIsLinkedPanelSelected={this.resetIsLinkedPanelSelected}
                    isLinkedPanelSelected={isLinkedPanelSelected}
                  />
                </Suspense>
              ) : (
                <>
                  <ScrollManager isTargetLoaded />

                  <TileGrid
                    onClick={this.handleClick}
                    onPreview={this.onPreview}
                    preview={preview}
                    onShowLinked={onShowLinked}
                    onContextMenu={onContextMenu}
                    isContextMenuOpen={isContextMenuOpen}
                    openNotes={this.handleOpenNotes}
                    isLinkedPanelSelected={isLinkedPanelSelected}
                    selectedDocuments={this.selectedDoc}
                    clearSelection={this.handleClearSelection}
                    getDocumentLinkedCount={getDocumentLinkedCount}
                  />
                  {documents.size === 0 && <StubWhenEmpty />}
                </>
              )}
            </Box>

            <LinkedDocumentsPanel
              disabled={preview.documentId}
              getDocumentUrl={this.getDocumentUrl}
              getPreviewSrc={this.getPreviewSrc}
              onContextMenu={onContextMenu}
              isContextMenuOpen={isContextMenuOpen}
              onClick={this.handleClick}
              onPreview={this.onPreview}
              preview={preview}
              openNotes={this.handleOpenNotes}
              selectedDocuments={!isLinkedPanelSelected && this.isGrid ? new OrderedSet() : selected}
              clearSelection={this.handleClearSelection}
              onMoveToCompany={onMoveToCompany}
              onOpenSendByEmailDialog={onOpenSendByEmailDialog}
            />

            <CompanyBulkPanel
              disabled={preview.documentId}
              documentsIds={selected}
              isLinkedPanelSelected={isLinkedPanelSelected}
              linkid={linked}
              selectAll={this.handleSelectAll}
              clearSelection={this.handleClearSelection}
              companyId={match.params.companyId}
              currentRootCategory={match.params.category1}
              moveDocumentToCategory={moveDocumentToCategory}
              gridRef={gridRef}
            />
          </Box>
        </ClickAwayListener>
      )
    );
  }
}

WorkSpace.contextType = CompanyContext;

export default WorkSpace;
