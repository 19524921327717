// @flow
import React, { memo, useCallback } from 'react';
import moment from 'moment';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiInputBase-root': {
      minHeight: 32,
      borderRadius: 5,
      borderColor: 'var(--ag-border-color)',

      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 13,
      padding: 4,
    },
    '& .MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 4,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
  },
}));

interface IProps {
  filterParams: {| format: string |};
  onDateChange: () => void;
}

const AgGridDateFilter = (props: IProps) => {
  const { onDateChange, filterParams, date } = props;
  const classes = useStyles();

  const handleDateChanged = useCallback(
    (selectedDate) => {
      const newDate = selectedDate?.toDate() || selectedDate;
      onDateChange(newDate);
    },
    [onDateChange],
  );

  return (
    <DesktopDatePicker
      className={classes.container}
      placeholder={filterParams.format}
      format={filterParams.format}
      value={date ? moment(date) : null}
      onChange={handleDateChanged}
      slotProps={{
        popper: {
          className: 'ag-custom-component-popup',
        },
        textField: { fullWidth: true },
      }}
    />
  );
};

export default memo(AgGridDateFilter);
