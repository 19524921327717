// @flow
import { Map } from 'immutable';
import type { RecordOf } from 'immutable';
import * as Router from 'domain/router/RouterActions';
import * as Companies from 'domain/companies/companiesActions';
import * as Organization from 'domain/organization/organizationActions';
import * as Documents from 'domain/documents/documentsActions';
import * as Settings from 'domain/settings/settingsActions';
import * as Categories from 'domain/categories/actions';
import * as OrganizationUser from 'domain/organizationUser/actions';
import * as RequestTransaction from 'domain/requestTransaction/actions';
import * as Textract from 'domain/textract/actions';
import * as adapters from './adapters';
import * as A from './envActions';
import type { EnvType } from './types.js.flow';
import { isMobile } from 'lib/systemHelpers/browserHelpers';

type Actions = {
  type: string,
  payload: any,
};

export type EnvStore = RecordOf<EnvType>;

export const reducer = (initial?: EnvType) => ({
  env(state: EnvStore = adapters.envAdapter(initial), action: Actions) {
    switch (action.type) {
      case A.signInAction.success: {
        const { limited, restricted, readonly } = action.payload;
        return state
          .set('newChatFlow', action.payload.newChatFlow)
          .set('dokkaToken', action.payload.dokkaToken)
          .set('role', action.payload.role)
          .set('chatToken', action.payload.chatToken)
          .set('userId', action.payload.userID)
          .set('superUser', action.payload.isSuperUser)
          .set('organizationId', action.payload.organizationId)
          .set('organizationName', action.payload.organizationName)
          .set('organizationBot', adapters.orgBotAdapter(action.payload.organizationBot || {}))
          .set('features', adapters.featuresAdapter(action.payload.features || {}))
          .set('firstName', action.payload.firstName)
          .set('lastName', action.payload.lastName)
          .set('picture', action.payload.picture)
          .set('phone', action.payload.phone)
          .set('chatUser', action.payload.displayName)
          .set('userGUID', action.payload.userGUID)
          .set('permissions', adapters.permissionsAdapter({ ...action.payload.permissions }))
          .set('isDokkaSupport', action.payload.isDokkaSupport)
          .set('helpdeskLink', action.payload.support_portal)
          .set('allowFreshdesk', action.payload.allowFreshdesk)
          .set('TFADokkaToken', null)
          .set('TFALastPhoneNumbers', null)
          .set('TFAEmail', null)
          .set('roleModificators', adapters.roleModificatorsAdapter({ limited, restricted, readonly }))
          .set('cognitoUsername', action.payload.cognitoUsername);
      }
      case A.setTFADataAction.type:
        return state.merge(action.payload);

      case A.removeTFADataAction.type:
        return state.merge({
          TFADokkaToken: null,
          TFALastPhoneNumbers: null,
          TFAEmail: null,
        });

      case A.expiredAction.type:
        return state.set('isExpired', true);

      case A.cognitoCleanAuthDataAction.type:
      case A.silentSignOutAction.success:
      case A.signOutAction.success:
        return adapters.EnvFactory({
          locale: state.locale,
          rtl: state.rtl,
          linkedSidebarWidth: state.linkedSidebarWidth,
          view: state.view,
          role: null,
          roleModificators: adapters.roleModificatorsFactory(),
          connectionHealth: state.connectionHealth,
        });

      case A.saveOnboardingInfoAction.type: {
        const { limited, restricted, readonly } = action.payload;
        return state
          .set('newChatFlow', action.payload.newChatFlow)
          .set('superUser', action.payload.isSuperUser)
          .set('firstName', action.payload.firstName)
          .set('lastName', action.payload.lastName)
          .set('organizationId', action.payload.organizationId)
          .set('organizationName', action.payload.organizationName)
          .set('permissions', adapters.permissionsAdapter({ ...action.payload.permissions }))
          .set('userGUID', action.payload.userGUID)
          .set('helpdeskLink', action.payload.helpdeskLink)
          .set('picture', action.payload.picture)
          .set('phone', action.payload.phone)
          .set('title', action.payload.title)
          .set('organizationBot', adapters.orgBotAdapter(action.payload.organizationBot || {}))
          .set('features', adapters.featuresAdapter(action.payload.features || {}))
          .set('allowFreshdesk', action.payload.allowFreshdesk)
          .set('roleModificators', adapters.roleModificatorsAdapter({ limited, restricted, readonly }));
      }

      case A.signInWithOrganizationAction.type:
        return state.set('dokkaToken', action.payload.dokkaToken);

      case A.setOrganizationIdAction.type:
        return state.set('organizationId', action.payload.organizationId);

      case A.documentChangeLinkedWidthAction.type:
        return state.set('linkedSidebarWidth', action.payload);

      case A.checkPasswordAction.failure:
        return state.set('resetPasswordError', true).set('loading', state.loading > 0 ? state.loading - 1 : 0);

      case A.checkPasswordAction.success:
        return state.set('resetPasswordError', false).set('loading', state.loading > 0 ? state.loading - 1 : 0);

      case A.documentUpdateViewAction.type:
        return state.set('view', new Map(action.payload));

      case A.updateSupplierAliasesAction.success:
        return state.set('supplierAliases', action.payload);

      case A.setSagaDataLoadingAction.type:
        return state.set('isSagaDataLoading', action.payload);

      case Settings.importDataAction.request:
      case Documents.documentGetExportFormats.request:
      case A.signInWithOrganizationAction.request:
      case Documents.documentAccept.request:
      case Documents.documentSearchAction.request:
      case Documents.supplierDocumentSearchAction.request:
      case Companies.fetchCompanies.request:
      case Companies.companySignInAction.request:
      case Documents.scanningRequest.type:
      case Documents.documentGet.request:
      case A.checkPasswordAction.request:
      case Documents.documentStopProcessingAction.request:
      case Companies.getCompanyFeatureSet.request:
      case RequestTransaction.getDocumentMessagingHistory.request:
      case Categories.getCategoriesAction.request:
      case Documents.mergeDocumentsAction.type:
      case Documents.documentSplit.type:
      case Documents.documentGetLinkedIDsAction.type:
      case A.locationChangeAction.type:
      case A.addDataLoadingAction.type:
      case Companies.setSearchFilterAction.request:
      case Textract.ExtractLinesAction.type:
      case Organization.getOrganizationApiKeysAction.request:
      case OrganizationUser.bulkAssignOrganizationUsers.request:
      case OrganizationUser.bulkRevokeOrganizationUsers.request:
      case OrganizationUser.bulkAssignOrganizationCompanies.request:
      case OrganizationUser.bulkRevokeOrganizationCompanies.request:
        return state.set('loading', state.loading + 1);

      case Settings.importDataAction.success:
      case Settings.importDataAction.failure:
      case Documents.documentGetExportFormats.success:
      case Documents.documentGetExportFormats.failure:
      case Documents.documentAccept.success:
      case A.signInWithOrganizationAction.failure:
      case A.removeDataLoadingAction.type:
      case A.signInWithOrganizationAction.success:
      case Documents.documentAccept.failure:
      case Documents.documentUpdate.success:
      case Documents.documentUpdate.failure:
      case Documents.documentSearchAction.success:
      case Documents.documentSearchAction.failure:
      case Documents.supplierDocumentSearchAction.success:
      case Documents.supplierDocumentSearchAction.failure:
      case Documents.supplierDocumentRefreshAction.success:
      case Documents.supplierDocumentRefreshAction.failure:
      case Documents.documentForceSearch.success:
      case Documents.documentForceSearch.failure:
      case Documents.documentWorkerSearch.success:
      case Documents.documentWorkerSearch.failure:
      case Documents.scanningRequest.success:
      case Documents.scanningRequest.failure:
      case Companies.fetchCompanies.success:
      case Companies.fetchCompanies.failure:
      case Companies.companySignInAction.success:
      case Companies.companySignInAction.failure:
      case Documents.nextDocumentAction.failure:
      case Documents.documentStopProcessingAction.success:
      case Documents.documentStopProcessingAction.failure:
      case Companies.getCompanyFeatureSet.success:
      case Companies.getCompanyFeatureSet.failure:
      case RequestTransaction.getDocumentMessagingHistory.success:
      case RequestTransaction.getDocumentMessagingHistory.failure:
      case Categories.getCategoriesAction.success:
      case Categories.getCategoriesAction.failure:
      case Documents.mergeDocumentsAction.success:
      case Documents.mergeDocumentsAction.failure:
      case Documents.documentGetLinkedIDsAction.success:
      case Documents.documentGetLinkedIDsAction.failure:
      case Companies.setSearchFilterAction.success:
      case Companies.setSearchFilterAction.failure:
      case Textract.ExtractLinesAction.success:
      case Textract.ExtractLinesAction.failure:
      case Documents.mergeDocumentsForPrintingAction.failure:
      case Organization.getOrganizationApiKeysAction.success:
      case Organization.getOrganizationApiKeysAction.failure:
      case OrganizationUser.bulkAssignOrganizationUsers.success:
      case OrganizationUser.bulkAssignOrganizationUsers.failure:
      case OrganizationUser.bulkRevokeOrganizationUsers.success:
      case OrganizationUser.bulkRevokeOrganizationUsers.failure:
      case OrganizationUser.bulkAssignOrganizationCompanies.success:
      case OrganizationUser.bulkAssignOrganizationCompanies.failure:
      case OrganizationUser.bulkRevokeOrganizationCompanies.success:
      case OrganizationUser.bulkRevokeOrganizationCompanies.failure:
        return state.set('loading', state.loading > 0 ? state.loading - 1 : 0);

      case Documents.documentGet.success:
      case Documents.documentGet.failure:
        return isMobile ? state.set('loading', state.loading > 0 ? state.loading - 1 : 0) : state;

      case Router.completeAction.type:
      case Router.completeResetAction.type:
      case Router.noLocationChange.type:
        return state.set('loading', 0);

      case A.locationChangeAction.type:
        return state.set('locale', action.payload).set('rtl', action.payload === 'he');

      case A.setNavigationAction.type:
        return state.set('navigation', Map(action.payload));

      case A.updateNavigationAction.type:
        return state.update('navigation', (n) => n.merge(action.payload));

      case A.clearNavigationAction.type:
        return state.set('navigation', new Map());

      case A.saveBackSearchUrlAction.type:
        return state.set('backSearchUrl', action.payload);

      case A.savePersistedBackUrlAction.type:
        return state.set('persistedBackUrl', action.payload);

      case A.setGoogleSearchTextAction.type:
        return state.set('googleSearchText', action.payload).set('googlePanelStatus', true);

      case A.setGooglePanelStatusAction.type:
        return state.set('googlePanelStatus', action.payload);

      case A.addAppStatusAction.type:
        return state.update('appStatuses', (appStatuses) =>
          appStatuses.filter((s) => s.id !== action.payload.id).push(action.payload),
        );

      case A.removeAppStatusAction.type:
        return state.update('appStatuses', (appStatuses) => appStatuses.filter((s) => s.id !== action.payload));

      case OrganizationUser.updateOrganizationUserPicture.success:
        return state.set('picture', action.payload);

      case OrganizationUser.updateOrganizationUserProfile.success:
        return state
          .set('firstName', action.payload.first_name)
          .set('lastName', action.payload.last_name)
          .set('phone', action.payload.phone)
          .set('title', action.payload.title)
          .set('chatUser', [action.payload.first_name, action.payload.last_name].join(' '));

      case A.updateWorkSpaceAction.type:
        return state.setIn(['workSpace', action.payload.companyId], action.payload.type);

      case A.updateUserFeaturesAction.success:
        return state.set('features', action.payload);

      case A.setErrorMessage.type: {
        return state.set('error', action.payload);
      }

      case A.connectionHealthAction.type:
        return state.set('connectionHealth', action.payload);

      default:
        return state;
    }
  },
});
