/* @flow */
import { fromJS, isList, List, Map, OrderedMap, Record, type RecordFactory, type RecordOf } from 'immutable';
import type { UnreadRequestDocType, UnreadRequestLineType, WsGridPresetsT, WsGridPresetT } from 'domain/contracts';
import type {
  GridHeaderItemType,
  GridItemType,
  GridRawHeaderItemType,
  GridRawItemType,
  TDocumentHotkey,
  THotkeyList,
  THotkeyMatch,
  THotkeyRaw,
  TParamsRaw,
  TQueueItemTypeRaw,
  WsGridPreset,
} from 'domain/documents/types.js.flow';
import { HotkeyFactory, ParamsFactory, UploadQueueItemFactory } from 'domain/documents/factories';
import moment from 'moment';
import { documentCompositionFunctionsAdapter } from 'domain/documents/helpers';
import { GRID_SERVICE_KEYS } from 'pages/company/grid/helpers';

import { WS_GRID_PRESET_TYPES, WS_GRID_RECENT_DEFAULT_NAME } from './constants';

export const paramsAdapter = (data?: TParamsRaw) => {
  const { creationDate: creation, ...rest } = data || {};

  const creationDate =
    creation && !moment(creation).isSame(moment(), 'day') ? creation.format('YYYY-MM-DD[T]HH:mm:ss') : null;
  return ParamsFactory({ creationDate, ...rest });
};

export const uploadQueueItemAdapter = ({ params, ...rest }: TQueueItemTypeRaw) =>
  UploadQueueItemFactory({
    params: paramsAdapter(params),
    ...rest,
  });

export const UnredRequestDocFactory: RecordFactory<UnreadRequestDocType> = new Record({
  documentId: '',
  companyId: '',
  lines: new List(),
});

export const UnredRequestLineFactory: RecordFactory<UnreadRequestLineType> = new Record({
  id: '',
  row: 0,
  lastTimestamp: '',
});

export const unredRequestLinesAdapter = (lines): List<RecordOf<UnreadRequestLineType>> =>
  new List(lines.map(({ row, ...rest }) => UnredRequestLineFactory({ row: parseInt(row, 10), ...rest })));

export const unredRequestDocsAdapter = (docs: Array<UnreadRequestDocType>): List<RecordOf<UnreadRequestDocType>> =>
  new List(
    docs.map(({ lines, ...rest }) =>
      UnredRequestDocFactory({
        lines: unredRequestLinesAdapter(lines),
        ...rest,
      }),
    ),
  );

const GridHeaderItemFactory: RecordFactory<GridHeaderItemType> = new Record({
  field: '',
  headerName: '',
  type: 'string',
  hide: false,
  pinned: false,
  minWidth: 50,
  operator: undefined,
  filterParams: undefined,
  filter: undefined,
  sortable: true,
});

const fieldsByTypes = {
  extraSelect: '.status',
};

const getField = (item: GridRawHeaderItemType) => {
  const endOfField = fieldsByTypes[item.type];
  return `${item.field}${endOfField || ''}`;
};

const GridHeaderItemAdapter = (item: GridRawHeaderItemType): RecordOf<GridHeaderItemType> =>
  GridHeaderItemFactory({
    ...item,
    field: getField(item),
    minWidth: +item.minWidth || undefined,
  });

const createGridMetaItem = (item: GridRawItemType): GridItemType => {
  const { document, ...rest } = item;

  return new Map({
    documentID: document.document_id,
    [GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME]: '',
    [GRID_SERVICE_KEYS.PREVIEW_CHECKBOX_COLUMN_NAME]: '',
    [GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU]: '',
    ...rest,
  });
};
// data stored in state
export const gridDocumentsListAdapter = (rawList: GridRawItemType[]) =>
  rawList.reduce((a, v) => {
    // eslint-disable-next-line camelcase
    const { document_id, notes, ...rest } = v.document;

    return a.set(
      document_id,
      documentCompositionFunctionsAdapter({
        ...rest,
        notes: notes || '',
        // eslint-disable-next-line camelcase
        documentID: document_id,
        gridMeta: createGridMetaItem(v),
      }),
    );
  }, new OrderedMap());

export const gridHeadersListAdapter = (rawList: GridRawHeaderItemType[]) =>
  List(rawList.map((item) => GridHeaderItemAdapter(item)));

// data that relevant for ag-grid
export const documentsGridRowNodesAdapter = (adaptedList) => {
  // TODO: ag-grid check this condition (maybe it will be fully immutable .toJS())
  if (isList(adaptedList)) {
    return adaptedList.map((documentID) => ({ documentID })).toArray();
  }

  return adaptedList.reduce(
    (a, v) => [
      ...a,
      {
        rowDocumentData: v,
        ...v.gridMeta.toJS(),
      },
    ],
    [],
  );
};

// eslint-disable-next-line max-len
const documentHotkeyAdapter = (item: THotkeyRaw): RecordOf<TDocumentHotkey> =>
  HotkeyFactory({
    keys: fromJS(item.keys),
    description: item.description,
    level: item.level,
  });

export const documentHotkeysAdapter = (rawList: THotkeyList) =>
  List(rawList.map((item) => documentHotkeyAdapter(item)));

export function hotkeyMatchAdapter(hotkeyMatch: THotkeyMatch) {
  return hotkeyMatch ? { hotkey: hotkeyMatch } : {};
}

export const wsGridPresetAdapter = ({ all, type = null, ...rest }: WsGridPresetT) => ({
  ...rest,
  isAll: !!all,
  type: type || (rest.name === WS_GRID_RECENT_DEFAULT_NAME ? WS_GRID_PRESET_TYPES.recent : WS_GRID_PRESET_TYPES.custom),
});

export const wsGridPresetsAdapter = (presets: WsGridPresetsT): WsGridPreset[] =>
  presets.map((p) => wsGridPresetAdapter(p));

export const wsGridSharePresetAdapter = (preset: WsGridPresetT): WsGridPreset => ({
  ...preset,
  isAll: false,
  name: 'Shared',
  type: WS_GRID_PRESET_TYPES.shared,
});
