import { put, select, call } from 'redux-saga/effects';
import * as selector from './documentSelector';
import { navigationSelector, updateNavigationAction } from 'domain/env';
import Api, { doLogout } from 'domain/api';
import { queryToSearch } from 'domain/router/helpers';
import * as actions from 'domain/documents/documentsActions';
import { documentsListAdapter, documentsWithoutLinkedAndAccepted, sorterByCreated } from 'domain/documents/helpers';
import { getNestedCategory } from 'domain/categories/helpers';
import * as JEactions from 'domain/journal/actions';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';

import { navigate } from 'domain/router/redux/reduxActions';
// eslint-disable-next-line import/no-cycle
import { ensureSearchDocuments, ensureSupplierSearchDocuments } from 'domain/documents/sagas';
import { isGridWorkSpaceSelector } from 'domain/companies/companiesSelector';
import * as ACL from 'domain/restriction';

// ensure it doesnt depend on user role
const TO_APPROVE_CATEGORY_ID = '46';

function* getNavigation(direction = 1) {
  const navigation = yield select(navigationSelector);
  const currentDocumentOrderNumber = navigation.get('currentDocumentOrderNumber');
  const docs = [];
  let currentIdx;
  let totalNumberOfDocuments;
  let isLinked = false;
  let showAllDocsForApproval = false;
  const params = navigation.getIn(['search', 'params']) || {};
  const doc = yield select(selector.documentSelector);
  const isGrid = yield select(isGridWorkSpaceSelector);
  const savedDocumentListWithFilters = yield select(selector.savedDocumentListWithFiltersSelector);
  const isGranted = yield select(ACL.isGranted);
  // if we come from the history event we can't navigate between docs
  // because we may have a list of documents from another category
  if (navigation.get('fromHistory')) {
    docs.push(doc);
    currentIdx = 0;
    totalNumberOfDocuments = docs.length;
  }
  // if we come from the link from the email to approve the documents
  else if (navigation.get('showAllDocsForApproval')) {
    const list = yield select(selector.documentsByIdSelector);
    list.map((d) => docs.push(d));
    showAllDocsForApproval = true;
    currentIdx = docs.findIndex((d) => d.documentID === doc.documentID);
    totalNumberOfDocuments = docs.length;
  }
  // user is coming from toApprove category, we want flat doc list including flat linked docs
  // in case user opens doc from linked panel, we fallback to linked folder navigation
  else if (getNestedCategory(params) === TO_APPROVE_CATEGORY_ID && !navigation.get('fromLinked')) {
    const list = yield select(selector.documentsByIdSelector);
    list.forEach((d) => docs.push(d));
    currentIdx = docs.findIndex((d) => d.documentID === doc.documentID);
    // this holds query params when we havigate to document
    const query = navigation.getIn(['search', 'query']);

    if (docs[docs.length - 1].documentID === doc.documentID) {
      yield ensureSearchDocuments(
        {
          params: { ...params, category: TO_APPROVE_CATEGORY_ID, query },
          force: true,
          infiniteScroll: true,
        },
        list.size * 2,
        false,
      );
      const updatedList = yield select(selector.documentsByIdSelector);
      totalNumberOfDocuments = updatedList.size;
    } else {
      totalNumberOfDocuments = list.size;
    }
  } else if (isGrid && savedDocumentListWithFilters.size > 0 && !navigation.get('fromLinked')) {
    savedDocumentListWithFilters.map((d) => docs.push({ documentID: d }));
    currentIdx = docs.findIndex((d) => d.documentID === doc.documentID);
    totalNumberOfDocuments = docs.length;
  } else if (doc.linkid) {
    const { data } = yield call(Api.documentSearch, {
      params: {
        maxResults: 300,
        searchQuery: `(linkid: ${doc.linkid})`,
      },
      paramsSerializer: (d) => queryToSearch(d),
    });
    const isPanelOpen = yield select(selector.documentLinkedOpenStatusSelector);
    yield put({
      type: actions.documentGetLinkedAction.success,
      payload: {
        list: documentsListAdapter(data.items),
        tag: doc.linkid,
        count: data.count,
        pageToken: data.pageToken,
        isOpen: isPanelOpen,
      },
    });

    const list = yield select(selector.documentLinkedSelector);
    list.map((d) => docs.push(d));

    // direction = 0
    // if we move linked document to another category, we go to next document from linked folder
    currentIdx =
      direction === 0
        ? currentDocumentOrderNumber - 1
        : docs.sort(sorterByCreated).findIndex((d) => d.documentID === doc.documentID);

    totalNumberOfDocuments = data.count;
    isLinked = true;
  } else if (isGranted(ACL.IS_SUPPLIER_USER)) {
    const list = yield select(selector.documentsByIdSelector);
    const { stateColumn } = list.filter((d) => d.documentID === doc.documentID).get(doc.documentID);

    // eslint-disable-next-line array-callback-return
    documentsWithoutLinkedAndAccepted(list).map((d) => {
      if (d.stateColumn === stateColumn) docs.push(d);
    });
    currentIdx = docs.sort(sorterByCreated).findIndex((d) => d.documentID === doc.documentID);
    // this holds query params when we havigate to document
    const query = navigation.getIn(['search', 'query']);
    if (docs[docs.length - 1].documentID === doc.documentID) {
      yield ensureSupplierSearchDocuments(
        {
          params: { ...params, query, stateColumn },
          force: true,
          infiniteScroll: true,
        },
        list.size * 2,
        false,
      );
    }
    const supplierStateDocCountsWithoutLinkPanels = yield select(
      selector.supplierStateDocCountsWithoutLinkPanelsSelector,
    );

    totalNumberOfDocuments = supplierStateDocCountsWithoutLinkPanels[stateColumn];
  } else {
    const list = yield select(selector.documentsByIdSelector);
    documentsWithoutLinkedAndAccepted(list).map((d) => docs.push(d));

    // direction = 0
    // if we move a document to another category, we go to the next document from the current (old for this document) category
    currentIdx =
      direction === 0 ? currentDocumentOrderNumber - 1 : docs.findIndex((d) => d.documentID === doc.documentID);

    // this holds query params when we navigate to document
    const query = navigation.getIn(['search', 'query']);
    if (docs.length && docs[docs.length - 1].documentID === doc.documentID) {
      const category = getNestedCategory(params);
      // params still have extra cattegory1 & category2 that are not used.
      yield ensureSearchDocuments(
        {
          params: { ...params, category, query },
          force: true,
          infiniteScroll: true,
        },
        list.size * 2,
        false,
      );
    }
    totalNumberOfDocuments = yield select(selector.totalNumberOfDocumentsSelector);
  }
  return {
    next: docs[currentIdx + direction] || docs[0],
    prev: docs[currentIdx - 1] || docs[0],
    fd: docs[0],
    params,
    listLength: docs.length,
    currentDocumentOrderNumber: currentIdx + 1,
    totalNumberOfDocuments,
    showAllDocsForApproval,
    isLinked,
  };
}

export function* ensureNextDocument({ payload }) {
  try {
    const { next, params } = yield getNavigation(payload);
    const currentCompanyId = yield select(selector.currentCompanySelector);

    yield put({ type: JEactions.resetMapTextAction.type });
    const url = generatePath(ROUTES_PATH.DOCUMENT.absolute, {
      companyId: params.companyId || currentCompanyId,
      documentId: next.documentID,
    });
    yield put(navigate.push(url));
  } catch (err) {
    yield doLogout(actions.nextDocumentAction, err);
  }
}

export function* ensureSetFdDocument() {
  try {
    const {
      fd,
      listLength,
      currentDocumentOrderNumber,
      totalNumberOfDocuments,
      next,
      prev,
      showAllDocsForApproval,
      isLinked,
    } = yield getNavigation();

    if (fd) {
      yield put({
        type: updateNavigationAction.type,
        payload: {
          fd: fd.documentID,
          listLength,
          currentDocumentOrderNumber,
          totalNumberOfDocuments,
          isSetRequest: false,
          nextDocId: next ? next.documentID : null,
          prevDocId: prev ? prev.documentID : null,
          showAllDocsForApproval,
          isLinked,
        },
      });
    }
  } catch (err) {
    yield doLogout(actions.nextDocumentAction, err);
  }
}
