import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useContext, useMemo } from 'react';
import CompanyContext from 'pages/company/CompanyContext';
import { getNestedCategory } from 'domain/categories/helpers';

import { chatAllUsersByIdSelector } from 'domain/chat/chatSelector';

const mapStateToProps = (state) => ({
  usersById: chatAllUsersByIdSelector(state),
});

const useGridContext = () => {
  const params = useParams();
  const { companyId } = params;
  const category = getNestedCategory(params);
  const query = useQuery();
  const { companyType } = useContext(CompanyContext);
  const isConfidential = companyType === 'confidential';
  const { usersById } = useSelector(mapStateToProps);

  // TODO: rm usersById from context for SSRM - used in custom components for tags approvals etc
  return useMemo(
    () => ({
      usersById,
      fetchParams: {
        isConfidential,
        companyId,
        category,
        ...query,
      },
    }),
    [isConfidential, query, companyId, category, usersById],
  );
};

export default useGridContext;
