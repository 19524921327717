/* @flow */
import { requestCreator, RequestCreator, makeUrl, CONFIGURABLE_API_ENDPOINT } from 'lib/apiHelpers';
import { call, put } from 'redux-saga/effects';
import { signInAction, signOutAction, silentSignOutAction, expiredAction } from 'domain/env';
import { notify } from 'lib/errorLogger';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { isMatchRoute } from 'domain/router/utils';
import * as c from './contracts';
import tokenKeeper from 'lib/apiTokenKeeper';

const SAGA_SYNC_ACTION_FAILURE = 'SAGA/SYNC_ACTION/FAILURE';

export function logoutAction(action, err) {
  // we shouldn't logout(redirect to login) user with expired token
  // on specific routes, eg setpasswd/resetpasswd
  const isSilentLogout = [
    ROUTES_PATH.AUTH_RESET_PASSWORD.absolute,
    ROUTES_PATH.AUTH_SET_PASSWORD.absolute,
    ROUTES_PATH.AUTH_FORGOT_PASSWORD.absolute,
  ].some((path) => isMatchRoute(path));

  if (isSilentLogout) {
    // make app think we are not authorized (we have dead token actually)
    // to allow not authorized routes
    return {
      type: silentSignOutAction.success,
    };
  }
  // @to-do catch network error by its type until it reaches
  // this line and be sure err.response is present
  // eslint-disable-next-line max-len
  if (
    action.type !== signInAction.type &&
    err.response &&
    err.response.status &&
    err.response.status.toString() === process.env.REACT_APP_UNAUTHORIZED_CODE
  ) {
    return {
      type: signOutAction.type,
    };
  }

  if (action.type === signInAction.type && err.response && !err.response.status) {
    const fakeError = { response: { data: 'Something went wrong. Please, refresh the page or contact support' } };

    return {
      type: action.failure,
      err: fakeError,
    };
  }

  // eslint-disable-next-line max-len
  if (
    err.response &&
    err.response.status &&
    err.response.status.toString() === process.env.REACT_APP_TRIAL_EXPIRED_CODE
  ) {
    return {
      type: expiredAction.type,
    };
  }

  return {
    type: action.failure || SAGA_SYNC_ACTION_FAILURE,
    err,
  };
}

export function* doLogout(action, err) {
  yield put(logoutAction(action, err));
}

export function ensure(api, action, { adapter = (d) => d, onSuccess, onFailure } = {}) {
  return function* requestApi(args = {}) {
    yield put({ type: action.request });
    try {
      const resp = yield call(api, args);
      yield put({
        ...args,
        type: action.success,
        payload: adapter(resp),
      });
      if (typeof onSuccess === 'function') onSuccess(resp);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error in ensure', err);
      yield doLogout(action, err);
      if (typeof onFailure === 'function') onFailure(err);
    }
  };
}

const API_ROOT_FULL = `${CONFIGURABLE_API_ENDPOINT || ''}/v2/3/` || '';

const publickRequest = requestCreator(API_ROOT_FULL);
const request = requestCreator(API_ROOT_FULL, tokenKeeper);
const uploadRequest = requestCreator(API_ROOT_FULL, tokenKeeper);

function errorLoger(error: TypeError, meta: Object) {
  notify.captureEvent(error, { data: meta }); // TODO: need create tage for separate error type
}

const rcInstance = new RequestCreator(API_ROOT_FULL, errorLoger, tokenKeeper);

export default {
  getTodoList: request.get('getTodoList'),
  createToDo: request.post('addTodoTask'),
  deleteToDo: request.post('deleteTodoTask'),
  updateToDo: request.post('updateTodoTask'),
  saveSearchCriteria: request.post('saveSearchCriteria'),
  deleteSearchCriteria: request.post('deleteSearchCriteria'),
  getDevices: rcInstance.get(makeUrl`getSigners/${'id'}`, c.validateDeviceList),
  getHashDevices: rcInstance.get('getHashDevices', (v) => v),
  connectHashDevice: rcInstance.post('hash/authorization', (v) => v),
  saveHashCompanyId: request.post('hash/save_company_id'),
  signIn: publickRequest.post('loginUser', c.validateUserCredentials),
  checkTwoFACode: publickRequest.post('checkTwoFACode', (x) => x),
  resendTwoFACode: publickRequest.post('resendTwoFACode', (x) => x),
  passwordLessAuthAccessLink: publickRequest.post('supplier_view/supplierAccessLink', (x) => x),
  signInSage: request.post('sage_sa/authorization'),
  companyLogin: request.get('companyLogin', (x) => x),
  saveSageCompany: request.post('sage_sa/save_company_id'),
  signInPriority: request.post('priority_api/authorization'),
  signInSAP: request.post('sap_api/authorization'),
  signInNetSuite: request.post('netsuite_api/authorization'),
  signInMDNavision: request.post('microsoft_dynamics_nav/authorization'),
  saveMDNavisionCompany: request.post('microsoft_dynamics_nav/save_company_id'),
  signInMDBC: request.post('microsoft_dynamics_bc/authorization'),
  saveMDBCCompany: request.post('microsoft_dynamics_bc/save_company_id'),
  signInAcumatica: request.post('acumatica_api/authorization'),
  // signInNetSuit: rcInstance.mock({}, x => x),
  savePriorityCompany: request.post('priority_api/save_company_id'),
  getZohoCompanyList: rcInstance.get('zoho/organization', (v) => v),
  setZohoCompany: rcInstance.post('zoho/organization', (v) => v),
  getCompanies: rcInstance.get('getListOfCompanies', c.validateCompanyList),
  markCompanyPinned: request.get('getListOfCompanies'), // @todo fix url
  getListRecent: request.get('listRecent'),
  getDocumentsExportFormats: request.get('exportFormats'),
  documentSearch: rcInstance.get('search', c.validateSearch),
  documentSearchByCategory: rcInstance.get('search_new', c.validateSearch),
  documentSearchBySupplier: rcInstance.get('supplier_view/search', c.validateSearch),
  searchInCompanies: rcInstance.get('search_in_companies', (x) => x),
  getDocument: rcInstance.get('getDocumentMetadata', c.validateDocumentMetadata),
  getOptionsList: rcInstance.get('getOptionsList', (x) => x),
  getTagSuggestion: rcInstance.get('searchGetHints', c.validateSearchGetHints),
  removeDocument: request.post('removeDocument'),
  updateDocumentTags: rcInstance.post('updateTags', c.validateUpdateTags),
  updateMultipleDocumentsTags: rcInstance.post('updateTagsDocuments/', (x) => x),
  changeTypeDocuments: rcInstance.post('changeTypeDocuments', c.validateDocumentsBulkUpdateResponse),
  changeTypeDocument: rcInstance.post('changeTypeDocument', c.validateDocumentMetadata),
  linkDocument: request.post('linkDocument'),
  linkDocuments: request.post('linkDocuments'),
  lockLinkedDocuments: request.post('updateLinkState'),
  unlinkDocument: request.post('unlinkDocument'),
  unlinkMultiDocuments: request.post('unlinkMultiDocuments'),
  updateLink: request.post('updateLink'),
  updateNotes: rcInstance.post('updateNotes', c.validateUpdateNotes),
  removeNotes: rcInstance.post('removeNotes', c.validateRemoveNotes),
  uploadDocument: uploadRequest.post('uploadDocument'),
  uploadSupplierDocument: uploadRequest.post('supplier_view/uploadDocuments'),
  rejectDocument: request.post('rejectDocument'),
  getDashboardStatus: rcInstance.get('getDashboardStats', c.validateDashboardState),
  getStatementTxnSearch: request.post('statementTxnSearch', c.validateGoogleSearch),
  // TODO uncomment after fix performance
  // getJournalEntry: rcInstance.get('getJournalEntry', c.validateJournalEntry),
  getJournalEntry: rcInstance.get('getJournalEntry', (x) => x),
  getJEList: rcInstance.get('getJournalEntryList', (x) => x),
  updateJournalEntry: rcInstance.post('updateJournalEntry', (x) => x),
  updateJournalEntryFromInsights: rcInstance.post('updateJournalEntryFromInsights', (x) => x),
  getSearchFavorites: rcInstance.get('getNewSearchFavorites', c.validateSearchFavorites),
  getTextMap: request.get('getTextMap'),
  getSettings: rcInstance.get('getCompanyPreferences', c.validateCompanyPreferences),
  getSlackConnection: rcInstance.get('slack/connection/status', (x) => x),
  disconnectSlack: rcInstance.post('slack/connection/revoke', (x) => x),
  connectSlack: rcInstance.get('slack/install', (x) => x),
  getSSOParams: request.get('getSSOParams'),
  disconnectErp: request.get('disconnectErp'),
  updateFavoriteCompanies: request.post('updateFavoriteCompanies'),
  acceptDocument: rcInstance.get('acceptDocument', c.validateAcceptDocument),
  resetPassword: publickRequest.get('resetPassword'),
  checkPassword: publickRequest.post('resetPassword'),
  checkActivate: publickRequest.post('activateUser'),
  updatePassword: publickRequest.post('updatePassword'),
  updatePrefs: request.post('updatePrefs'),
  splitDocument: request.post('splitDocument'),
  unlinkDocuments: request.post('unlinkDocuments'),
  healthCheck: publickRequest.get('healthCheck'),
  getCompanyChatContext: rcInstance.get('getChatPeers', c.validateChatPeers),
  getUserProfile: rcInstance.get('getUserProfile', c.validateUserProfile),
  getMRUTags: request.get('getMRUTags'),
  signDocument: request.post('signDocument'),
  signDocuments: rcInstance.post('signDocuments', c.validateDocumentsBulkUpdateResponse),
  bulkAcceptDocuments: rcInstance.post('bulkAcceptDocument', (x) => x),
  bulkApprove: rcInstance.post('approvals/bulkSignDocuments', (x) => x),
  bulkSetApprovals: rcInstance.post('bulkSetApprovalFlow', (x) => x),
  moveDocumentToCompany: rcInstance.post('moveDocumentToCompany', (x) => x),
  moveDocumentsToCompany: rcInstance.post('moveDocumentsToCompany', (x) => x),
  mergeDocuments: rcInstance.post('mergeDocuments', c.validateDocumentsBulkUpdateResponse),
  mergeDocumentsForPrinting: rcInstance.post('getMergedDocuments', (x) => x),
  getOrganization: rcInstance.get(makeUrl`organizations/${'id'}`, c.validateOrganizations),
  updateOrganization: rcInstance.post(makeUrl`organizations/${'id'}`, c.validateUpdateOrganization),
  updateOrganizationLogo: rcInstance.post(makeUrl`organizations/${'id'}/uploadLogo`, c.validateEmptyContracts),
  createOrganization: rcInstance.post('organizations', c.validateCreateOrganization),
  getPasswordExpiration: rcInstance.get(makeUrl`preferences/org/${'id'}/password_expiration`, (x) => x),
  updatePasswordExpiration: rcInstance.post(makeUrl`preferences/org/${'id'}/password_expiration`, (x) => x),
  createCompany: rcInstance.post('company', c.validateCreateCompany),
  getAllCompanies: rcInstance.get('company', (x) => x),
  exportData: rcInstance.url('newExportData'),
  getSignings: rcInstance.get(makeUrl`preferences/${'type'}/${'id'}/signing`, c.validateGetSignings),
  updateSignings: rcInstance.put(makeUrl`preferences/${'type'}/${'id'}/signing`, c.validateUpdateSignings),
  removeSignings: rcInstance.delete(makeUrl`preferences/${'type'}/${'id'}/signing`, c.validateRemoveSignings),
  setCompanyFeatures: rcInstance.post(makeUrl`preferences/company/${'id'}/feature_set`, c.validateSetCompanyFeatures),
  getCompanyFeatures: rcInstance.get(makeUrl`preferences/company/${'id'}/feature_set`, c.validateSetCompanyFeatures),
  getFTPConnection: rcInstance.get(makeUrl`preferences/company/${'id'}/ftp_connection`, (x) => x),
  saveFTPConnection: rcInstance.post(makeUrl`preferences/company/${'id'}/ftp_connection`, (x) => x),
  getCompanyConnections: rcInstance.get(
    makeUrl`preferences/company/${'id'}/connections`,
    c.validateGetCompanyConnections,
  ),
  syncErpIndexes: rcInstance.post(makeUrl`company/${'id'}/sync_indexes`, (x) => x),
  setGuidSignings: rcInstance.post(makeUrl`preferences/${'type'}/${'id'}/signing/set_guid`, c.validateSetSigningsGuid),
  getSigningsCopy: rcInstance.get(makeUrl`preferences/company/${'id'}/signing/copy`, c.validateGetSignings),
  deleteAllSignings: rcInstance.delete(makeUrl`preferences/${'type'}/${'id'}/signing/all`, c.validateRemoveSignings),
  getOrgFeatures: rcInstance.get(makeUrl`preferences/org/${'id'}/feature_set`, c.validateOrganizationsFeatures),
  setOrgFeatures: rcInstance.post(makeUrl`preferences/org/${'id'}/feature_set`, c.validateOrganizationsFeatures),
  getOrgBackupConnections: rcInstance.get(
    makeUrl`preferences/org/${'id'}/cloud_backup_connections`,
    c.validateOrgBackupConnections,
  ),
  getBackupSSOParams: rcInstance.get(makeUrl`cloudBackup/getSSOParams`, (x) => x),
  disconnectOrgBackup: rcInstance.get(makeUrl`cloudBackup/disconnect`, (x) => x),
  getCompanyUsers: rcInstance.get(makeUrl`company/${'id'}/users`, c.validateUsers),
  createCompanyUser: rcInstance.post(makeUrl`company/${'id'}/users`, c.validateUser),
  getOrgUsers: rcInstance.get(makeUrl`org/${'id'}/users`, c.validateUsers),
  downloadUsersReport: rcInstance.url('organizations/usersReport'),
  createOrgUser: rcInstance.post(makeUrl`org/${'id'}/users`, c.validateUser),
  updateOrgUser: rcInstance.post(makeUrl`users/${'id'}`, c.validateUser),
  uploadUserPicture: rcInstance.post(makeUrl`users/${'id'}/uploadPicture`, (x) => x),
  getCompany: rcInstance.get(makeUrl`company/${'id'}`, c.validateCompany),
  updateCompanyLogo: rcInstance.post(makeUrl`company/${'id'}/uploadLogo`, c.validateUpdateCompanyLogo),
  updateCompany: rcInstance.post(makeUrl`company/${'id'}`, c.validateUpdateCompany),
  assignOrgUser: rcInstance.post(makeUrl`users/${'id'}/company_assign`, c.validateAssignOrgUser),
  revokeOrgUser: rcInstance.delete(makeUrl`users/${'id'}/company_assign`, c.validateRevokeOrgUser),
  bulkAssignOrgUsers: rcInstance.post(makeUrl`company/${'companyId'}/bulk_user_assign`, (x) => x),
  bulkRevokeOrgUsers: rcInstance.delete(makeUrl`company/${'companyId'}/bulk_user_assign`, (x) => x),
  bulkAssignOrgCompanies: rcInstance.post(makeUrl`users/${'userId'}/bulk_company_assign`, (x) => x),
  bulkRevokeOrgCompanies: rcInstance.delete(makeUrl`users/${'userId'}/bulk_company_assign`, (x) => x),
  revokeUserFromAllCompanies: rcInstance.delete(
    makeUrl`users/${'id'}/company_assign/revoke_all`,
    c.validateRevokeOrgUser,
  ),
  deleteCompany: rcInstance.delete(makeUrl`company/${'id'}`, c.validateDeleteCompany),
  deleteOrgUser: rcInstance.delete(makeUrl`users/${'id'}`, c.validateDeleteUser),
  getCompaniesByErpType: rcInstance.get(makeUrl`getCompaniesByErpType`, c.validateCompanies),
  getCurrentERPSettings: rcInstance.get(
    makeUrl`preferences/company/${'id'}/erp_config`,
    c.validateERPSettingsContracts,
  ),
  setCurrentERPSettings: rcInstance.post(
    makeUrl`preferences/company/${'id'}/erp_config`,
    c.validateERPSettingsContracts,
  ),
  duplicateDocument: rcInstance.post('duplicateDocument', c.validateDuplicateDocument),
  getDocumentUrl: rcInstance.url('getDocument'),
  getDocumentLinkedCount: rcInstance.get('getCountOfDocumentByLinkpanel', (x) => x),
  getDocumentCsv: rcInstance.url('exportBankStatement'),
  getDocumentThumbnailUrl: rcInstance.url('getDocumentThumbnail'),
  getDocuments: rcInstance.post('getDocuments', c.validateGetDocuments),
  getUnreadRequests: rcInstance.get('GetUnreadRequests', c.validateUnreadRequests),
  removeDocuments: rcInstance.post('removeDocuments', c.validateRemoveDocuments),
  stopDocumentProcessing: rcInstance.post('setDocumentAsViewed', c.validateDocumentMetadata),
  headerTablesCorrection: rcInstance.post('headerTablesCorrection', (x) => x),
  getEvents: rcInstance.get('getEvents', c.validateGetEvents),
  getEventFilters: rcInstance.get('getEventFilters', c.validateGetEventFilters),
  getCountOfNewEvents: rcInstance.get('getCountOfNewEvents', c.validateGetCountOfNewEvents),
  exportEventsHistory: rcInstance.url('exportEventsHistory'),
  getCurrencies: rcInstance.get('getCurrencies', c.validateCurrencies),
  getTimezones: rcInstance.get('getTimezones', (x) => x),
  activateErp: rcInstance.get('activateErp', c.validateActivateErp),
  importData: rcInstance.post('importData', c.validateERPSettingsContracts),
  getReconcileStatistic: rcInstance.post('getReconcileStatistic', c.validateReconcileStatistic),
  getReconciliationSearchParams: request.get('getSearchJournalEntry'),
  updateReconciliationSearchParams: request.post('getSearchJournalEntry'),
  reconcileTransaction: request.post('acceptSearchResult'),
  undoTransaction: request.post('undoFromSearch'),
  anGetDocument: rcInstance.url('anonymous/getDocument'),
  anGetDocumentMessagingHistory: rcInstance.get('anonymous/getDocumentMessagingHistory', (x) => x),
  anUpdateRequestTransactionAnonymously: rcInstance.post('anonymous/updateRequestTransactionAnonymously', (x) => x),
  getDocumentMessagingHistory: rcInstance.get('StatementDetailsRequest', (x) => x),
  createTransactionRequest: rcInstance.post('StatementDetailsRequest', (x) => x),
  markStatementRequestAsRead: rcInstance.post('MarkStatementRequestAsRead', (x) => x),
  getOrganizationList: rcInstance.get('organizations', (x) => x),
  // updateApproval: rcInstance.mock(baseApprovalMock, x => x),
  updateApproval: rcInstance.post('approvals', (x) => x),
  signApproval: rcInstance.post('approvals/sign', c.validateDocumentMetadata),
  rejectApproval: rcInstance.post('approvals/reject', (x) => x),
  // getApprovalRejectReasons: rcInstance.mock(approvalRejectReasons, x => x),
  getApprovalApproveReasons: request.get('approvals/getApprovalForm', (x) => x),
  updateApprovalApproveReasons: request.put('approvals/updateApprovalForm', (x) => x),
  changeBulkApprovalFlowStatus: request.post('bulkSetStatusApprovalFlow', (x) => x),
  bulkApprovalReplace: request.post('approvals/nodes/bulk_replace', (x) => x),
  getCategories: rcInstance.get('getWorkspaceCategories', (v) => v),
  moveDocumentToCategory: rcInstance.post('changeCategoryForDocuments', (x) => x),
  moveAllCategoryDocumentsToOtherCategory: rcInstance.get('moveDocumentsToCategory', (x) => x),
  setDocumentAsCover: rcInstance.post('setDocumentAsCover', (x) => x),
  getGrid: rcInstance.get('search_new_4grid', (x) => x),
  getGridPaginated: rcInstance.post('grid/documents/search', (x) => x),
  getGridHeaders: rcInstance.get('grid/documents/headers', (x) => x),
  getGridTotalByCategory: rcInstance.get('grid/documents/total', (x) => x),
  getGridFilterListByField: rcInstance.get('grid/autocomplete', (x) => x),
  getInsightsGrid: rcInstance.get('search_new_4grid/insights', (x) => x),
  getWsGridPresets: rcInstance.get('grid_configs', c.validateWsGridPresetsResponse),
  createWsGridPreset: rcInstance.post('grid_configs', c.validateWsGridPresetsResponse),
  updateWsGridPreset: rcInstance.put('grid_configs', c.validateWsGridPresetsResponse),
  deleteWsGridPreset: rcInstance.delete('grid_configs', c.validateWsGridPresetsResponse),
  getWsGridPreset: rcInstance.get(makeUrl`grid_configs/${'id'}`, c.validateWsGridPresetResponse),
  addApproval: rcInstance.post('approvals/nodes/add', (x) => x),
  removeApproval: rcInstance.post('approvals/nodes/delete', (x) => x),
  changeOrderApproval: rcInstance.post('approvals/nodes/rearrange', (x) => x),
  setApprovalFlowStatus: rcInstance.post('approvals/flow/set_status', (x) => x),
  getEmailData: rcInstance.get('getEmailData', (x) => x),
  getNotifications: rcInstance.get(makeUrl`user/${'userGUID'}/notifications`, c.validateNotifications),
  updateNotifications: rcInstance.post(makeUrl`user/${'userGUID'}/notifications`, c.validateNotifications),
  getDocumentHotkeys: rcInstance.get('hotkeys', c.validateDocumentHotkeyList),
  updateUserFeatures: rcInstance.post('preferences/user/feature_set', c.validateUserFeatures),
  jeGetIndexForm: rcInstance.get('indexForm', (x) => x),
  getDocumentsDownloadURL: rcInstance.post('getDocumentsDownloadURL', (x) => x),
  getSuppliersDefaults: rcInstance.get('supplierFormGrid', (x) => x),
  updateSuppliersDefaults: rcInstance.post('supplierFormGrid', (x) => x),
  importCorruptedVendorPreferences: rcInstance.url('vendorPreferencesImportErrorsReport', (x) => x),
  importVendorPreferences: rcInstance.post('vendorPreferencesFile', (x) => x),
  exportVendorPreferences: rcInstance.url('vendorPreferencesFile', (x) => x),
  ignoreDocumentWarnings: rcInstance.post('ignoreWarningDocument', (x) => x),
  getAliases: rcInstance.get('supplier_view/aliases', (x) => x),
  updateAliases: rcInstance.post('supplier_view/aliases', (x) => x),
  getDocumentVendor: rcInstance.get('getDocumentVendor', c.validateInsightsDocumentVendor),
  sendDocByEmail: rcInstance.post('send_document_email', (x) => x),
  getEmailsForAutocomplete: rcInstance.get('email_autocomplete', (x) => x),
  // ws
  getWSConectionUrl: rcInstance.get('getWebSocketConnectionURL', (x) => x),
  // approvals
  getApprovalGroups: rcInstance.get('approvals/groups', (x) => x),
  createApprovalsGroup: rcInstance.post('approvals/groups', (x) => x),
  updateApprovalGroup: rcInstance.post(makeUrl`approvals/groups/${'groupId'}`, (x) => x),
  deleteApprovalsGroup: rcInstance.delete(makeUrl`approvals/groups/${'groupId'}`, (x) => x),
  // textract
  getExtractedTableData: rcInstance.get(makeUrl`tables_data/${'documentID'}`, (x) => x),
  getExtractedTableFieldsMapping: rcInstance.get(makeUrl`tables_data/fields/${'documentID'}`, (x) => x),
  learnExtractedTableFields: rcInstance.post(makeUrl`tables_data/learn_items_headers/${'documentID'}`, (x) => x),
  extractLines: rcInstance.post(makeUrl`tables_data/lines_extraction/${'documentID'}`, (x) => x),
  // UX switching
  setUXCookie: rcInstance.mock({}, (x) => x),
  removeUXCookie: rcInstance.mock({}, (x) => x),
  // organization api keys
  getOrganizationApiKeys: rcInstance.get('organizationApiKey', (x) => x),
  createOrganizationApiKey: rcInstance.post('organizationApiKey', (x) => x),
  updateOrganizationApiKey: rcInstance.put(makeUrl`organizationApiKey/${'apiKey'}`, (x) => x),
  deleteOrganizationApiKey: rcInstance.delete(makeUrl`organizationApiKey/${'apiKey'}`, (x) => x),
  // get user IP address
  getUserIP: rcInstance.get('getUserIP', (x) => x),
};
