// @flow
import React, { useEffect, useRef, forwardRef, useCallback, useMemo, useState, useLayoutEffect } from 'react';
import { Set, OrderedSet, OrderedMap } from 'immutable';
import isEqual from 'lodash/isEqual';
import flow from 'lodash/fp/flow';
import { debounce } from 'throttle-debounce';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Api from 'domain/api';
import {
  documentsGridFilterAppliedAction,
  documentsGridSortingAppliedAction,
  documentsGridColumnsAppliedAction,
  documentsGridGetRowsAction,
  documentsGridDefaultColumnsAppliedAction,
  documentsGridSetListIdWithFiltersAction,
  gridCurrentFiltersSelector,
  gridCurrentSortSelector,
  gridCurrentColumnsStateSelector,
  savedDocumentListDataWithFiltersSelector,
  gridRawPresetsLoadedSelector,
  gridRefreshServerSideSelector,
  gridApplyTransactionSelector,
  documentsGridApplyTransactionAction,
  documentsGridRefreshServerSideAction,
} from 'domain/documents';
import { GridApplyTransactionFactory } from 'domain/documents/factories';
import { isCompanyConfigurationLoadedSelector } from 'domain/companies/companiesSelector';
import {
  changeWidthIfMasterViewNotAvailable,
  setExelModeFilter,
  addColDefaultParams,
  GRID_SERVICE_KEYS,
  suppressSelectAll,
} from 'pages/company/grid/helpers';
import {
  addSelectionMasterDetailToFirstColumn,
  addColumnsVisibilityColumnDef,
  addContextMenuColumnDef,
  addPreviewColumnDef,
  addLinkedButtonColumnRef,
} from 'pages/company/grid/helpers/columnDefs';
import * as ACL from 'domain/restriction';
import { addFilters } from 'pages/company/grid/helpers/filterHelpers';
import { documentsGridRowNodesAdapter, gridDocumentsListAdapter } from 'domain/documents/adapters';
import { getNestedCategory } from 'domain/categories/helpers';

// components
import { AgGridReact } from 'ag-grid-react';
import Box from '@mui/material/Box';
import DocumentPreviewNavigation from 'pages/company/DocumentPreviewNavigation/DocumentPreviewNavigation';
import RowDetailsJE from './components/rowDetailsJE';
import WithDocumentViewButton from './components/withDocumentViewButton';
import ContextButton from './components/contextButton';
import AgGridDateFilter from './components/DateFilter';
import ApprovalsCellRenderer from './components/ApprovalsCellRenderer';
import TagsCellRenderer from './components/TagsCellRenderer';
import AgGridCustomTooltip from './components/CustomTooltip';
import StatusCellRenderer from './components/StatusCellRenderer';
import AgGridColumnHeader from './components/ColumnHeader';
import AmountSumStatusPanelRenderer from './components/AmountSumStatusPanelRenderer';
import AmountCellRenderer from './components/AmountCellRenderer';
import CustomSetColumnFilter from 'pages/company/grid/components/CustomHeaderFilter/CustomSetColumnFilter/CustomSetColumnFilter';
import LinkedButton from './components/LinkedButton';
import StubWhenEmpty from 'pages/company/stub/StubWhenEmpty';

import usePropagateGridApi from 'pages/company/grid/usePropagateGridApiRef';
import usePrevious from 'hooks/usePrevious';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useScrollManager from 'lib/scrollManager';
import useAgGridDefaultConfig from 'hooks/agGrid/useAgGridDefaultConfig';
import useColumnTypes from 'hooks/agGrid/columnTypes/useColumnTypes';
import useAgGridManualConfig from 'hooks/agGrid/useAgGridManualConfig';
import useAgGridMethods from 'hooks/agGrid/useAgGridMethods';
import useGridContext from 'pages/company/grid/useGridContext';
import useFetchGridColumnDefs from 'pages/company/grid/useFetchGridColumnDefs';
import useFetchTotalByCategory from 'pages/company/grid/useFetchTotalByCategory';
import useRangeSelectionChanged from './useRangeSelectionChanged';
import { IS_OPEN_CONTEXT_MENU_CLASS } from 'hooks/agGrid/agGridThemeClasses';
import useWorkspaceGridStyles from './useWorkspaceGridStyles';

import type { TDocumentRecord, TDocument } from 'domain/documents/types.js.flow';
import type { TGridData, TgridApiRef, GridRef } from './types.js.flow';
import type { PreviewStateT } from '../type.js.flow';

export interface IGridProps {
  selected: OrderedSet<string>;
  onChangeSelected: (newSelected: Set<string>) => void;
  onContextMenu: (e: SyntheticMouseEvent<HTMLElement>, d?: TDocumentRecord, l?: string) => void;
  onDocumentOpen: (documentID: string, isLinked?: ?boolean) => void;
  getDocumentUrl: (documentID: string) => string;
  isContextMenuOpen?: string;
  onPreview: (p: PreviewStateT) => void;
  preview: PreviewStateT;
  onShowLinked: (tag: string) => Promise<*>;
  resetIsLinkedPanelSelected: () => void;
  isLinkedPanelSelected: string;
}

type TFrameworComponentProps = {
  ...TGridData,
};

export const PREVIEW_CONTEXT_NAME = 'gridList';
const PER_PAGE = 500;

const mapStateToProps = (state) => ({
  savedDocumentListDataWithFilters: savedDocumentListDataWithFiltersSelector(state),
  isGranted: ACL.isGranted(state),
  gridFilters: gridCurrentFiltersSelector(state),
  gridSorting: gridCurrentSortSelector(state),
  gridColumnsState: gridCurrentColumnsStateSelector(state),
  isGridPresetLoaded: gridRawPresetsLoadedSelector(state),
  isCompanyConfigurationLoaded: isCompanyConfigurationLoadedSelector(state),
  refreshDocumentNodes: gridApplyTransactionSelector(state),
  refreshServerSide: gridRefreshServerSideSelector(state),
});

const Grid = forwardRef(
  (
    {
      onContextMenu,
      onChangeSelected,
      onDocumentOpen,
      getDocumentUrl,
      selected,
      isContextMenuOpen = null,
      onPreview,
      preview,
      onShowLinked,
      resetIsLinkedPanelSelected,
      isLinkedPanelSelected,
    }: IGridProps,
    ref: GridRef,
  ) => {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const { search } = useLocation();

    const {
      savedDocumentListDataWithFilters,
      refreshDocumentNodes,
      refreshServerSide,
      isGranted,
      gridFilters,
      gridSorting,
      gridColumnsState,
      isGridPresetLoaded,
      isCompanyConfigurationLoaded,
    } = useSelector(mapStateToProps);

    const agGridDefaultProps = useAgGridDefaultConfig(useWorkspaceGridStyles);
    const { enableRtl, rowClassRules, getLocaleText } = useAgGridManualConfig();
    const { invertPinnedWhenRTL } = useAgGridMethods();
    const columnTypes = useColumnTypes();
    const gridApiRef: {| current: null | TgridApiRef |} = useRef();
    const onFirstDataRenderedRef = useRef(false);
    const previousFilterModel = useRef({}); // used for detect if filter model has changes
    const previousSortModel = useRef([]); // used for detect if sort model has changes
    const previousColumnModel = useRef([]); // used for detect if column model has changes - [width, pinned, order, hide]
    const previousInitialColumnState = useRef([]); // used for detect if initial column state has changes
    const previousSelectedSize = usePrevious(selected.size); // used for detect cmd/ctrl+A or escape
    const selectedIDsRef = useRef(selected);
    // documentsOrderedMapRef used for pagination, dispatch actual documents list to redux
    // set filtered or next loaded chunk of documents
    const documentsOrderedMapRef = useRef(new OrderedMap()); // OrderedMap with adapted documents by ID
    const [isGridInit, setGridInit] = useState(false);
    const [, updateState] = useState(); // TODO: ag-grid review this
    const contextMenuCellRef = useRef(null);
    const onRangeSelectionChanged = useRangeSelectionChanged(gridApiRef);
    const category = getNestedCategory(routeParams);

    const hasMasterDetail = isGranted(ACL.IS_BOOKKEEPER_IN_FINANCIAL_COMPANY);

    const forceUpdate = useCallback(() => updateState({}), []);

    const gridContext = useGridContext();

    const { fetchGridTotal, documentsTotal, documentsTotalIsBusy } = useFetchTotalByCategory();
    const { rawColumnDefsIsBusy, rawColumnDefs } = useFetchGridColumnDefs();

    usePropagateGridApi(ref, gridApiRef, forceUpdate);

    useScrollManager({
      view: 'grid',
      isTargetLoaded: isGridInit && !documentsTotalIsBusy,
      gridApi: gridApiRef.current,
    });

    const defaultColumnDefs = useMemo(() => {
      if (rawColumnDefsIsBusy) return [];

      const columns = flow([
        addLinkedButtonColumnRef,
        addPreviewColumnDef,
        addSelectionMasterDetailToFirstColumn,
        addColumnsVisibilityColumnDef,
        addContextMenuColumnDef,
        addFilters,
        setExelModeFilter('windows'),
        addColDefaultParams,
      ])(rawColumnDefs);

      const state = changeWidthIfMasterViewNotAvailable(columns, hasMasterDetail);
      previousColumnModel.current = state;

      return state;
    }, [rawColumnDefsIsBusy, rawColumnDefs, hasMasterDetail]);

    const columnDefs = useMemo(
      () => flow(suppressSelectAll, invertPinnedWhenRTL)(defaultColumnDefs),
      [defaultColumnDefs, invertPinnedWhenRTL],
    );

    const initialColumnState = useMemo(
      () =>
        defaultColumnDefs.map(({ field, hide = false, pinned, width = 0, minWidth, maxWidth }) => {
          const currentWidth = Math.max(minWidth || 1, Math.min(maxWidth || 1000, width));

          return {
            aggFunc: null,
            colId: field,
            flex: null,
            hide,
            pinned: pinned || null,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: null,
            sortIndex: null,
            width: currentWidth,
          };
        }),
      [defaultColumnDefs],
    );

    const initialState = useMemo(
      () => ({
        filter: {
          filterModel: gridFilters,
        },
        sort: {
          sortModel: gridSorting,
        },
      }),
      [gridSorting, gridFilters],
    );

    useEffect(() => {
      if (documentsTotal === 0) {
        gridApiRef.current.showNoRowsOverlay();
      }
    }, [isGridInit, documentsTotal]);

    useEffect(() => {
      if (
        rawColumnDefs.length > 0 &&
        initialColumnState.length > 0 &&
        !isEqual(previousInitialColumnState.current, initialColumnState)
      ) {
        console.log('======= SAVE INITIAL CONFIG initialColumnState');

        previousInitialColumnState.current = initialColumnState;
        previousColumnModel.current = initialColumnState;
        dispatch(documentsGridDefaultColumnsAppliedAction(initialColumnState));
      }
    }, [dispatch, initialColumnState, rawColumnDefs]);

    // // !IMPORTANT - dont change order of useEffect below for applying grid state
    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousFilterModel.current, gridFilters)) {
          console.log(
            '====== USE EFFECT APPLY COLUMN FILTER - ON DIFFERENT PREV FILTER STATE',
            previousFilterModel.current,
            gridFilters,
          );
          gridApiRef.current.setFilterModel(gridFilters);
          previousFilterModel.current = gridFilters;
        }
      }
    }, [isGridInit, gridFilters]);

    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousColumnModel.current, gridColumnsState)) {
          // skip sort props - we apply sort via gridSorting state
          const state = invertPinnedWhenRTL(gridColumnsState).map(({ colId, pinned, width, hide, flex }) => ({
            colId,
            pinned,
            width,
            hide,
            flex,
          }));

          // TODO: ag-grid the case of removing adding columns is not taken into account
          // const orderedColumnConfig = gridColumnsState || state || [];
          // return mergeDifferenceBtwColumnsAdapter(columns, orderedColumnConfig);
          console.log('====== USE EFFECT APPLY COLUMN STATE - ON DIFFERENT PREV COLUMN STATE', state);

          gridApiRef.current.applyColumnState({
            state,
            applyOrder: true,
          });
          previousColumnModel.current = gridColumnsState;
        }
      }
    }, [isGridInit, gridColumnsState, invertPinnedWhenRTL]);

    useEffect(() => {
      if (isGridInit) {
        if (!isEqual(previousSortModel.current, gridSorting)) {
          console.log('====== USE EFFECT APPLY COLUMN SORT - ON DIFFERENT PREV SORT STATE');
          const previousSort = previousSortModel.current[0];
          const currentSort = gridSorting[0];

          const state = [
            {
              sort: null,
              colId: previousSort?.colId,
            },
            {
              sort: currentSort?.sort || null,
              colId: currentSort?.colId,
            },
          ];

          gridApiRef.current.applyColumnState({
            state,
          });

          previousSortModel.current = gridSorting;
        }
      }
    }, [isGridInit, gridSorting]);

    useEffect(() => {
      if (gridApiRef.current && documentsOrderedMapRef.current.size > 0) {
        // reset selected rows when we have empty array list
        // TODO: ag-grid add comment about source 'api'
        if ((selected.size === 0 && previousSelectedSize) || isLinkedPanelSelected) {
          console.log('====== USE EFFECT SELECT API - deselectAll');
          gridApiRef.current.deselectAll('api');
          selectedIDsRef.current = OrderedSet();
        } else if (selected.size === documentsOrderedMapRef.current.size && previousSelectedSize !== selected.size) {
          console.log('====== USE EFFECT SELECT API - selectAll');
          gridApiRef.current.selectAll('api');
          selectedIDsRef.current = OrderedSet(selected);
        }
      }
    }, [selected, previousSelectedSize, isLinkedPanelSelected]);

    useEffect(() => {
      const { nodes, transactionType } = refreshDocumentNodes;
      if (transactionType && nodes.size > 0) {
        const data = documentsGridRowNodesAdapter(refreshDocumentNodes.nodes);
        console.log('==== INVOKE applyServerSideTransaction', nodes, transactionType, data);
        const transaction = {
          [refreshDocumentNodes.transactionType]: data,
        };

        const { status } = gridApiRef.current.applyServerSideTransaction(transaction);

        if (transactionType === 'remove' && status === 'Applied') {
          documentsOrderedMapRef.current = documentsOrderedMapRef.current.filter((d) => !nodes.includes(d.documentID));
          fetchGridTotal().then();
        }
        dispatch(documentsGridApplyTransactionAction(GridApplyTransactionFactory()));
      }
    }, [refreshDocumentNodes, dispatch, selected, fetchGridTotal]);

    useDidUpdateEffect(() => {
      // refresh grid data when category changed and skip it on first render
      console.log('====== INVOKE GRID API - REFRESH SERVER SIDE when [category] changed');
      gridApiRef.current.hideOverlay();
      gridApiRef.current.deselectAll();
      gridApiRef.current.refreshServerSide({ purge: true });
    }, [category]);

    useDidUpdateEffect(() => {
      // refresh grid data when [location search (URL query params)] changed and skip it on first render
      console.log('====== INVOKE GRID API - REFRESH SERVER SIDE when [query] changed');
      gridApiRef.current.hideOverlay();
      gridApiRef.current.refreshServerSide({ purge: false });
    }, [search]);

    useEffect(() => {
      if (typeof refreshServerSide === 'boolean') {
        console.log('=== INVOKE GRID API - REFRESH SERVER SIDE', refreshServerSide);
        gridApiRef.current.hideOverlay();
        gridApiRef.current.refreshServerSide({ purge: refreshServerSide });
        dispatch(documentsGridRefreshServerSideAction(null));
        fetchGridTotal().then();
      }
    }, [refreshServerSide, dispatch, fetchGridTotal]);

    const getRowID = useCallback(({ data }) => data.documentID, []);

    const isRowMaster = useCallback((dataItem) => dataItem.hasMasterDetail, []);

    const dispatchColumnState = useCallback(() => {
      if (gridApiRef.current) {
        const columns = gridApiRef.current.getColumnState();
        // we save to store always LTR version of grid state
        const stateLTR = invertPinnedWhenRTL(columns);
        console.log('==== INVOKE DISPATCH CURRENT COLUMN STATE TO REDUX - getColumnState', columns);
        // we compare with gridColumnState in ltr variant, so set to previousColumnModel.current ltr variant as well
        previousColumnModel.current = stateLTR;
        dispatch(documentsGridColumnsAppliedAction(stateLTR));
      }
    }, [dispatch, invertPinnedWhenRTL]);

    const handleColumnMoved = useCallback(() => {
      const allCols = gridApiRef.current.getAllGridColumns();
      const serviceColIndex = allCols.findIndex((col) => col.colId === GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU);

      if (serviceColIndex !== allCols.length - 1) {
        gridApiRef.current.moveColumns([GRID_SERVICE_KEYS.COLUMNS_VISIBILITY_MENU], allCols.length - 1);
      }
    }, []);

    const onSelectionChanged = useCallback(
      ({ api, source, type }) => {
        console.log('=== INVOKE onSelectionChanged FN - source, type', source, type);

        if (!isLinkedPanelSelected && source !== 'api') {
          let selectedIDs;
          const { selectAll, toggledNodes } = api.getServerSideSelectionState();
          console.log('=== INVOKE selection changed  - selectAll, toggledNodes', selectAll, toggledNodes);
          const documentIDs = documentsOrderedMapRef.current.keySeq().toOrderedSet();

          if (selectAll) {
            if (toggledNodes.length > 0) {
              selectedIDs = OrderedSet(documentIDs).subtract(toggledNodes);
            } else {
              selectedIDs = OrderedSet(documentIDs);
            }
          } else {
            selectedIDs = OrderedSet(toggledNodes);
          }

          if (!selectedIDs.equals(selectedIDsRef.current)) {
            console.log('=== INVOKE selection changed - selectedIDs', selectedIDs);
            console.log('===============');
            selectedIDsRef.current = selectedIDs;
            resetIsLinkedPanelSelected();
            onChangeSelected(selectedIDs);
          }
        }
      },
      [onChangeSelected, resetIsLinkedPanelSelected, isLinkedPanelSelected],
    );

    const onRowGroupOpened = useCallback((e: { [key: string]: any, expanded: boolean }) => {
      if (e.expanded && gridApiRef.current) {
        gridApiRef.current.forEachNode((node) => {
          if (node.expanded && e.data.documentID !== node.data.documentID) {
            node.setExpanded(false);
          }
        });
      }
    }, []);

    const handleChangeColumnState = useMemo(
      () =>
        debounce(250, ({ type, source }) => {
          // dispatch column state only when done manually
          // dragStopped - column drag, resize column
          // autosizeColumns - via column menu (generalMenuTab)
          if (type === 'dragStopped' || source === 'autosizeColumns') {
            console.log('===== INVOKE handleChangeColumnState CALLBACK', type, source);
            dispatchColumnState();
          }
        }),
      [dispatchColumnState],
    );

    const onColumnPinned = useCallback(
      ({ type, source }) => {
        // dispatch column state only when done manually via menu
        // skip when we apply state via api
        console.log('==== INVOKE ON COLUMN PINNED CALLBACK');
        if (source === 'columnMenu') {
          console.log('==== INVOKE ON COLUMN PINNED CALLBACK', type, source);
          dispatchColumnState();
        }
      },
      [dispatchColumnState],
    );

    const onColumnMenuVisibleChanged = useCallback(
      ({ visible, key, api }) => {
        // when click on column menu button for management columns -
        // event return sometimes key generalMenuTab instead of columnsMenuTab
        if (visible === false && ['columnsMenuTab', 'generalMenuTab'].includes(key)) {
          const state = api.getColumnState();
          const previousHiddenColumn = previousColumnModel.current.filter((col) => col.hide === true);
          const currentHiddenColumn = state.filter((col) => col.hide === true);

          if (previousHiddenColumn.length !== currentHiddenColumn.length) {
            console.log('==== INVOKE onColumnMenuVisibleChanged CALLBACK');
            dispatchColumnState();
          }
        }
      },
      [dispatchColumnState],
    );

    const setPreview = useCallback(
      (doc: TDocument | null) => {
        const [documentId, contextName] = doc ? [doc.documentID, PREVIEW_CONTEXT_NAME] : [null, ''];
        onPreview({ documentId, contextName });
      },
      [onPreview],
    );

    const onClickOpen = useCallback(
      (_, doc: TDocument) => {
        onDocumentOpen(doc.documentID);
      },
      [onDocumentOpen],
    );

    const handleContextMenu = useCallback(
      (e, rowDocumentData) => {
        onContextMenu(e, rowDocumentData);
        // context menu icon stay visible when menu is open
        const element = e.currentTarget.closest(`[col-id="${GRID_SERVICE_KEYS.CONTEXT_MENU_COLUMN_NAME}"]`);

        element.classList.add(IS_OPEN_CONTEXT_MENU_CLASS);
        contextMenuCellRef.current = element;
      },
      [onContextMenu],
    );

    useEffect(() => {
      if (!isContextMenuOpen && contextMenuCellRef.current) {
        contextMenuCellRef.current.classList.remove(IS_OPEN_CONTEXT_MENU_CLASS);
        contextMenuCellRef.current = null;
      }
    }, [isContextMenuOpen]);

    const previewComponentRender = useCallback(
      ({ data: { rowDocumentData }, ...rest }: TFrameworComponentProps) => (
        <WithDocumentViewButton
          {...rest}
          onClick={() => {
            setPreview(rowDocumentData);
            // TODO: ag-grid check this
            // it is way hard row resetting allows us to avoid grid's scrolling during navigation preview by arrow keys
            rest.api.redrawRows({ rowNodes: [rest.node] });
          }}
          to={getDocumentUrl(rowDocumentData.documentID)}
          onDocumentOpen={() => onDocumentOpen(rowDocumentData.documentID)}
        />
      ),
      [getDocumentUrl, onDocumentOpen, setPreview],
    );

    const contextRenderRender = useCallback(
      ({ data: { rowDocumentData }, ...rest }: TFrameworComponentProps) => (
        <ContextButton {...rest} onClick={(e) => handleContextMenu(e, rowDocumentData)} />
      ),
      [handleContextMenu],
    );

    const linkedButtonComponentRender = useCallback(
      ({ data: { rowDocumentData } }: TFrameworComponentProps) => (
        <LinkedButton linkid={rowDocumentData.linkid} onClick={onShowLinked} />
      ),
      [onShowLinked],
    );

    const masterViewCellRenderer = useCallback(
      (props: TGridData) => <RowDetailsJE documentID={props.data.documentID} />,
      [],
    );

    const frameworkComponents = useMemo(
      () => ({
        masterViewCellRenderer,
        approval: ApprovalsCellRenderer,
        tag: TagsCellRenderer,
        amount: AmountCellRenderer,
        customSetColumnFilter: CustomSetColumnFilter,
        amountSumStatusPanel: AmountSumStatusPanelRenderer,
        preview: previewComponentRender,
        linkedButton: linkedButtonComponentRender,
        statusCellRenderer: StatusCellRenderer,
        agDateInput: AgGridDateFilter,
        agColumnHeader: AgGridColumnHeader,
        customTooltip: AgGridCustomTooltip,
        context: contextRenderRender,
      }),
      [masterViewCellRenderer, contextRenderRender, previewComponentRender, linkedButtonComponentRender],
    );

    const serverDatasource = useMemo(
      () => ({
        getRows: async (params) => {
          const { context, success, fail, request, api } = params;
          const { startRow, endRow, filterModel, sortModel } = request;
          const { fetchParams } = context;

          api.hideOverlay();
          console.log('==== SERVER DATASOURCE PARAMS', sortModel, filterModel);

          if (!isEqual(previousSortModel.current, sortModel)) {
            previousSortModel.current = sortModel;
            console.log('======= SERVER REQUEST DISPATCH SORT MODEL', previousSortModel.current, sortModel);
            dispatch(
              documentsGridSortingAppliedAction({
                sorting: sortModel,
              }),
            );
          }

          if (!isEqual(previousFilterModel.current, filterModel)) {
            console.log('======= SERVER REQUEST DISPATCH FILTER MODEL', filterModel);
            dispatch(
              documentsGridFilterAppliedAction({
                filters: filterModel,
              }),
            );
            previousFilterModel.current = filterModel;
          }

          try {
            const {
              data: { items, count, timeStamp },
            } = await Api.getGridPaginated({
              data: {
                ...fetchParams,
                startRow,
                endRow,
                filterModel,
                sortModel,
              },
            });

            const adaptedList = gridDocumentsListAdapter(items);
            const rowData = documentsGridRowNodesAdapter(adaptedList);

            success({ rowData, rowCount: count });

            documentsOrderedMapRef.current = adaptedList;

            dispatch({
              type: documentsGridGetRowsAction.type,
              payload: {
                list: documentsOrderedMapRef.current,
                count,
                timeStamp,
                count_without_link_panels: documentsOrderedMapRef.current.size,
              },
            });

            const filteredRows = documentsOrderedMapRef.current.map((_, documentID) => documentID);

            dispatch(documentsGridSetListIdWithFiltersAction(filteredRows.toOrderedSet()));
          } catch (error) {
            console.log('====== REQUEST FAILED', error);
            fail();
            api.showNoRowsOverlay();
          }
        },
      }),
      [dispatch], // be careful when adding dependencies to hook - potentially invoked again every time values change
    );

    const onGridReady = useCallback(async (params: {| api: TgridApiRef |}) => {
      gridApiRef.current = params.api;
      setGridInit(true);
    }, []);

    const onFirstDataRendered = useCallback(() => {
      // console.log('====== ON FIRST DATA RENDERED');
      onFirstDataRenderedRef.current = true;
    }, []);

    const onPaginationChanged = useCallback(({ newData, newPage, api }) => {
      console.log('====== ON PAGINATION CHANGED', newData, newPage);
      if (newPage) {
        api.deselectAll();
      }
    }, []);

    const onStateUpdated = useCallback(({ state, sources, api }) => {
      console.log('====== ON STATE UPDATED', state, sources, api.getColumnState());
    }, []);

    const isPreviewOpen = preview.documentId && preview.contextName === PREVIEW_CONTEXT_NAME;

    if (!isGridPresetLoaded || !isCompanyConfigurationLoaded || rawColumnDefsIsBusy) {
      return null;
    }

    return (
      <>
        <Box className="ag-theme-material" display="flex" flexDirection="column" height="100%">
          <AgGridReact
            rowModelType="serverSide"
            serverSideDatasource={serverDatasource}
            masterDetail={hasMasterDetail}
            suppressServerSideFullWidthLoadingRow
            isRowMaster={isRowMaster}
            components={frameworkComponents}
            detailRowHeight={400}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onStateUpdated={onStateUpdated}
            onRowGroupOpened={onRowGroupOpened}
            onColumnPinned={onColumnPinned}
            onSelectionChanged={onSelectionChanged}
            onDragStopped={handleChangeColumnState}
            onColumnMoved={handleColumnMoved}
            onRangeSelectionChanged={onRangeSelectionChanged}
            onColumnResized={handleChangeColumnState}
            onColumnMenuVisibleChanged={onColumnMenuVisibleChanged}
            onPaginationChanged={onPaginationChanged}
            // props from useAgGridManualConfig hook
            enableRtl={enableRtl}
            getLocaleText={getLocaleText}
            rowClassRules={rowClassRules}
            // props from useAgGridDefaultConfig hook
            {...agGridDefaultProps}
            columnTypes={columnTypes}
            getRowId={getRowID}
            rowHeight={58}
            columnDefs={columnDefs}
            context={gridContext}
            cacheBlockSize={PER_PAGE}
            initialState={initialState}
            noRowsOverlayComponent={StubWhenEmpty}
            pagination
            paginationPageSize={PER_PAGE}
            paginationPageSizeSelector={false}
            serverSideInitialRowCount={documentsTotal} // used for restore scroll position
          />
          s
        </Box>

        {isPreviewOpen && (
          <DocumentPreviewNavigation
            documentId={preview.documentId}
            setPreview={setPreview}
            list={savedDocumentListDataWithFilters}
            allCount={savedDocumentListDataWithFilters.size}
            onContextMenu={onContextMenu}
            isContextMenuOpen={isContextMenuOpen}
            onClick={onClickOpen}
          />
        )}
      </>
    );
  },
);

// eslint-disable-next-line react/prop-types
export default ({ forwardedRef, ...props }) => <Grid ref={forwardedRef} {...props} />;
