// @flow
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { categoryAllSelector } from 'domain/categories';
import { generatePath, useParams } from 'react-router-dom';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import EmptyContainer from './components/EmptyContainer';
import CompanyContext from 'pages/company/CompanyContext';

import noDocs from './images/noDocs.png';
import noDocsConfidential from './images/noDocsConfidential.png';

import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

const empty = {
  img: '',
  Icon: null,
  title: '1',
  defaultTitle: '1',
  description: '1',
  defaultDescription: '1',
};

const noDocuments = {
  img: noDocs,
  Icon: null,
  title: 'document.search.no_documents',
  defaultTitle: 'No documents',
  description: 'document.search.no_document_description',
  defaultDescription:
    'The documents that you work with will be here. They will load automatically when the company sends them to you, or you can add a document from your computer.',
};

const screens = [
  {
    img: '',
    Icon: CelebrationOutlinedIcon,
    title: 'document.search.caught.title',
    defaultTitle: 'All caught up!',
    description: 'document.search.caught.description',
    defaultDescription: 'You’ve done all work here. Please, enjoy your day!',
  },
  {
    img: '',
    Icon: EmojiEventsOutlinedIcon,
    title: 'document.search.wellDone.title',
    defaultTitle: 'Well done!',
    description: 'document.search.wellDone.description',
    defaultDescription: 'You do not have any documents to work here.',
  },
  {
    img: '',
    Icon: RocketOutlinedIcon,
    title: 'document.search.great.title',
    defaultTitle: 'You are doing great!',
    description: 'document.search.great.description',
    defaultDescription: 'There is no more work for you.',
  },
  {
    img: '',
    Icon: StarBorderOutlinedIcon,
    title: 'document.search.keepItUp.title',
    defaultTitle: 'Keep it up!',
    description: 'document.search.keepItUp.description',
    defaultDescription: 'You’ve finished all the documents and there is no work left for you.',
  },
  {
    img: '',
    Icon: MilitaryTechOutlinedIcon,
    title: 'document.search.congrats.title',
    defaultTitle: 'Congratulations!',
    description: 'document.search.congrats.description',
    defaultDescription: 'You’ve finished all the documents here.',
  },
];

const confidentialScreen = {
  img: noDocsConfidential,
  Icon: null,
  className: 'confidential',
  title: 'document.search.confidential.title',
  defaultTitle: 'There is a place for confidential documents',
  description: 'document.search.confidential.description',
  defaultDescription: "You can send it to bookkeepers, but other members of your company don't see it.", //eslint-disable-line
};

type TNoDocuments = {
  view: string,
};

const NoDocuments: React$StatelessFunctionalComponent<TNoDocuments> = ({ view }) => {
  const allCategory = useSelector(categoryAllSelector);
  const { companyId } = useParams();
  const { companyType, toggleUpload } = useContext(CompanyContext);
  const [screen, setScreen] = useState(empty);
  const random = companyType === 'standard' && view === 'noDocs';

  const onClick = useCallback(() => {
    toggleUpload();
  }, [toggleUpload]);

  const link = useMemo(() => {
    if (random) {
      const path =
        companyType === 'confidential'
          ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
          : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

      return allCategory?.id ? generatePath(path, { category1: allCategory.id, companyId }) : null;
    }
    return '';
  }, [random, allCategory, companyId, companyType]);

  useEffect(() => {
    const currentScreen = random ? screens[Math.floor(Math.random() * screens.length)] : noDocuments;
    setScreen(companyType === 'confidential' ? confidentialScreen : currentScreen);
  }, [random, companyType]);

  return (
    <EmptyContainer
      link={random ? link : ''}
      imageSrc={screen.img}
      Icon={screen.Icon}
      title={{ id: screen.title, defaultMessage: screen.defaultTitle }}
      description1={{
        id: screen.description,
        defaultMessage: screen.defaultDescription,
      }}
      buttonText={{
        id: random ? 'document.search.workspace.go_to_all' : 'document.search.no_document.button',
        defaultMessage: random ? 'Go to all documents' : 'Add new document',
      }}
      onClick={onClick}
    />
  );
};

export default NoDocuments;
