/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { compose, type Dispatch } from 'redux';
import { Set, Map, type RecordOf } from 'immutable';
import Api from 'domain/api';
import print, { removePrintFramesFromDOM, onPrint } from 'lib/print';
import { isOpenSelector } from 'domain/chat/chatSelector';
import { type ConfirmOptions } from 'material-ui-confirm';

import { rootCategoriesListSelector, type CategoriesList } from 'domain/categories';
import { injectIntl, FormattedMessage, type IntlShape } from 'react-intl';
import {
  documentsChangeTypesAction,
  documentsAddSignaturesAction,
  documentsBulkAcceptAction,
  documentsDownloadDocumentsAction,
  documentsRemoveDocumentsAction,
  documentsByIdSelector,
  documentLinkedSelector,
  documentLinkedTagSelector,
  mergeDocumentsAction,
  documentHideLinkedDocs,
  mergeDocumentsForPrintingAction,
} from 'domain/documents';
import { roleModificatorsSelector, userFeaturesSelector } from 'domain/env';
import { checkAcceptSignType } from 'domain/documents/helpers';
import { companiesByIdSelector, isGridWorkSpaceSelector } from 'domain/companies';
import type { DocDropParams } from 'pages/company/type.js.flow';
import type { TDocumentRecord } from 'domain/documents/types.js.flow';
import { type GridRef } from 'pages/company/grid/types.js.flow';
import { isMacOs } from 'lib/systemHelpers/osHelpers';
import { isAllowMoveToCategories, getMovableCategories } from 'domain/categories/helpers';
import CONST from 'domain/documents/constants';
import { makeTost, type ToastActionType } from './messages';
import * as ACL from 'domain/restriction';
import { withApiToken } from 'lib/apiTokenKeeper';
import type { TUserFeatures } from 'domain/env/types.js.flow';
import withConfirm from 'hoc/withConfirm';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/mui/Tooltip/TooltipForButton';
import toast, { type TToastType } from 'components/Toast';
import DialogSignDocument from 'pages/common/Dialog/DialogSignDocument';
import DialogTagMultipleManage from 'pages/common/Dialog/DialogTagsManage/DialogTagMultipleManage';
// import DialogManageBulkApprovalFlow from 'pages/common/Dialog/DialogManageBulkApprovalFlow';
import BulkApproveButton from 'pages/company/CompanyBulkPanel/BulkApproveButton';
import DotsMenu from 'pages/company/CompanyBulkPanel/DotsMenu';
import { SelectPanelContainer, CategoryIcon, Divider } from './StyledComponents';
import DialogSendByEmail from 'pages/common/Dialog/DialogSendByEmail';

import IconInfinity from 'components/Icons/infinity';
import CloseIcon from '@mui/icons-material/Close';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ScoreOutlined from '@mui/icons-material/ScoreOutlined';
import PostAddOutlined from '@mui/icons-material/PostAddOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { type TRoleModificators } from 'domain/env/types.js.flow';

type DocIdType = $Subtype<string>;

type Props = {
  intl: IntlShape,
  disabled: boolean,
  documentsIds: Set<DocIdType>,
  documentsById: Map<DocIdType, TDocumentRecord>,
  clearSelection: () => void,
  companyId: string,
  signParams: any,
  linkid: ?string,
  isLinkedPanelSelected: boolean,
  selectAll: () => void,
  changeTypes: Dispatch<typeof documentsChangeTypesAction>,
  addSignatures: Dispatch<typeof documentsAddSignaturesAction>,
  download: Dispatch<typeof documentsDownloadDocumentsAction>,
  delete: Dispatch<typeof documentsRemoveDocumentsAction>,
  mergeDocuments: Dispatch<typeof mergeDocumentsAction>,
  mergeDocumentsForPrinting: Dispatch<typeof mergeDocumentsForPrintingAction>,
  hideLinkedDocs: Dispatch<typeof documentHideLinkedDocs>,
  bulkAccept: Dispatch<typeof documentsBulkAcceptAction>,
  isGranted: (number | number[]) => boolean,
  rootCategoriesList: CategoriesList,
  currentRootCategory: string,
  moveDocumentToCategory: (data: DocDropParams) => void,
  gridRef: GridRef,
  isGridView: boolean,
  userFeatures: RecordOf<TUserFeatures>,
  apiToken: string,
  isChatOpen: boolean,
  confirm: (options?: ConfirmOptions) => Promise<void>,
  roleModificators: TRoleModificators,
};

type State = {|
  showSignAllModal: boolean,
  showTagAllModal: boolean,
  showSendByEmailModal: boolean,
  isActive: boolean,
  isBusyPrint: boolean,
|};

const INPUT_LOCAL_NAMES = ['input', 'textarea'];

function promisify(fn: Function, arg: Object) {
  return new Promise((resolve, reject) => {
    fn({ ...arg, resolve, reject });
  });
}

class CompanyBulkPanel extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      showSignAllModal: false,
      showTagAllModal: false,
      showSendByEmailModal: false,
      isActive: false,
      isBusyPrint: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown, false);
    window.addEventListener('keyup', this.handleKeyUp, false);
    document.addEventListener('contextmenu', this.handleRightClick, false);
    window.addEventListener('blur', this.handleBlur, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('contextmenu', this.handleRightClick);
    window.removeEventListener('blur', this.handleBlur);
    removePrintFramesFromDOM();
  }

  get isVisible() {
    const { documentsIds } = this.props;
    const { isActive } = this.state;
    return isActive || Boolean(documentsIds.size);
  }

  get documents(): Set<TDocumentRecord> {
    const { documentsById, documentsIds } = this.props;
    return documentsIds.reduce((a, v: string) => {
      const el: ?TDocumentRecord = documentsById.get(v);
      if (el) return a.add(el);
      return a;
    }, new Set());
  }

  get isAllSigned() {
    const { signParams } = this.props;
    return !signParams || !signParams.guid || this.documents.every(this.isSignAvailable);
  }

  get isFinancialDocument(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_WORK_WITH_FINANCIAL_DOCUMENT);
  }

  get isLimitedBookkeeper(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_LIMITED_ACCOUNT);
  }

  get objWithDisabledStatus() {
    return {
      convertToGeneral: this.documents.every(
        (d) => d.tags.has(CONST.tags.isScheduledAcceptance) || d.doctype === 'general',
      ),
      convertToFinancial: this.documents.every((d) => d.doctype === 'financial'),
      signDocuments: this.isAllSigned,
      mergeDocuments:
        this.documents.size < 2 || this.documents.some((d) => d.tags.has(CONST.tags.isScheduledAcceptance)),
      bulkAcceptDocuments: this.getAvailableToPublishCount() === 0,
    };
  }

  getAvailableDocumentsToPublish = (): Set<TDocumentRecord> =>
    this.documents.filter(
      (d) =>
        !(
          d.tags.has(CONST.tags.isAccepted) ||
          d.tags.has(CONST.tags.isScheduledAcceptance) ||
          d.tags.has(CONST.tags.isScheduledApproval) ||
          !d.isAcceptable ||
          d.doctype === 'general'
        ),
    );

  getAvailableToPublishCount = () => {
    const documents = this.getAvailableDocumentsToPublish();

    return documents.size;
  };

  moveDocToCategories = (categoryId: number) => () => {
    const { documentsIds, moveDocumentToCategory, clearSelection, linkid, isLinkedPanelSelected } = this.props;
    const linkidParams = isLinkedPanelSelected && linkid ? { linkid } : {};
    moveDocumentToCategory({ categoryId, documentID: documentsIds.toJS(), ...linkidParams });
    clearSelection();
  };

  isSignAvailable = (d: TDocumentRecord) =>
    d.status.includes('signed') || !checkAcceptSignType(d.documentID) || d.protected;

  isAllowMergeDocuments = () => {
    const { documentsIds, isGranted } = this.props;
    return documentsIds.size > 1 && isGranted(ACL.IS_ALLOW_MERGE_DOCUMENTS);
  };

  isAllowAutoConfirm = () => {
    const { userFeatures } = this.props;
    return !userFeatures.get('modal', true);
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { documentsIds, clearSelection, disabled, isGridView, selectAll, isChatOpen } = this.props;
    // console.log('===== BULK FN handleKeyDown', e.code, e.keyCode);
    // console.log('===== BULK FN clear', e.keyCode === 27, documentsIds.size);

    if (INPUT_LOCAL_NAMES.includes(document.activeElement.localName)) {
      return;
    }

    if (e.keyCode === 65 && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        selectAll();
      }
    }

    if (e.keyCode === 27 && documentsIds.size) {
      clearSelection();
    }

    if (e.ctrlKey && isMacOs) return;

    //* * we shouldnot display selectpanel for grid view when pressed 'ctr' or 'cmd' key  */
    if (isGridView) return;

    if (!disabled && !isChatOpen && (e.metaKey || e.ctrlKey)) {
      this.setState({ isActive: true });
    }
  };

  handleKeyUp = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === 91 || keyCode === 17) {
      this.setState({
        isActive: false,
      });
    }
  };

  handleBlur = () => {
    this.setState({
      isActive: false,
    });
  };

  handleRightClick = () => {
    const { isActive } = this.state;

    if (!isActive) return;
    this.setState({ isActive: false });
  };

  changeTypes = (type) => async () => {
    const { clearSelection, changeTypes, documentsIds } = this.props;

    const { success } = await promisify(changeTypes, { documentsIds, type });
    clearSelection();
    this.toastHandler('changeTypeSuccess', { success: success.length, total: documentsIds.size });
  };

  toggleSignAllModal = (showSignAllModal: boolean) => {
    this.setState({ showSignAllModal });
  };

  toggleTagAllModal = (showTagAllModal: boolean) => {
    this.setState({ showTagAllModal });
  };

  openSignAllModal = () => {
    this.toggleSignAllModal(true);
  };

  showBulkSendByEmailModal = () => {
    this.setState({ showSendByEmailModal: true });
  };

  hideBulkSendByEmailModal = () => {
    const { clearSelection } = this.props;
    this.setState({ showSendByEmailModal: false });
    clearSelection();
  };

  mergeDocuments = () => {
    const {
      documentsIds,
      mergeDocuments,
      clearSelection,
      isLinkedPanelSelected,
      hideLinkedDocs,
      confirm,
      intl: { formatMessage },
    } = this.props;

    const confirmOptions = {
      title: formatMessage({
        id: 'documents.show.modals.merge.title',
        defaultMessage: 'Merge Documents?',
      }),
      description: (
        <FormattedMessage
          id="documents.show.modals.merge.placeholder"
          defaultMessage="Do you want to merge {total} documents?{br} Original documents, including signatures and approvals, will be removed"
          values={{
            total: documentsIds.size,
            br: <br />,
          }}
        />
      ),
      confirmationText: formatMessage({
        id: 'documents.show.modals.merge.btn',
        defaultMessage: 'Merge',
      }),
    };

    const action = () => {
      clearSelection();

      const promise = new Promise((resolve, reject) => {
        mergeDocuments({ documentID: documentsIds, resolve, reject });
      });
      promise.then(() => {
        if (isLinkedPanelSelected) {
          hideLinkedDocs();
        }
      });
    };

    this.isAllowAutoConfirm()
      ? action()
      : confirm({ ...confirmOptions })
          .then(() => {
            action();
          })
          .catch(() => 'cancel');
  };

  closeSignAllModal = () => {
    this.toggleSignAllModal(false);
  };

  signAllDocuments = async (signatureId: string, nextIsLast: boolean) => {
    const { addSignatures, documentsIds } = this.props;
    try {
      const { success } = await promisify(addSignatures, { documentsIds, signatureId });
      if (nextIsLast) this.toastHandler('signSuccess', { success: success.length, total: documentsIds.size });
    } catch (err) {
      this.toastHandler('signFailed');
    }
  };

  handleDownload = async () => {
    const { download, documentsIds, clearSelection } = this.props;
    download({ documentID: documentsIds });

    toast.success(
      <FormattedMessage id="selectedPanel.download.toast" defaultMessage="The download will start in a few seconds" />,
      { autoClose: 7000 },
    );
    clearSelection();
  };

  handlePublish = async () => {
    const confirmOptions = {
      title: <FormattedMessage id="documents.show.modals.publish.title" defaultMessage="Publish documents?" />,
      description: (
        <FormattedMessage
          id="documents.show.modals.publish.placeholder"
          defaultMessage="{success} of {total} documents will be scheduled for publishing. It may take a while."
          values={{
            success: <span style={{ fontWeight: 600 }}>{this.getAvailableToPublishCount()}</span>,
            total: <span style={{ fontWeight: 600 }}>{this.documents.size}</span>,
          }}
        />
      ),
      confirmationText: <FormattedMessage id="documents.show.modals.publish.btn" defaultMessage="Publish" />,
    };
    const { bulkAccept, clearSelection, confirm } = this.props;

    // get filtered/available documents and send to b-end(it can be not only all selected documents)
    const documentsIds = this.getAvailableDocumentsToPublish().map((d) => d.documentID);

    confirm({ ...confirmOptions }).then(() => {
      const action = async () => {
        const { success } = await promisify(bulkAccept, { documentsIds });
        clearSelection();
        this.toastHandler('bulkAcceptSuccess', { success: success.length, total: documentsIds.size });
      };
      action();
    });
  };

  handleDelete = async () => {
    const {
      documentsIds,
      clearSelection,
      delete: deleteDocs,
      intl: { formatMessage },
      confirm,
    } = this.props;

    const confirmOptions = {
      title: formatMessage({
        id: 'documents.show.modals.delete.title',
        defaultMessage: 'Delete Documents?',
      }),
      description: formatMessage({
        id: 'documents.show.modals.delete.placeholder',
        defaultMessage: 'Are you sure you want to delete these documents?',
      }),
      confirmationText: formatMessage({
        id: 'documents.show.modals.delete.btn',
        defaultMessage: 'Delete',
      }),
    };

    confirm({ ...confirmOptions }).then(async () => {
      const { success } = await promisify(deleteDocs, { documentID: documentsIds });
      this.toastHandler('remove', { success: success.length, total: documentsIds.size });
      clearSelection();
    });
  };

  handlePrint = async () => {
    const { documentsIds, documentsById, companyId, apiToken: dokkaToken, mergeDocumentsForPrinting } = this.props;

    this.setState({ isBusyPrint: true });

    try {
      // if documentsIds have one ID and document not linked
      if (documentsIds.size === 1 && !documentsById.getIn([documentsIds.first(), 'linkid'], false)) {
        const documentID = documentsIds.first();
        const url = Api.getDocumentUrl({ dokkaToken, documentID, companyId });
        const document = documentsById.get(documentID);

        await print(url, documentsIds[0], document.protected);
      } else {
        const pdfFile = await promisify(mergeDocumentsForPrinting, { documentID: documentsIds });
        await onPrint(pdfFile, documentsIds.first(), false);
      }

      this.setState({ isBusyPrint: false });
    } catch {
      this.setState({ isBusyPrint: false });
    }
  };

  toastHandler(action: ToastActionType, args: Object = {}, type?: TToastType) {
    const {
      intl: { formatMessage },
    } = this.props;
    return makeTost(formatMessage)(action, args, type);
  }

  render() {
    const {
      documentsIds,
      signParams,
      isGranted,
      rootCategoriesList,
      currentRootCategory,
      gridRef,
      isGridView,
      clearSelection,
      roleModificators,
      isLinkedPanelSelected,
    } = this.props;
    const isAccount = isGranted(ACL.IS_ACCOUNT);
    const { showSignAllModal, showTagAllModal, isBusyPrint, showSendByEmailModal } = this.state;

    return (
      <>
        <SelectPanelContainer isVisible={this.isVisible} elevation={24}>
          {documentsIds.size ? (
            <>
              <Box
                bgcolor="primary.main"
                color="common.white"
                px={2}
                borderRadius="8px 0 0 8px"
                display="flex"
                alignItems="center"
              >
                <Typography variant="h6">{documentsIds.size}</Typography>
              </Box>

              <Stack
                direction="row"
                bgcolor="common.white"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                py={1.5}
                pr={1.5}
                borderRadius={2}
              >
                <Box mx={1.5}>
                  <FormattedMessage id="selectedPanel.selected" defaultMessage="Docs selected" />
                </Box>
                <Tooltip t={{ id: 'selectedPanel.downloadAll', defaultMessage: 'Download all' }} placement="top">
                  <IconButton aria-label="download" color="primary" onClick={this.handleDownload}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  t={{ id: 'selectedPanel.tooltip.sendByEmail', defaultMessage: 'Share via email' }}
                  placement="top"
                >
                  <IconButton aria-label="sendByEmail" color="primary" onClick={this.showBulkSendByEmailModal}>
                    <ForwardToInboxOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip t={{ id: 'selectedPanel.tooltip.print', defaultMessage: 'Print' }} placement="top">
                  <IconButton aria-label="print" color="primary" onClick={this.handlePrint} disabled={isBusyPrint}>
                    {isBusyPrint ? <IconInfinity size={22} /> : <PrintOutlinedIcon />}
                  </IconButton>
                </Tooltip>

                {isGridView && !isLinkedPanelSelected && (
                  <Tooltip t={{ id: 'selectedPanel.exportAsExcel', defaultMessage: 'Export as Excel' }} placement="top">
                    <IconButton
                      aria-label="export"
                      color="primary"
                      onClick={() => gridRef.current && gridRef.current.exportSelectedRowsDataAsExcel()}
                    >
                      <CloudDownloadOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Divider />
                {isAccount && (
                  <>
                    <Tooltip
                      t={{ id: 'selectedPanel.generateJournalEntry', defaultMessage: 'Mark as financial document' }}
                      placement="top"
                    >
                      <IconButton
                        aria-label="convertToFinancial"
                        color="primary"
                        onClick={this.changeTypes('financial')}
                        disabled={this.objWithDisabledStatus.convertToFinancial}
                      >
                        <ScoreOutlined />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      t={{ id: 'selectedPanel.convertToGeneral', defaultMessage: 'Convert to general' }}
                      placement="top"
                    >
                      <IconButton
                        aria-label="convertToGeneral"
                        color="primary"
                        onClick={this.changeTypes('general')}
                        disabled={this.objWithDisabledStatus.convertToGeneral}
                      >
                        <PostAddOutlined />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip t={{ id: 'selectedPanel.signDocuments', defaultMessage: 'Sign documents' }} placement="top">
                  <IconButton
                    aria-label="sign"
                    color="primary"
                    onClick={this.openSignAllModal}
                    disabled={this.objWithDisabledStatus.signDocuments}
                  >
                    <BorderColorOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Divider />

                {this.isAllowMergeDocuments() && (
                  <>
                    <Tooltip
                      t={{ id: 'selectedPanel.mergeDocuments', defaultMessage: 'Merge documents' }}
                      placement="top"
                    >
                      <IconButton
                        aria-label="merge"
                        color="primary"
                        onClick={this.mergeDocuments}
                        disabled={this.objWithDisabledStatus.mergeDocuments}
                      >
                        <CallMergeIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip t={{ id: 'selectedPanel.tagMultipleDocuments', defaultMessage: 'Tag as' }} placement="top">
                  <IconButton aria-label="tagAs" color="primary" onClick={() => this.toggleTagAllModal(true)}>
                    <LabelOutlinedIcon />
                  </IconButton>
                </Tooltip>

                {isAllowMoveToCategories({
                  isGranted,
                  rootCategories: rootCategoriesList,
                  currentRootCategoryId: currentRootCategory,
                }) && (
                  <>
                    <Divider />
                    {getMovableCategories(currentRootCategory, rootCategoriesList).map((category) => (
                      <Tooltip
                        t={{ id: `category.name.${category.nameLangId}`, defaultMessage: 'cat.name' }}
                        key={category.id}
                        placement="top"
                      >
                        <IconButton
                          aria-label={category.name}
                          color="primary"
                          onClick={this.moveDocToCategories(category.id)}
                        >
                          <CategoryIcon src={category.icon} />
                        </IconButton>
                      </Tooltip>
                    ))}
                  </>
                )}
                {this.isFinancialDocument && isGridView && !this.isLimitedBookkeeper && !roleModificators.readonly && (
                  <>
                    <Divider />
                    <Tooltip
                      t={{ id: 'selectedPanel.bulkPublish', defaultMessage: 'Publish documents' }}
                      placement="top"
                    >
                      <IconButton
                        aria-label="publish"
                        color="primary"
                        onClick={this.handlePublish}
                        disabled={this.objWithDisabledStatus.bulkAcceptDocuments}
                      >
                        <PublishOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Divider />

                <BulkApproveButton selectedIds={documentsIds} clearSelection={clearSelection} />

                <DotsMenu selectedIds={documentsIds} clearSelection={clearSelection} />

                <Divider />

                <Tooltip t={{ id: 'selectedPanel.delete', defaultMessage: 'Delete' }} placement="top">
                  <IconButton aria-label="delete" color="primary" onClick={this.handleDelete}>
                    <DeleteOutlinedIcon color="error" />
                  </IconButton>
                </Tooltip>

                <IconButton aria-label="remove" onClick={clearSelection}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </>
          ) : (
            <Box bgcolor="primary.main" color="common.white" p={2} borderRadius={2}>
              <Typography variant="body1">
                <FormattedMessage
                  id="selectedPanel.selectMultipleDocumentsToPerformBulkSelection"
                  defaultMessage="Select multiple documents to perform bulk selection"
                />
              </Typography>
            </Box>
          )}
        </SelectPanelContainer>
        {showSignAllModal && documentsIds.size > 0 && (
          <DialogSignDocument
            documents={this.documents}
            onClose={this.closeSignAllModal}
            onSign={this.signAllDocuments}
            signParams={signParams}
          />
        )}
        <DialogTagMultipleManage
          open={showTagAllModal}
          documents={this.documents}
          onCancel={() => this.toggleTagAllModal(false)}
        />
        {showSendByEmailModal && (
          <DialogSendByEmail
            documentIds={[...documentsIds]}
            onClose={this.hideBulkSendByEmailModal}
            action={clearSelection}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props: Props) => ({
  documentsById: documentsByIdSelector(state).merge(documentLinkedSelector(state)),
  signParams: companiesByIdSelector(state).getIn([props.companyId, 'signParams']),
  isGranted: ACL.isGranted(state),
  rootCategoriesList: rootCategoriesListSelector(state),
  linkid: documentLinkedTagSelector(state),
  isGridView: isGridWorkSpaceSelector(state),
  userFeatures: userFeaturesSelector(state),
  isChatOpen: isOpenSelector(state),
  roleModificators: roleModificatorsSelector(state),
});

const mapDispatchToProps = {
  changeTypes: documentsChangeTypesAction,
  addSignatures: documentsAddSignaturesAction,
  bulkAccept: documentsBulkAcceptAction,
  download: documentsDownloadDocumentsAction,
  delete: documentsRemoveDocumentsAction,
  mergeDocuments: mergeDocumentsAction,
  mergeDocumentsForPrinting: mergeDocumentsForPrintingAction,
  hideLinkedDocs: documentHideLinkedDocs,
};

export default compose(
  injectIntl,
  withConfirm,
  connect(mapStateToProps, mapDispatchToProps),
  withApiToken,
)(CompanyBulkPanel);
