/* @flow */
export default {
  header: {
    home: 'header.home',
    events: 'header.events',
    search: 'header.search',
    settings: 'header.settings',
    logout: 'header.logout',
    chat: 'header.chat',
    logo: 'header.logo',
    back: 'header.back',
    points: 'header.points',
    fullscreen: 'header.fullscreen',
    keyboard: 'header.help.keyboard',
    ask: {
      help: 'header.ask.help',
      tour: 'header.ask.tour',
      support: 'header.ask.support',
    },
    profile: {
      container: 'header.profile',
      menuItem: 'header.profile.menuItem',
    },
    profileLink: 'header.profileLink',
    organization: {
      logo: 'header.organization.logo',
    },
    company: {
      logo: 'header.company.logo',
      about: 'header.company.about',
    },
    document: {
      forceSync: 'header.document.forceSync',
    },
  },
  aside: {
    left: {
      bar: {
        container: 'container',
        item: {
          home: 'aside.left.bar.home',
          workspace: 'aside.left.bar.workspace',
          confWorkspace: 'aside.left.bar.confWorkspace',
          categoriesBox: 'aside.left.bar.categoriesBox',
          category: 'aside.left.bar.category',
          sysFav: 'aside.left.bar.sysFav',
          userFav: 'aside.left.bar.userFav',
          favDelete: 'aside.left.bar.favDelete',
          favHeader: 'aside.left.bar.favHeader',
          userTag: 'aside.left.bar.userTag',
        },
      },
    },
  },
  filterBar: {
    container: 'filterBar',
    addDocumentButton: 'filterBar.addDocumentButton',
    scanButton: 'filterBar.scanButton',
    calendarButton: 'filterBar.calendarButton',
    moveAllToArchive: 'filterBar.moveAllToArchive',
    viewSwitcher: 'filterBar.viewSwitcher',
    tab: {
      container: 'filterBar.tab.container',
      item: {
        all: 'filterBar.tab.item.all',
        financial: 'filterBar.tab.item.financial',
        general: 'filterBar.tab.item.general',
      },
    },
    type: {
      new: 'filterBar.type.new',
      paid: 'filterBar.type.paid',
      unpaid: 'filterBar.type.unpaid',
      accepted: 'filterBar.type.accepted',
      rejected: 'filterBar.type.rejected',
      read: 'filterBar.type.read',
    },
    search: 'filterBar.search',
    save: {
      container: 'filterBar.save',
      button: 'filterBar.save.button',
    },
    result: {
      container: 'filterBar.result.container',
      count: 'filterBar.result.count',
      tags: 'filterBar.result.tags',
      tag: 'filterBar.result.tag',
      delete: 'filterBar.result.delete',
    },
  },
  sortBar: {
    container: 'sortBar',
    list: 'sortBar.list',
    item: 'sortBar.item',
  },
  content: {
    companies: {
      name: 'content.companies.name',
      item: 'content.companies.item',
      pin: 'content.companies.pin',
      correspondence: 'content.companies.correspondence',
      unreadMessage: 'content.companies.unreadMessage',
      documents: 'content.companies.documents',
      documentsCount: 'content.companies.documentsCount',
    },
    documents: {
      section: 'content.documents.section',
      item: 'content.documents.item',
      linkedBtn: 'content.documents.linkedBtn',
      notes: 'content.documents.notes',
      properties: 'content.documents.properties',
      status: 'content.documents.status',
      increase: 'content.documents.increase',
      decrease: 'content.documents.decrease',
      normalScale: 'content.documents.normalScale',
      rotateCCW: 'content.documents.rotateCCW',
      rotateCW: 'content.documents.rotateCW',
      preview: {
        actions: {
          group: 'content.documents.preview.actions.group',
          open: 'content.documents.preview.actions.open',
          context: 'content.documents.preview.actions.context',
        },
      },
      label: {
        processing: 'content.documents.label.processing',
      },
      sign: {
        noSigned: 'content.documents.sign.noSigned',
        pending: 'content.documents.sign.pending',
      },
      tags: {
        container: 'content.documents.tags',
        recent: 'content.documents.tags.recent',
        input: 'content.documents.tags.input',
        add: 'content.documents.tags.add',
        removeTag: 'content.documents.tags.removeTag',
        tagItem: 'content.documents.tags.tagItem',
        expand: 'content.documents.tags.expand',
      },
      navigation: {
        container: 'content.documents.navigation',
        prev: 'content.documents.navigation.prev',
        next: 'content.documents.navigation.next',
      },
      rejected: {
        container: 'content.documents.rejected',
        expandButton: 'content.documents.rejected.expandButton',
        reason: 'content.documents.rejected.reason',
        approvals: {
          container: 'content.documents.rejected.approvals',
          expandButton: 'content.documents.rejected.approvals.expandButton',
          reason: 'content.documents.rejected.approvals.reason',
        },
      },
      approvals: {
        container: 'content.documents.approvals',
        input: 'content.documents.approvals.input',
        menu: 'content.documents.approvals.menu',
        removeApproval: 'content.documents.approvals.removeApproval',
        approvalItem: 'content.documents.approvals.approvalItem',
        expand: 'content.documents.approvals.expand',
        toggler: 'content.documents.approvals.toggler',
        dropdownToggler: 'content.documents.approvals.dropdownToggler',
        autocompleteOptions: {
          groupsContainer: 'content.documents.approvals.options.groupsContainer',
          approvalItem: 'content.documents.approvals.options.approvalItem',
          approvalGroupItem: {
            container: 'content.documents.approvals.options.approvalGroupItem.container',
            name: 'content.documents.approvals.options.approvalGroupItem.name',
            listOfApprovals: 'content.documents.approvals.options.approvalGroupItem.listOfApprovals',
          },
        },
        selectedGroupName: 'content.documents.approvals.selectedGroupName',
        listOfApprovals: 'content.documents.approvals.listOfApprovals',
        popup: {
          reject: {
            reasonIdLabel: 'content.documents.approvals.popup.reject.reasonLabel',
            reasonIdControl: 'content.documents.approvals.popup.reject.reasonControl',
            detailsControl: 'content.documents.approvals.popup.reject.detailsControl',
            actions: {
              reject: 'content.documents.approvals.popup.reject.actions.reject',
              cancel: 'content.documents.approvals.popup.reject.actions.cancel',
            },
          },
        },
      },
    },
    editPanel: {
      documents: 'content.editPanel.documents',
      split: 'content.editPanel.split',
      splitAll: 'content.editPanel.splitAll',
      undo: 'content.editPanel.undo',
      redo: 'content.editPanel.redo',
      undoRedoWrapper: 'content.editPanel.undoRedo',
      remove: 'content.editPanel.remove',
      cancel: 'content.editPanel.cancel',
      save: 'content.editPanel.save',
    },
    analyzing: {
      container: 'content.analyzing.container',
    },
    dashboard: {
      indicators: {
        container: 'content.dashboard.indicators',
        financial: 'content.dashboard.indicators.financial',
        general: 'content.dashboard.indicators.general',
        gropup: {
          review: 'content.dashboard.indicators.gropup.review',
          completed: 'content.dashboard.indicators.gropup.completed',
          average: 'content.dashboard.indicators.gropup.average',
          allTime: 'content.dashboard.indicators.gropup.allTime',
        },
      },
      favorites: {
        container: 'content.dashboard.favorites',
        title: 'content.dashboard.favorites.title',
        list: 'content.dashboard.favorites.list',
        item: {
          constainer: 'content.dashboard.favorites.item',
          link: 'content.dashboard.favorites.item.link',
          counter: 'content.dashboard.favorites.item.counter',
          title: 'content.dashboard.favorites.item.title',
        },
      },
      todo: {
        container: 'content.dashboard.todo',
        clearBtn: 'content.dashboard.todo.clearBtn',
        group: 'content.dashboard.todo.group',
        counter: 'content.dashboard.todo.counter',
        add: 'content.dashboard.todo.add',
        item: 'content.dashboard.todo.item',
        actionItem: 'content.dashboard.todo.actionItem',
        calendarButton: 'content.dashboard.todo.calendarButton',
      },
    },
    linkedPanel: {
      container: 'content.linkedPanel',
      close: 'content.linkedPanel.close',
      linkedText: 'content.linkedPanel.linkedText',
      documents: 'content.linkedPanel.documents',
      documentItem: 'content.linkedPanel.documentItem',
      done: 'content.linkedPanel.done',
      more: 'content.linkedPanel.more',
      lock: 'content.linkedPanel.lock',
    },
    selectedPanel: {
      container: 'content.selectedPanel',
      empty: 'content.selectedPanel.empty',
      counter: 'content.selectedPanel.counter',
      clear: 'content.selectedPanel.clear',
      download: 'content.selectedPanel.download',
      toJE: 'content.selectedPanel.toJE',
      toGeneral: 'content.selectedPanel.toGeneral',
      sign: 'content.selectedPanel.sign',
      delete: 'content.selectedPanel.delete',
      merge: 'content.selectedPanel.merge',
      tag: 'content.selectedPanel.tag',
      move: 'content.selectedPanel.move',
      exportAsExcel: 'content.selectedPanel.exportAsExcel',
      bulkAccept: 'content.selectedPanel.bulkAccept',
      print: 'content.selectedPanel.print',
      bulkApprove: 'content.selectedPanel.bulkApprove',
    },
    workspace: {
      documentsList: 'content.workspace.documentsList',
    },
    document: {
      tableBlock: {
        container: 'content.document.tableBlock',
      },
      bottomBar: {
        buttons: {
          more: 'content.document.bottomBar.buttons.more',
          accept: 'content.document.bottomBar.buttons.accept',
          reconcile: 'content.document.bottomBar.buttons.reconcile',
          ok: 'content.document.bottomBar.buttons.ok',
          request: 'content.document.bottomBar.buttons.request',
          approve: 'content.document.bottomBar.buttons.approve',
        },
        pdfControls: {
          signatureToggler: 'content.document.bottomBar.pdfControls.signatureToggler',
          viewModeToggle: 'content.document.bottomBar.pdfControls.viewModeToggle',
          twoWayMatching: 'content.document.bottomBar.pdfControls.twoWayMatching',
        },
      },
    },
  },
  popup: {
    container: 'popup.container',
    content: 'popup.content',
    close: 'popup.close',
    title: 'popup.title',
    SaveSearchPopup: {
      form: 'popup.SaveSearchPopup.form',
      title: 'popup.SaveSearchPopup.title',
      input: 'popup.SaveSearchPopup.input',
      inputWarapper: 'popup.SaveSearchPopup.inputWarapper',
      buttons: 'popup.SaveSearchPopup.buttons',
      cancel: 'popup.SaveSearchPopup.cancel',
      add: 'popup.SaveSearchPopup.add',
    },
    Upload: {
      dialog: 'popup.Upload.dialog',
      dropZone: 'popup.Upload.dropZone',
      input: 'popup.Upload.input',
      cancelBtn: 'popup.Upload.cancelButton',
      uploadBtn: 'popup.Upload.uploadButton',
      categorySelector: {
        expandButton: 'popup.Upload.categorySelector.expandButton',
        input: 'popup.Upload.categorySelector.input',
        listItem: 'popup.Upload.categorySelector.listItem',
      },
      datePicker: {
        input: 'popup.Upload.datePicker.input',
        iconContainer: 'popup.Upload.datePicker.iconContainer',
      },
      queue: {
        container: 'popup.Upload.queue',
        item: 'popup.Upload.queue.item',
        document: 'popup.Upload.queue.document',
        actionBtn: 'popup.Upload.queue.actionBtn',
        deleteBtn: 'popup.Upload.queue.deleteBtn',
      },
    },
    Notes: {
      textField: 'popup.Notes.textField',
      save: 'popup.Notes.save',
      buttons: 'popup.Notes.buttons',
      cancel: 'popup.Notes.cancel',
      delete: 'popup.Notes.delete',
    },
    JEAddElement: {
      container: 'popup.JEAddElement',
      input: 'popup.JEAddElement.input',
      select: 'popup.JEAddElement.select',
      buttons: 'popup.JEAddElement.buttons',
      cancel: 'popup.JEAddElement.cancel',
      confirm: 'popup.JEAddElement.confirm',
    },
    JEEditSupplier: {
      cancel: 'popup.JEEditSupplier.cancel',
      confirm: 'popup.JEEditSupplier.confirm',
      actions: 'popup.JEAddElement.actions',
    },
    companyModal: {
      input: 'popup.companyModal.input',
      logo: 'popup.companyModal.logo',
      close: 'popup.companyModal.close',
      submit: 'popup.companyModal.submit',
    },
    signDocuments: {
      container: 'popup.signDocuments',
      signer: 'popup.signDocuments.signer',
      signButton: 'popup.signDocuments.signButton',
      status: {
        signed: 'popup.signDocuments.status.signed',
        pending: 'popup.signDocuments.status.pending',
      },
    },
    RejectPopup: {
      container: 'popup.RejectPopup',
      checkBox: 'popup.RejectPopup.checkBox',
      label: 'popup.RejectPopup.label',
      textArea: 'popup.RejectPopup.textArea',
      cancelButton: 'popup.RejectPopup.cancelButton',
      submitButton: 'popup.RejectPopup.submitButton',
      reasonsList: 'popup.RejectPopup.reasonsList',
      buttonGroup: 'popup.RejectPopup.buttonGroup',
    },
    ClientUserForm: {
      container: 'popup.ClientUserForm',
      tab: 'popup.ClientUserForm.tab',
      contactsContainer: 'popup.CreateUserForm.contactInformation',
    },
    OrganizationUserForm: {
      container: 'popup.OrganizationUserForm',
      tab: 'popup.OrganizationUserForm.tab',
      role: 'popup.OrganizationUserForm.role',
      firstName: 'popup.OrganizationUserForm.firstName',
      lastName: 'popup.OrganizationUserForm.lastName',
      email: 'popup.OrganizationUserForm.email',
      phone: 'popup.OrganizationUserForm.phone',
      contactsContainer: 'popup.OrganizationUserForm.contactInformation',
    },
    OrganizationForm: {
      container: 'popup.OrganizationForm',
      title: 'popup.OrganizationForm.titleField',
    },
    approvalGroups: {
      groupName: 'popup.approvalGroups.groupName',
      groupNameValidationError: 'popup.approvalGroups.groupNameValidationError',
      selectApprovers: 'popup.approvalGroups.selectApprovers',
      selectApproversDescription: 'popup.approvalGroups.selectApproversDescription',
      option: 'popup.approvalGroups.option',
      selectedApproversContainer: 'popup.approvalGroups.selectedApproversContainer',
      listOf: 'popup.approvalGroups.listOf',
      cancel: 'popup.approvalGroups.cancel',
      save: 'popup.approvalGroups.save',
    },
  },
  contextMenu: {
    container: 'contextMenu',
    item: 'contextMenu.item',
  },
  tooltip: {
    container: 'tooltip',
  },
  select: {
    container: 'select',
    header: 'select.header',
    list: 'select.list',
    option: 'select.option',
    optionBtn: 'select.optionBtn',
  },
  companies: {
    add: 'companies.add',
  },
  configuration: {
    sidebar: {
      container: 'configuration.sidebar.container',
    },
    list: {
      container: 'configuration.list.container',
    },
    company: {
      lists: {
        users: {
          bookkeepers: {
            container: 'configuration.company.lists.users.bookkeepers',
          },
          clients: {
            container: 'configuration.company.lists.users.clients',
          },
        },
      },
      integrations: {
        container: 'configuration.company.integrations.container',
        forms: {
          container: 'configuration.company.integrations.forms',
          item: 'configuration.company.integrations.forms.item',
          section: 'configuration.company.integrations.forms.section',
          control: 'configuration.company.integrations.forms.control',
          select: 'configuration.company.integrations.forms.select',
          input: 'configuration.company.integrations.forms.input',
          toggler: {
            hint: 'configuration.company.integrations.param.forms.toggler',
          },
          saveButton: 'configuration.company.integrations.forms.saveButton',
        },
      },
      approvalGroups: {
        noGroupsTitle: 'configuration.company.approvals.noGroupsTitle',
        noGroupsDescription: 'configuration.company.approvals.noGroupsDescription',
        createGroupButton: 'configuration.company.approvals.createGroupButton',
        groupList: 'configuration.company.approvals.groupList',
        groupTile: {
          container: 'configuration.company.approvals.groupTile',
          controls: {
            edit: 'configuration.company.approvals.groupTile.controls.edit',
            delete: 'configuration.company.approvals.groupTile.controls.delete',
          },
        },
      },
      tabs: {
        main: 'configuration.company.tabs.main',
        contact: 'configuration.company.tabs.contact',
        financial: 'configuration.company.tabs.financial',
        features: 'configuration.company.tabs.features',
      },
      forms: {
        features: {
          featureSet: 'configuration.company.forms.features.featureSet',
          signFeature: 'configuration.company.forms.features.signFeature',
          guid: 'configuration.company.forms.features.guid',
          submitGuid: 'configuration.company.forms.features.guid.submit',
          copySignings: 'configuration.company.forms.features.copySignings',

          signerOne: 'configuration.company.forms.features.signerOne',
          signerOneName: 'configuration.company.forms.features.signerOne.name',
          signerOneSubmit: 'configuration.company.forms.features.signerOne.submit',
          signerOneRemove: 'configuration.company.forms.features.signerOne.remove',

          signerTwo: 'configuration.company.forms.features.signerTwo',
          signerTwoName: 'configuration.company.forms.features.signerTwo.name',
          signerTwoSubmit: 'configuration.company.forms.features.signerTwo.submit',
          signerTwoRemove: 'configuration.company.forms.features.signerTwo.remove',
        },
        main: {
          container: 'configuration.company.forms.main',
          companyEmail: 'configuration.company.forms.main.companyEmail',
          companyAddressContainer: 'configuration.company.forms.main.address',
          submitButton: 'configuration.company.forms.main.submitButton',
        },
      },
      users: {
        revokeButton: 'configuration.company.users.revokeButton',
        editButton: 'configuration.company.users.editButton',
        counter: 'configuration.company.users.counter',
      },
    },
    organization: {
      tabs: {
        main: 'configuration.organization.tabs.main',
        contact: 'configuration.organization.tabs.contact',
        financial: 'configuration.organization.tabs.financial',
        features: 'configuration.organization.tabs.features',
      },
      forms: {
        features: {
          featureSet: 'configuration.organization.forms.features.featureSet',
          signFeature: 'configuration.organization.forms.features.signFeature',
          guid: 'configuration.organization.forms.features.guid',
          submitGuid: 'configuration.organization.forms.features.guid.submit',

          signerOne: 'configuration.organization.forms.features.signerOne',
          signerOneName: 'configuration.organization.forms.features.signerOne.name',
          signerOneSubmit: 'configuration.organization.forms.features.signerOne.submit',
          signerOneRemove: 'configuration.organization.forms.features.signerOne.remove',

          signerTwo: 'configuration.organization.forms.features.signerTwo',
          signerTwoName: 'configuration.organization.forms.features.signerTwo.name',
          signerTwoSubmit: 'configuration.organization.forms.features.signerTwo.submit',
          signerTwoRemove: 'configuration.organization.forms.features.signerTwo.remove',
        },
      },
      lists: {
        users: {
          bookkeepers: {
            container: 'configuration.org.lists.users.bookkeepers',
            back: 'configuration.org.lists.users.bookkeepers.details.back',
          },
        },
      },
    },
    general: {
      financial: {
        currency: 'configuration.general.financial.currency',
        registrationNumber: 'configuration.general.financial.registrationNumber',
        vat: 'configuration.general.financial.vat',
        amountsRepresentation: 'configuration.general.financial.amountsRepresentation',
        preparationDays: 'configuration.general.financial.preparationDays',
        saveButton: 'configuration.general.financial.saveButton',
      },
    },
    users: {
      addUserButton: 'configuration.users.addUserButton',
      bookkeepers: {
        item: 'configuration.users.bookkeepers.item',
        revokeButton: 'configuration.users.bookkeepers.revokeButton',
        assignButton: 'configuration.users.bookkeepers.assignButton',
      },
      clients: {
        item: 'configuration.users.clients.item',
        editButton: 'configuration.users.clients.editButton',
        deleteButton: 'configuration.users.clients.deleteButton',
      },
    },
    integration: {
      item: {
        container: 'configuration.integration.item',
        openSection: 'configuration.integration.item.openSection',
        disconnectButton: 'configuration.integration.item.disconnectButton',
      },
    },
  },
  profiles: {
    info: {
      btn: {
        submit: 'profiles.info.btn.submit',
      },
    },
    notifications: {
      btn: {
        submit: 'profiles.notifications.btn.submit',
      },
    },
    preferences: {
      switcher: {
        confirmActions: 'profiles.preferences.switcher.confirmActions',
      },
    },
  },
  toasts: {
    success: 'toasts.success',
    warn: 'toasts.warn',
    error: 'toasts.error',
    info: 'toasts.info',
  },
  chat: {
    shield: 'chat.shield',
    button: 'chat.button',
  },
  je: {
    container: 'je',
    cell: 'je.cell',
    processingBox: {
      container: 'je.processingBox',
      stopButton: 'je.processingBox.stopButton',
    },
    autocomplete: {
      container: 'je.autocomplete',
      shield: 'je.autocomplete.shield',
      field: 'je.autocomplete.field',
    },
    hintinput: {
      container: 'je.hintinput',
      field: 'je.hintinput.field',
    },
    description: {
      container: 'je.description',
    },
    readonly: {
      container: 'je.readonly',
    },
    fakeCell: {
      container: 'je.fakeCell',
      value: 'je.fakeCell.value',
    },
    literal: {
      container: 'je.literal',
      field: 'je.literal.field',
    },
    reconcileCell: {
      container: 'je.reconcileCell',
    },
    reconcileSearch: {
      spent: 'js.reconcileSearch.spent',
      selected: 'js.reconcileSearch.selected',
      diff: 'js.reconcileSearch.diff',
      amount: 'js.reconcileSearch.amount',
      vendor: 'js.reconcileSearch.vendor',
      dateFrom: 'js.reconcileSearch.dateFrom',
      dateTo: 'js.reconcileSearch.dateTo',
      account: 'js.reconcileSearch.account',
      ref: 'js.reconcileSearch.ref',
      transactionType: 'js.reconcileSearch.transactionType',
      details: 'js.reconcileSearch.details',
      table: 'js.reconcileSearch.table',
      btn: {
        add: 'js.reconcileSearch.btn.add',
        save: 'js.reconcileSearch.btn.save',
        undo: 'js.reconcileSearch.btn.undo',
        match: 'js.reconcileSearch.btn.match',
        ok: 'js.reconcileSearch.btn.ok',
      },
    },
    booleanCell: {
      container: 'je.booleanCell',
    },
    grid: {
      container: 'je.grid',
      float: 'je.grid.float',
      tune: 'je.grid.tune',
      colOption: 'je.grid.colOption',
      colOptionPined: 'je.grid.colOptionPined',
      selectAll: 'je.grid.selectAll',
      selectOne: 'je.grid.selectOne',
    },
    request: {
      btn: {
        send: 'je.request.btn.send',
        cancel: 'je.request.btn.cancel',
        reply: 'je.request.btn.reply',
      },
      selectRecipients: 'je.request.selectRecipients',
      name: 'je.request.name',
      table: 'je.request.table',
      message: 'je.request.message',
      historyTitle: 'je.request.historyTitle',
      companyName: 'je.request.companyName',
      docPreview: 'je.request.docPreview',
      transaction: {
        title: 'je.request.transaction.title',
        date: 'je.request.transaction.date',
        amount: 'je.request.transaction.amount',
        description: 'je.request.transaction.description',
        showAll: 'je.request.transaction.showAll',
        messageTitle: 'je.request.transaction.messageTitle',
        answerMessage: 'je.request.transaction.answerMessage',
        reply: 'je.request.transaction.reply',
        answered: 'je.request.transaction.answered',
      },
      indicator: {
        read: 'je.request.indicator.read',
        unread: 'je.request.indicator.unread',
      },
    },
    virtualGrid: {
      container: 'je.virtualGrid.container',
      row: 'je.virtualGrid.row',
    },
    viewArrangement: {
      rtl: 'je.viewArrangement.default',
      ltr: 'je.viewArrangement.ltr',
      jeOnly: 'je.viewArrangement.jeOnly',
      docOnly: 'je.viewArrangement.docOnly',
      btt: 'je.viewArrangement.btt',
      ttb: 'je.viewArrangement.ttb',
    },
    viewArrangementControl: 'je.viewArrangementControl',
    gridTopBar: {
      selected: 'je.gridTopBar.selected',
    },
  },
  history: {
    openHistoryButton: 'history.openHistoryButton',
    newEventsButton: 'history.newEventsButton',
    container: 'history.container',
    searchInput: 'history.searchInput',
    calendar: 'history.calendar',
    dropdownFilter: {
      usersDropdown: 'history.usersDropdown',
      eventsDropdown: 'history.eventsDropdown',
      item: 'history.dropdownFilter.item',
    },
    export: 'history.export',
    list: 'history.list',
    item: 'history.item',
    itemTitle: 'history.itemTitle',
    itemDate: 'history.itemDate',
    itemDetails: 'history.itemDetails',
    close: 'history.close',
  },
  noDocuments: {
    container: 'noDocuments.container',
    goToWorkspaceButton: 'noDocuments.goToWorkspaceButton',
    addDocumentButton: 'noDocuments.addDocumentButton',
  },
  noResults: {
    container: 'noResults.container',
    addDocumentButton: 'noResults.addDocumentButton',
  },
  generalMessages: {
    container: 'generalMessages',
    types: {
      info: 'generalMessages.info',
      warning: 'generalMessages.warning',
      error: 'generalMessages.error',
    },
    close: 'generalMessages.close',
  },
};
