export default {
  tags: {
    new: '_S_NEW',
    rejected: '_S_REJECTED',
    isBeingProcessed: '_S_PREVIEW_STATUS',
    isScheduledAcceptance: '_S_SCHEDULED_ACCEPTANCE',
    isAccepted: '_S_ACCEPTED',
    isScheduledApproval: '_S_SCHEDULED_APPROVAL',
    paid: '_S_PAID',
    unpaid: '_S_UNPAID',
    financial: '_T_FIN',
    approvalPending: '_S_APPROVAL_PENDING',
    approvalComplete: '_S_APPROVAL_COMPLETE',
  },
  doctype: {
    general: 'general',
  },
};

export const WS_GRID_PRESET_TYPES = {
  default: 'default',
  shared: 'shared',
  custom: 'custom',
  recent: 'recent',
};

export const WS_GRID_RECENT_DEFAULT_NAME = 'Recent configuration';

export const WS_GRID_DEFAULT_PRESET_ID = 'default';

export const SEARCH_DEFAULT_MAX_RESULT = 30;
